const AR = {
  countryConfig: {
    accountHolderDocNumber: {
      visible: true,
      label: "bankDetails.accountHolderDocNumber",
      placeholder: "bankDetails.CUIT2",
      required: true,
    },
    accountNumber: {
      visible: false,
      required: false,
    },
    swift: {
      label: "bankDetails.swift",
      placeholder: "bankDetails.swiftMinmaxValidation",
      visible: true,
      required: true,
    },
    CBU1: {
      visible: true,
      label: "bankDetails.CBU1",
      placeholder: "bankDetails.CBU1PlaceholderAr",
      required: true,
    },
    CBU2: {
      visible: true,
      label: "bankDetails.CBU2",
      placeholder: "bankDetails.CBU2PlaceholderAr",
      required: true,
    },
    bankCode: {
      visible: false,
      required: false,
    },
    IBAN: {
      visible: false,
    },
    bankName: {
      visible: true,
      required: true,
      label: "bankDetails.bankName",
    },
    bankAddress: {
      visible: false,
      required: false,
    },
  },
};
export { AR };
