<template>
  <div>
    <v-container class="client-form">
      <v-row>
        <v-col cols="6">
          <div>
            <VTextFieldWithValidation
              :rules="{ required: true, alphacar_spaces: true }"
              :counter="90"
              :label="$t('directService.customerData.name')"
              type="text"
              :default="name"
              v-model="dataClientForSend['name']"
            />
          </div>
        </v-col>
        <v-col cols="6">
          <div>
            <VTextFieldWithValidation
              :rules="{ required: true, alphacar_spaces: true }"
              :counter="90"
              :label="$t('directService.customerData.secondName')"
              type="text"
              :default="surname"
              v-model="dataClientForSend['secondName']"
            />
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div>
            <VTextFieldWithValidation
              :rules="{ required: true, alpha_num: true }"
              :counter="90"
              :label="$t('directService.customerData.identificationDocument')"
              v-model="dataClientForSend['documentation']"
            />
          </div>
          <v-row>
            <v-col cols="4">
              <div>
                <VSelectWithValidation
                  v-model="mobilePrefixSelected"
                  :items="mobilePrefixes"
                  @change="prefixSelected({ field: 'mobile', value: $event })"
                  :label="$t('directService.customerData.prefix') + '*'"
                  filled
                  :hide-validations="true"
                  :rules="{ required: true }"
                />
              </div>
            </v-col>
            <v-col cols="8">
              <div>
                <VTextFieldWithValidation
                  :rules="{ required: true, isValidPhone: true }"
                  :counter="90"
                  :label="$t('directService.customerData.mobile')"
                  v-model="dataClientForSend['mobile']"
                />
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <div>
                <VSelectWithValidation
                  v-model="telephonePrefixSelected"
                  @change="
                    prefixSelected({
                      field: 'telephone',
                      value: $event,
                    })
                  "
                  :items="mobilePrefixes"
                  :label="$t('directService.customerData.prefix')"
                  filled
                  :hide-validations="true"
                  :rules="{ required: false }"
                />
              </div>
            </v-col>
            <v-col cols="8">
              <div>
                <VTextFieldWithValidation
                  :rules="{ required: false, isValidPhone: true }"
                  :counter="90"
                  :label="$t('directService.customerData.telephone')"
                  v-model="dataClientForSend['telephone']"
                />
              </div>
            </v-col>
          </v-row>
          <div class="mt-1 pa-2">
            <v-row>
              <v-col class="section-heading">
                {{ $t("directService.customerData.deliveryAddress.title") }}
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div>
                  <VTextFieldWithValidation
                    :rules="{
                      required: true,
                      alphanumcar_spaces: true,
                    }"
                    :counter="90"
                    :label="
                      $t('directService.customerData.deliveryAddress.street')
                    "
                    v-model="dataClientForSend['street']"
                  />
                </div>
                <v-row>
                  <v-col cols="4">
                    <div>
                      <VTextFieldWithValidation
                        :rules="{ num_car: true, required: true }"
                        :counter="10"
                        :label="
                          $t(
                            'directService.customerData.deliveryAddress.number'
                          )
                        "
                        v-model="dataClientForSend['number']"
                      />
                    </div>
                  </v-col>
                  <v-col cols="4">
                    <div>
                      <VTextFieldWithValidation
                        :rules="{
                          required: false,
                          alphanumcar_spaces: false,
                        }"
                        :counter="90"
                        :label="
                          $t('directService.customerData.deliveryAddress.floor')
                        "
                        v-model="dataClientForSend['floor']"
                      />
                    </div>
                  </v-col>
                  <v-col cols="4">
                    <div>
                      <VTextFieldWithValidation
                        :rules="{
                          required: true,
                          alphanumcar_spaces: true,
                        }"
                        :counter="90"
                        :label="
                          $t('directService.customerData.deliveryAddress.town')
                        "
                        type="text"
                        v-model="dataClientForSend['town']"
                      />
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <div>
                      <VSelectWithValidation
                        v-model="country"
                        :items="countryConfig"
                        :label="
                          $t(
                            'directService.customerData.deliveryAddress.country'
                          )
                        "
                        filled
                        :rules="{
                          alpha: true,
                          required: true,
                          is_not: 'OT',
                        }"
                        @change="changeCountry"
                      />
                    </div>
                  </v-col>
                  <v-alert v-if="country === 'OT'" icon="mdi-vuetify" text>
                    {{ $t("directService.customerData.alert") }}
                  </v-alert>
                  <v-col cols="6">
                    <div>
                      <VSelectWithValidation
                        v-if="country !== 'OT'"
                        v-model="dataClientForSend['provinces']"
                        :items="provinces"
                        :label="
                          $t(
                            'directService.customerData.deliveryAddress.provinces'
                          )
                        "
                        filled
                        :rules="{ required: true }"
                      />
                    </div>
                  </v-col>
                </v-row>
                <div>
                  <VTextFieldWithValidation
                    v-if="country !== 'OT'"
                    :rules="{ numeric: true, required: true }"
                    :counter="90"
                    :label="
                      $t(
                        'directService.customerData.deliveryAddress.postalCode'
                      )
                    "
                    v-model="dataClientForSend['postalCode']"
                  />
                </div>
              </v-col>
            </v-row>
          </div>

          <v-container>
            <ValidationProvider rules="required">
              <v-radio-group v-model="directionOption" @change="resetDate">
                <div class="section-heading">
                  {{
                    $t("directService.customerData.suitcaseCollection.title")
                  }}*
                </div>

                <v-radio
                  density="compact"
                  :label="
                    $t(
                      'directService.customerData.suitcaseCollection.permanentAddress'
                    )
                  "
                  :value="1"
                ></v-radio>
                <!-- @change="deseleccionarPermanente" -->
                <v-radio
                  density="compact"
                  :label="
                    $t(
                      'directService.customerData.suitcaseCollection.temporalAddress'
                    )
                  "
                  :value="2"
                  class="ma-0 pa-0"
                ></v-radio>
                <div v-if="directionOption === 1">
                  <v-row>
                    <v-col cols="6" class="mt-3">
                      <span>
                        {{
                          $t(
                            "directService.customerData.suitcaseCollection.ask1"
                          )
                        }}
                      </span>
                    </v-col>

                    <v-col cols="6">
                      <DatePickerComponent
                        v-model="permanentDate"
                        @input="fromDate"
                        :rules="{ required: true }"
                      />
                    </v-col>
                  </v-row>
                </div>

                <div v-if="directionOption === 2">
                  <v-row>
                    <v-col cols="6" class="mt-3">
                      <span>
                        {{
                          $t(
                            "directService.customerData.suitcaseCollection.ask2"
                          )
                        }}
                      </span>
                    </v-col>
                    <v-col cols="6">
                      <DatePickerComponent
                        v-model="temporalDate"
                        @input="toDate"
                        :rules="{ required: true }"
                      />
                    </v-col>
                  </v-row>
                </div>
              </v-radio-group>
            </ValidationProvider>
          </v-container>

          <div class="mt-1 pa-2">
            <VTextareaWithValidation
              :options="{
                required: false,
                max: 90,
              }"
              :counter="90"
              :label="
                $t('directService.customerData.suitcaseCollection.observations')
              "
              v-model="dataClientForSend['observations']"
              :hide-validations="true"
            ></VTextareaWithValidation>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import VTextFieldWithValidation from "@/components/dynamic/VTextFieldWithValidation.vue";
import VTextareaWithValidation from "@/components/dynamic/VTextareaWithValidation.vue";
import VSelectWithValidation from "@/components/dynamic/VSelectWithValidation.vue";
import * as provinceConfig from "@/configs/province";
import { mapStores } from "pinia";
import { useCountriesStore } from "@/stores/countriesStore";
import { useLanguageStore } from "@/stores/languageStore";
import {
  getCountryCallingCode,
  parsePhoneNumber,
  isValidPhoneNumber,
} from "libphonenumber-js";
import DatePickerComponent from "@/components/dynamic/DatePickerComponent.vue";
import { ValidationProvider } from "vee-validate";
export default {
  name: "ClientForm",
  components: {
    VTextFieldWithValidation,
    VTextareaWithValidation,
    VSelectWithValidation,
    DatePickerComponent,
    ValidationProvider,
  },
  data: function () {
    return {
      provinces: [],
      directionOption: null,
      dataClientForSend: {},
      config: null,
      actualStep: 2,
      steps: 2,
      dirPermanente: false,
      temporalAddress: false,
      country: null,
      mobilePrefixSelected: null,
      mobilePrefixes: null,
      telephonePrefixSelected: null,
      permanentDate: null,
      temporalDate: null,
      fromdate: null,
      todate: null,
      prefixMobile: null,
      prefixTelephone: null,
      countryConfig: [
        {
          name: this.$t("directService.customerData.spain"),
          value: "ES",
        },
        {
          name: this.$t("directService.customerData.portugal"),
          value: "PT",
        },
        { name: this.$t("directService.customerData.other"), value: "OT" },
      ],
    };
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    surname: {
      type: String,
      required: true,
    },
  },
  watch: {
    country(value) {
      if (!Object.keys(provinceConfig).includes(value)) {
        this.provinces = [];
        return;
      }
      this.dataClientForSend["country"] = this.countryConfig.find(
        (c) => c.value === value
      );
      this.provinces = [...provinceConfig[value]];
    },
  },
  computed: {
    ...mapStores(useCountriesStore, useLanguageStore),
    showField() {
      return this.dirPermanente || this.temporalAddress;
    },
  },
  created() {
    this.loadCountriesDapi();
  },
  methods: {
    fromDate(date) {
      this.dataClientForSend.fromdate = date;
    },
    toDate(date) {
      this.dataClientForSend.todate = date;
    },
    changeCountry() {
      this.dataClientForSend["provinces"] = null;
      this.dataClientForSend["postalCode"] = "";
    },
    resetDate() {
      this.temporalDate = null;
      this.permanentDate = null;
      if (this.directionOption === 1) {
        this.dataClientForSend.permanentDate = this.fromdate;
        this.dataClientForSend.todate = null;
        this.dataClientForSend.temporalAddress = false;
      } else if (this.directionOption === 2) {
        this.dataClientForSend.temporalDate = this.todate;
        this.dataClientForSend.fromdate = null;
        this.dataClientForSend.temporalAddress = true;
      }
    },
    async loadCountriesDapi() {
      await this.countriesStore.loadCountriesDapi();
      this.mobilePrefixes = this.countriesStore.countriesDapi.map((country) => {
        return { name: country?.translations?.es, value: country.alpha2Code };
      });
      this.moveSpainToTop();
    },
    /**
     * Método para mover el prefijo móvil de España al principio de la lista.
     */
    moveSpainToTop() {
      let spainIndex = this.mobilePrefixes.findIndex(
        (prefix) => prefix.value === "ES"
      );
      if (spainIndex !== -1) {
        let spain = this.mobilePrefixes.splice(spainIndex, 1)[0];
        this.mobilePrefixes.unshift(spain);
      }
    },
    prefixSelected({ field, value }) {
      this.prefix = getCountryCallingCode(value);
      this.dataClientForSend[field + "PrefixSelected"] = this.prefix;
      if (
        !this.dataClientForSend[field] ||
        !isValidPhoneNumber(this.dataClientForSend[field])
      ) {
        this.dataClientForSend[field] = "+" + this.prefix;
        return;
      }
      this.dataClientForSend[field] = parsePhoneNumber(
        this.dataClientForSend[field]
      ).nationalNumber;
    },
  },
};
</script>

<style scoped lang="scss">
.main-background {
  background-color: #fafafa;
}
.client-form {
  .v-messages__message {
    line-height: 12px;
    word-break: normal;
    overflow-wrap: normal;
    word-wrap: normal;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: inherit;
  }
}
.main-header {
  height: 250px;
  font-size: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2d69e1;
  h1 {
    font-family: AERadar;
    color: white;
    @media (max-width: 920px) {
      font-size: 40px;
    }
  }
}
.section-heading {
  color: #0f2367;
  font-family: AERadar;
  font-size: 16px;
  padding-bottom: 15px;
}
.ae-description {
  font-size: 14px;
  font-family: Lato, -apple-system, blinkmacsystemfont, Segoe UI, Roboto,
    Helvetica Neue, arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    Segoe UI Symbol, icomoon;
  margin-bottom: 16px;
  text-decoration: none;
  color: #565656;
}
</style>
