import AnimationError from "@/components/AnimationError";
import AnimationSuccess from "@/components/AnimationSuccess";
import { datadogRum } from "@datadog/browser-rum";
import Vue from "vue";

import App from "./App.vue";
import { i18n } from "./i18n/translation";
import vuetify from "./plugins/vuetify";
import pinia from "./stores/store";
import router from "./router";
// import VueCompositionApi from "@vue/composition-api";

import "./plugins/vee-validate";
import "@/styles/main.scss";

const email = process.env.VUE_APP_CRMEMAIL;

// Vue.use(VueCompositionApi);
Vue.component("animation-error", AnimationError);
Vue.component("animation-success", AnimationSuccess);
Vue.config.productionTip = false;
Vue.prototype.$crmEmail = email;

const rum_enabled = process.env.VUE_APP_RUM_ENABLED;

if (rum_enabled == true || rum_enabled == "true") {
  const appid = process.env.VUE_APP_DATADOG_APPID;
  const clientToken = process.env.VUE_APP_DATADOG_TOKEN;
  const site = process.env.VUE_APP_DATADOG_SERVER;
  const env = process.env.VUE_APP_DATADOG_ENV;

  datadogRum.init({
    applicationId: appid,
    clientToken: clientToken,
    site: site,
    service: "suma-customerservice-front",
    env: env,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: "mask-user-input",
  });
  datadogRum.startSessionReplayRecording();
}

new Vue({
  vuetify,
  router,
  pinia,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
