<template>
  <div>
    <FeedBackView mode="error" :title="$t('text.expired')" />
  </div>
</template>

<script>
import FeedBackView from "@/components/common/FeedbackView";

export default {
  name: "ExpiredPage",
  components: {
    FeedBackView,
  },
};
</script>
