<template>
  <div>
    <v-container class="suitcase-form">
      <div v-for="(tagBuckup, i) in suitCase" :key="i">
        <h4>Maleta - {{ i + 1 }}</h4>
        <v-row>
          <v-col cols="6">
            <VTextFieldWithValidation
              :rules="{ alphanumcar_spaces: true, required: false }"
              :counter="90"
              :label="$t('directService.suitcaseData.brand')"
              v-model="suitcaseDataForSend[tagBuckup]['brand']"
            />
          </v-col>
          <v-col cols="6">
            <VTextFieldWithValidation
              :rules="{ alphanumcar_spaces: true, required: false }"
              :counter="90"
              :label="$t('directService.suitcaseData.model')"
              v-model="suitcaseDataForSend[tagBuckup]['model']"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <VSelectWithValidation
              v-model="suitcaseDataForSend[tagBuckup]['material']"
              :items="materials"
              :label="$t('directService.suitcaseData.material')"
              filled
              :rules="{ required: true }"
              @change="changeMaterial(tagBuckup)"
            />
          </v-col>
          <!-- <v-col cols="6" v-if="selectOtherMaterial">
            <VTextFieldWithValidation
              type="text"
              :label="$t('directService.suitcaseData.otherMaterial')"
              v-model="othMaterial"
              :rules="{
                required:
                  suitcaseDataForSend[tagBuckup]['material'].value === 'X',
              }"
              :counter="90"
              @change="changeMaterial(tagBuckup)"
            />
          </v-col> -->
          <v-col cols="6">
            <VSelectWithValidation
              v-model="suitcaseDataForSend[tagBuckup]['color']"
              :items="colors"
              :label="$t('directService.suitcaseData.color')"
              filled
              :rules="{ required: true }"
              @change="changeColor(tagBuckup)"
            />
          </v-col>
          <!-- <v-col cols="6" v-if="selectOtherColor">
            <VTextFieldWithValidation
              type="text"
              :label="$t('directService.suitcaseData.otherColor')"
              v-model="othColor"
              :counter="90"
              @change="changeColor(tagBuckup)"
            />
          </v-col> -->
        </v-row>

        <v-row>
          <v-col class="ma-0 pt-0" cols="12" sm="4">
            <VTextFieldWithValidation
              :rules="{ num_car: true, required: true }"
              :counter="90"
              :label="$t('directService.suitcaseData.long')"
              v-model="suitcaseDataForSend[tagBuckup]['long']"
            />
          </v-col>
          <v-col class="ma-0 pt-0" cols="12" sm="4">
            <VTextFieldWithValidation
              :rules="{ num_car: true, required: true }"
              :counter="90"
              :label="$t('directService.suitcaseData.width')"
              v-model="suitcaseDataForSend[tagBuckup]['width']"
            />
          </v-col>
          <v-col class="ma-0 pt-0" cols="12" sm="4">
            <VTextFieldWithValidation
              :rules="{ num_car: true, required: true }"
              :counter="90"
              :label="$t('directService.suitcaseData.high')"
              v-model="suitcaseDataForSend[tagBuckup]['high']"
            />
          </v-col>
        </v-row>

        <VSelectWithValidation
          :items="nRuedas"
          filled
          :rules="{ required: true }"
          :label="$t('directService.suitcaseData.wheels')"
          v-model="suitcaseDataForSend[tagBuckup]['wheels']"
          @change="wheelsChange(tagBuckup)"
        />

        <VTextareaWithValidation
          :counter="90"
          :options="{ required: true }"
          :label="$t('directService.suitcaseData.damageDescription')"
          v-model="suitcaseDataForSend[tagBuckup]['damageDescription']"
        ></VTextareaWithValidation>

        <VTextareaWithValidation
          :counter="90"
          :options="{ required: false }"
          :label="$t('directService.suitcaseData.additionalDescription')"
          v-model="suitcaseDataForSend[tagBuckup]['additionalDescription']"
        ></VTextareaWithValidation>

        <VSelectWithValidation
          :items="items"
          :rules="{ required: true }"
          filled
          :label="$t('directService.suitcaseData.extendableHandle')"
          v-model="suitcaseDataForSend[tagBuckup]['extendableHandle']"
        />

        <VSelectWithValidation
          :items="items"
          :rules="{ required: true }"
          filled
          :label="$t('directService.suitcaseData.hasClosures')"
          v-model="suitcaseDataForSend[tagBuckup]['hasClosures']"
        />

        <VSelectWithValidation
          :items="items"
          :rules="{ required: true }"
          filled
          :label="$t('directService.suitcaseData.hasZippers')"
          v-model="suitcaseDataForSend[tagBuckup]['hasZippers']"
        />
      </div>
    </v-container>
  </div>
</template>

<script>
import VTextFieldWithValidation from "@/components/dynamic/VTextFieldWithValidation.vue";
import VTextareaWithValidation from "@/components/dynamic/VTextareaWithValidation.vue";
import VSelectWithValidation from "@/components/dynamic/VSelectWithValidation.vue";

export default {
  name: "SuitcaseForm",
  props: {
    contactId: {
      type: String,
      required: true,
    },
    suitCases: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    VTextFieldWithValidation,
    VTextareaWithValidation,
    VSelectWithValidation,
  },
  data: function () {
    return {
      suitcaseDataForSend: {},
      materials: [
        {
          name: this.$t("directService.material.hard"),
          value: "R",
        },
        {
          name: this.$t("directService.material.soft"),
          value: "B",
        },
        {
          name: this.$t("directService.material.fur"),
          value: "L",
        },
        {
          name: this.$t("directService.material.metal"),
          value: "M",
        },
        {
          name: this.$t("directService.material.regid"),
          value: "R",
        },
        {
          name: this.$t("directService.material.semiregid"),
          value: "SR",
        },
        {
          name: this.$t("directService.material.other"),
          value: "OT",
        },
      ],
      colors: [
        {
          name: this.$t("directService.colors.beige"),
          value: "BE",
        },
        {
          name: this.$t("directService.colors.black"),
          value: "BK",
        },
        {
          name: this.$t("directService.colors.brown"),
          value: "BN",
        },
        {
          name: this.$t("directService.colors.blue"),
          value: "BU",
        },
        {
          name: this.$t("directService.colors.green"),
          value: "GN",
        },
        {
          name: this.$t("directService.colors.grey"),
          value: "GY",
        },
        {
          name: this.$t("directService.colors.multicolored"),
          value: "MC",
        },
        {
          name: this.$t("directService.colors.orange"),
          value: "OR",
        },
        {
          name: this.$t("directService.colors.print"),
          value: "PR",
        },
        {
          name: this.$t("directService.colors.purple"),
          value: "PU",
        },
        {
          name: this.$t("directService.colors.red"),
          value: "RD",
        },
        {
          name: this.$t("directService.colors.checkered"),
          value: "SQ",
        },
        {
          name: this.$t("directService.colors.white"),
          value: "WT",
        },
        {
          name: this.$t("directService.colors.yellow"),
          value: "YW",
        },
        {
          name: this.$t("directService.colors.other"),
          value: "X",
        },
      ],
      nRuedas: [
        this.$t("directService.suitcaseData.withoutWheels"),
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
      ],
      items: [
        {
          name: this.$t("directService.suitcaseData.yes"),
          value: true,
        },
        {
          name: this.$t("directService.suitcaseData.no"),
          value: false,
        },
      ],
      othMaterial: "",
      othColor: "",
      numberOfSuitcases: [],
      suitCase: [],
    };
  },
  watch: {},
  computed: {},
  mounted() {
    this.suitCases.length &&
      this.suitCases.forEach((suitCase) => {
        this.suitcaseDataForSend[suitCase] = {};
        this.$set(this.suitcaseDataForSend, suitCase, {});
      });
    this.suitCase = this.suitCases;
  },
  created() {},
  methods: {
    changeMaterial(tagBuckup) {
      const introducedMaterial =
        this.suitcaseDataForSend[tagBuckup]["material"];
      this.suitcaseDataForSend[tagBuckup].introducedMaterial =
        introducedMaterial;
    },
    changeColor(tagBuckup) {
      const introducedColor = this.suitcaseDataForSend[tagBuckup]["color"];
      this.suitcaseDataForSend[tagBuckup].introducedColor = introducedColor;
    },
    wheelsChange(tagBuckup) {
      const selectedWheels = this.suitcaseDataForSend[tagBuckup]["wheels"];

      if (
        selectedWheels === this.$t("directService.suitcaseData.withoutWheels")
      ) {
        this.suitcaseDataForSend[tagBuckup].selectedWheels = "0";
      } else {
        this.suitcaseDataForSend[tagBuckup].selectedWheels = selectedWheels;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.main-background {
  background-color: #fafafa;
}
.suitcase-form {
  .v-messages__message {
    line-height: 12px;
    word-break: normal;
    overflow-wrap: normal;
    word-wrap: normal;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: inherit;
  }
}
.main-header {
  height: 250px;
  font-size: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2d69e1;
  h1 {
    font-family: AERadar;
    color: white;
    @media (max-width: 920px) {
      font-size: 40px;
    }
  }
}
.section-heading {
  color: #0f2367;
  font-family: AERadar;
  font-size: 16px;
  padding-bottom: 15px;
}
.ae-description {
  font-size: 14px;
  font-family: Lato, -apple-system, blinkmacsystemfont, Segoe UI, Roboto,
    Helvetica Neue, arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    Segoe UI Symbol, icomoon;
  margin-bottom: 16px;
  text-decoration: none;
  color: #565656;
}
</style>
