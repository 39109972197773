<template src="./CompensationTemplate.html"></template>
<!-- <style src="./Compensation.css" scoped></style> -->
<script>
/*
 *  colocar elementos del modulo en el orden: created, data, computed, watch, methods
 */
/*
 *  Notas: crear componente reutilizable que contenga: encabezado estetica + el preloader y manejo de errores*.
 *  manejo de errores: el content-switch puede ser equivalente a try catch en templates.
 */
/*
  al cargar los datos , crear el ObjetocustomerData selection vacio pero con las claves.

*/
import Api from "@/api/api.js";
import Aebond from "./Aebond";
import Monetary from "./monetaryform/Monetary";
import Layout from "@/components/Layout";
import TcAebond from "./TcAebond";
import TcMonetary from "./TcMonetary";
import TcMiles from "./TcMiles";
import FeedBackView from "@/components/common/FeedbackView";
import AnimationError from "@/components/AnimationError";
import AnimationSuccess from "@/components/AnimationSuccess";
import BankDetailsSapErrors from "../dialogs/BankDetailsSapErrors.vue";
import DynamicForm from "@/components/dynamic/DynamicForm.vue";
import { mapStores, mapActions } from "pinia";
import { useCountriesStore } from "@/stores/countriesStore";
import { useUiStore } from "@/stores/uiStore";
import { useLanguageStore } from "@/stores/languageStore";
import { useTokenStore } from "@/stores/tokenStore";
import { ValidationObserver } from "vee-validate";
import { validateValues } from "@/util/validators.js";

export default {
  name: "CompensationComponent",
  components: {
    Aebond,
    AnimationError,
    AnimationSuccess,
    BankDetailsSapErrors,
    ValidationObserver,
    FeedBackView,
    Layout,
    Monetary,
    TcAebond,
    TcMiles,
    TcMonetary,
    DynamicForm,
  },
  data() {
    return {
      validateChildren: null,
      bankDetails: null,
      defaultValues: null,
      // monetaryOffered: false,
      refundBankDetails: {
        valid: false,
        data: {},
      },
      bankDetailsSapErrorsDialog: {
        open: false,
        errors: [],
      },
      showValidations: false,
      compensationFormUpdated: 0, // this.validatorTick > this.compensationFormUpdated
      validatorTick: 0,
      enabledCompensationAebond: false,
      enabledCompensationMiles: false,
      enabledCompensationMonetary: false,
      expanded: {},
      lastMonetaryClick: null,
      monetarySelected: null,
      validationState: {},
      selected: {},
      selection: {},
      smallScreen: false,
      validationMessage: null,
      compensationDialog: null,
      infoDialog: false,
      STATUS_OK: 0,
      STATUS_LOADERR: 1,
      STATUS_ACCEPTERR: 2,
      STATUS_ACCEPTOK: 3,
      STATUS_PENDINGREVIEW: 4,
      STATUS_CASECLOSED: 5,
      STATUS_ALREADY_UPDATED: 6,
      status: null,
      currencyCountries: null,
      dirty: {},
      valid: {},
      caseId: null,
      token: null,
      formType: null,
      debugError: "",
      dialog: false,
      selectionList: [],
      summary: [],
      expenses: [],
      headers: [],
      customerSelection: {},
      customerData: [],

      feedBackView: {
        mode: null,
        title: null,
        msg: null,
        hidetitle: false,
      },
    };
  },
  computed: {
    ...mapStores(useLanguageStore, useTokenStore, useCountriesStore),
    isThereMonetaryCompensation: {
      cache: false,
      get() {
        const selectionList = this.getSelectionList();
        for (const i in selectionList) {
          if (selectionList[i].MonetaryCompensationAcceptedText == "Accepted") {
            return true;
          }
        }
        return false;
      },
    },
    isOnlyAeBond() {
      if (Object.keys(this.selection).length === 0) return false;
      return Object.values(this.selection).every((el) => el === "Aebond");
    },
    showBankDetails: {
      cache: false,
      get() {
        return Object.values(this.selection).includes("Monetary");
      },
    },
    isMoreThanOnePax() {
      return this.customerData ? this.customerData.length > 1 : false;
    },
    /**
     * Mira que se haya escogido una compensación por cada uno
     */
    allCompensationsSelected: {
      // 552010
      cache: false,
      get() {
        const index = this.customerData.reduce((acc, curr) => {
          acc[curr.ClientID] = curr;
          return acc;
        }, {});
        const clients = this.customerData
          .map((k) => {
            return k.ClientID;
          })
          .filter((k) => {
            return !this.hasNoCompensations(index[k]);
          });

        const result = Object.keys(this.selection).length == clients.length;
        return result;
      },
    },
    totalExpenses() {
      if (this.expenses == null) {
        return 0.0;
      }
      if (this.expenses.length == 0) return 0;

      /* var total = this.expenses.reduce((acc,cur) =>{
          return acc + Number(cur.ExpenseAmount);
        },0); */
      return (
        this.expenses.AdditionalExpensesAmount +
        this.expenses.ReimbursementServicesAmount
      );
    },
    isThereRefund() {
      return (
        this.isThereAdditionalExpenses || this.isThereReimbursementServices
      );
    },
    isThereAdditionalExpenses() {
      return this.expenses.AdditionalExpensesAmount > 0;
    },
    isThereReimbursementServices() {
      return this.expenses.ReimbursementServicesAmount > 0;
    },
    bankDetailsRequired() {
      return this.isThereAdditionalExpenses;
    },
    debugSelection() {
      return JSON.stringify(this.selectionList);
      // return 'test';
    },
    translatedHeaders() {
      return this.headers.map((h) => {
        return {
          text: this.$i18n.t("compensation.".concat(h.text)),
          value: h.value,
        };
      });
    },
  },
  watch: {
    allCompensationsSelected(newValue) {
      if (newValue) {
        this.validationMessage = null;
      }
    },
    validationFixed(newValue) {
      if (newValue) {
        this.validationMessage = null;
      }
    },
    valid: {
      deep: true,
      handler(newValue) {
        this.toto = newValue;
      },
    },
  },
  created() {
    if (window.innerWidth < 920) {
      this.smallScreen = true;
    }
    try {
      this.loadData(this.tokenStore.caseId, this.tokenStore.token);
      // eslint-disable-next-line  no-unused-vars
    } catch (err) {
      console.error(err);

      this.changeSpinner(false);
      this.status = this.STATUS_LOADERR;
    }
  },
  methods: {
    ...mapActions(useUiStore, ["changeSpinner"]),
    btnSendDisabled(invalid) {
      if (!this.allCompensationsSelected) return true;
      if (
        this.showBankDetails &&
        (!this.$refs?.formMonetary?.config ||
          !this.$refs?.formMonetary?.dataForSend)
      ) {
        return true;
      }
      if (this.showBankDetails) {
        return invalid;
      }
      if (this.enabledCompensationMiles) {
        return false;
      }
      if (this.isOnlyAeBond) {
        return false;
      }
      return true;
    },
    loadFeedBackViewConfigByStatusCode(statusCode) {
      const statusCodeConfig = {
        [this.STATUS_ACCEPTOK]: {
          mode: "success",
          title: this.$t("compensation.formSuccess"),
        },
        [this.STATUS_PENDINGREVIEW]: {
          mode: "error",
          title: this.$t("compensation.pendingReview"),
        },
        [this.STATUS_LOADERR]: {
          mode: "error",
          title: this.$t("compensation.formLoadError"),
          text: this.$t("bankDetails.supportContact", {
            email: this.$crmEmail,
          }),
        },
        [this.STATUS_ACCEPTERR]: {
          mode: "error",
          title: this.$t("compensation.submitError"),
          text: this.$t("bankDetails.supportContact", {
            email: this.$crmEmail,
          }),
        },
        [this.STATUS_ALREADY_UPDATED]: {
          mode: "error",
          hidetitle: true,
          title: this.$t("compensation.alreadyWorking"),
          text: this.$t("compensation.alreadyWorking", {
            email: this.$crmEmail,
          }),
        },
        [this.STATUS_CASECLOSED]: {
          mode: "error",
          title: this.$t("compensation.caseClosedError", {
            email: this.$crmEmail,
          }),
        },
        default: {
          mode: "error",
          title: this.$t("bankDetails.formLoadError"),
          text: this.$t("bankDetails.supportContact", {
            email: this.$crmEmail,
          }),
        },
      };
      return statusCodeConfig[statusCode]
        ? statusCodeConfig[statusCode]
        : statusCodeConfig["default"];
    },
    loadFeedBack({ mode = null, title = null, msg = null, hidetitle = false }) {
      this.feedBackView = {
        mode,
        title,
        msg,
        hidetitle,
      };
    },
    hasNoCompensations(client) {
      if (
        client.AEBondOffered == "false" &&
        client.MonetaryOffered == "false" &&
        client.MilesOffered == "false"
      ) {
        return true;
      }

      return false;
    },
    findEnabledCompensations(customerData) {
      for (const c in customerData) {
        const client = customerData[c];

        if (client.AEBondOffered == "true" || client.AEBondOffered == "True") {
          this.enabledCompensationAebond = true;
        }
        if (
          client.MonetaryOffered == "true" ||
          client.MonetaryOffered == "True"
        ) {
          this.enabledCompensationMonetary = true;
        }
        if (client.MilesOffered == "true" || client.MilesOffered == "True") {
          this.enabledCompensationMiles = true;
        }
      }
    },
    filterExpenses(expenses) {
      if (this.expenses == null) {
        return [];
      }
      return expenses.filter((e) => {
        return Number(e.ExpenseAmount) > 0;
      });
    },
    formatNumber(s) {
      return s.toFixed(2);
    },

    clearMonetary() {
      // eliminar los datos de monetario:
      const ids = this.customerData.map((r) => {
        return r.ClientID;
      });
      for (const i in ids) {
        const clientId = ids[i];
        if (this.selection[clientId] == "Monetary") {
          this.expanded[clientId] = false;
          delete this.selection[clientId];
          this.valid[clientId] = false;
          this.customerSelection[clientId];
        }
      }
    },
    clickAeBond(caseData, clientId) {
      if (this.expanded[clientId] && this.selection[clientId] == "Monetary") {
        this.clearMonetary();
      }
      this.expanded[clientId] = true;

      const compensationType = "Aebond";
      this.$set(this.selection, clientId, compensationType);
      this.$set(this.customerSelection, clientId, {
        compensationType: compensationType,
        data: caseData,
      });
      this.$forceUpdate();
      return true;
    },
    selectMonetary(clientId) {
      this.expanded[clientId] = false;

      this.valid[clientId] = true;
      this.dirty[clientId] = true;

      this.$set(this.validationState, clientId, {
        compensationType: "monetary",
        valid: true,
      });
      this.$set(this.selection, clientId, "Monetary");
      this.$set(this.customerSelection, clientId, {
        compensationType: "Monetary",
      });
      this.$set(this.valid, clientId, true);
      this.$forceUpdate();
      return true;
    },
    selectMiles(clientId) {
      if (this.expanded[clientId] && this.selection[clientId] == "Monetary") {
        this.clearMonetary();
      }
      this.expanded[clientId] = false;

      this.dirty[clientId] = true;

      this.$set(this.validationState, clientId, {
        compensationType: "miles",
        valid: true,
      });
      this.$set(this.selection, clientId, "miles");
      this.$set(this.customerSelection, clientId, {
        compensationType: "miles",
        data: {},
      });
      this.$set(this.valid, clientId, true);

      this.$forceUpdate();
      return true;
    },
    onResize() {
      if (window.innerWidth < 920) {
        this.smallScreen = true;
      } else {
        this.smallScreen = false;
      }
    },
    rowStatusColor(clientId) {
      const blue = {
        // 'textbankdetails':true
      };
      if (this.validationMessage == null) {
        return blue;
      }
      if (
        Object.prototype.hasOwnProperty.call(this.valid, clientId) &&
        !this.valid[clientId]
      ) {
        return {
          //  'red--text':true
        };
      }

      return blue;
    },
    compensationInfo(compensationType) {
      this.compensationDialog = compensationType;
      this.infoDialog = true;
    },

    disableDialog() {
      this.infoDialog = false;
    },
    isValid() {
      if (this.valid == null || this.valid == undefined) {
        this.valid = {};
      }

      return false;
    },
    validationStateChanged(ev) {
      this.compensationFormUpdated = new Date().getTime();
      this.validationState[ev.clientId] = {
        compensationType: ev.compensationType,
        valid: ev.valid,
      };
    },
    shouldExpand() {
      return true;
    },
    isEnableAEBond(client) {
      return client.AEBondOffered == "true" || client.AEBondOffered == "True";
    },
    isEnabledMonetary(client) {
      return (
        client.MonetaryOffered == "true" || client.MonetaryOffered == "True"
      );
    },
    isEnabledMiles(client) {
      return client.MilesOffered == "true" || client.MilesOffered == "True";
    },
    loadData(caseId, token) {
      try {
        this.changeSpinner(true);

        Api.loadCompensationOptions(caseId, token)
          .then((response) => {
            const status = response.data.StatusCode;
            this.defaultValues = response.data;
            if (status != this.STATUS_OK) {
              const compensationStatus = {
                7: this.STATUS_PENDINGREVIEW,
                2: this.STATUS_ALREADY_UPDATED,
                8: this.STATUS_CASECLOSED,
                default: this.STATUS_LOADERR,
              };
              this.status =
                compensationStatus[status] || compensationStatus["default"];

              const loadedConfigByData =
                this.loadFeedBackViewConfigByStatusCode(this.status);
              this.loadFeedBack(loadedConfigByData);
              this.changeSpinner(false);
            } else {
              if (
                Object.prototype.hasOwnProperty.call(response.data, "Language")
              ) {
                var lang = response.data.Language;
                this.$i18n.locale = lang.toLowerCase();
              }

              this.status = this.STATUS_OK;
              Api.getCountries(lang.toUpperCase())
                .then((countriesResp) => {
                  const countryData = countriesResp.data;
                  const countryCode = response.data.CountryCode;
                  this.customerData = response.data.clients;

                  let countryName = null;
                  for (const i in countryData.CountriesList) {
                    const item = countryData.CountriesList[i];
                    if (item.CountryCode == countryCode) {
                      countryName = item.CountryName;
                      break;
                    }
                  }

                  this.bankDetails = {
                    AccountHolderDocNumber:
                      response.data.AccountHolderDocNumber,
                    AccountHolderDocType: response.data.AccountHolderDocType,
                    AccountHolderName: response.data.AccountHolderName,
                    AccountNumber: response.data.AccountNumber,
                    BankCode: response.data.BankCode,
                    BankName: response.data.BankName,
                    CountryCode: response.data.CountryCode,
                    CountryName: countryName,
                    IBAN: response.data.IBAN,
                    FullAddress: response.data.FullAddress,
                    SWIFT: response.data.SWIFT,
                    SWIFT2: response.data.SWIFT2,

                    RefBank: response.data.RefBank,
                    AccountTypeBankDetails:
                      response.data.AccountTypeBankDetails,
                    AddressLine2: response.data.AddressLine2,
                  };

                  this.monetaryOffered = this.customerData
                    .map((item) => {
                      return item.MonetaryOffered == "true";
                    })
                    .reduce((acc, curr) => {
                      return acc || curr;
                    }, false);

                  this.findEnabledCompensations(this.customerData);
                  this.expenses = {
                    AdditionalExpensesAmount: Number(
                      response.data.AdditionalExpensesAmount
                    ),
                    ReimbursementServicesAmount: Number(
                      response.data.ReimbursementServicesAmount
                    ),
                  };

                  this.$forceUpdate();
                  // create empty form model this.countries
                  this.changeSpinner(false);
                  this.currencyCountries =
                    response.data?.CurrencyCountries?.map((i) => i.Code);

                  this.countriesStore.filterWithAvailableCurrency(
                    this.currencyCountries
                  );

                  // this.countries =
                  //   this.countriesStore.countriesListFilteredByCountry;
                })
                // eslint-disable-next-line no-unused-vars
                .catch((err) => {
                  console.error(err);

                  this.changeSpinner(false);
                  this.status = this.STATUS_LOADERR;
                });
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((err) => {
            console.error(err);
            this.changeSpinner(false);
            this.status = this.STATUS_LOADERR;
          });
      } catch (error) {
        console.error(error);
      }
    },
    compensationMiles(clientId, init) {
      const r = this.emptyCompensation(init);
      r.MilesCompensationAcceptedText = "Accepted";
      return r;
    },
    compensationAebond(clientId, init, data) {
      if (!init) return;
      const r = this.emptyCompensation(init);
      r.AEBondCompensationAcceptedText = "Accepted";
      r.BeneficarySecondLastName = data.lastName2;
      r.BeneficiaryDocType = data.documentType;
      r.BeneficiaryDocNumber = data.documentNumber;
      return r;
    },
    compensationMonetary(init) {
      const r = this.emptyCompensation(init);
      r.MonetaryCompensationAcceptedText = "Accepted";
      return r;
    },
    emptyCompensation(init) {
      return {
        ClientId: init.ClientID,
        BeneficiaryDocType: Object.prototype.hasOwnProperty.call(
          init,
          "BeneficiaryDocType"
        )
          ? init.BeneficiaryDocType
          : "",
        BeneficiaryDocNumber: init.BeneficiaryDocNumber,
        MilesCompensationAcceptedText: "Rejected",
        MonetaryCompensationAcceptedText: "Rejected",
        AEBondCompensationAcceptedText: "Rejected",
      };
    },
    //confirm cbon
    sendDialog() {
      if (!this.allCompensationsSelected) {
        this.showValidations = true;
        return;
      }
      const check = this.checkRequiredFields();

      if (
        (check && typeof this.$refs?.formMonetary?.config == "undefined") ||
        (check && this.checkRequiredBankFieldsRFGA())
      ) {
        const summary = this.getSummary();
        this.summary = summary;
        this.dialog = true;
      }
    },
    indexCustomerData() {
      const customerData = this.customerData;
      return customerData.reduce((acc, item) => {
        acc[item.ClientID] = item;
        return acc;
      }, {});
    },
    getSummary() {
      const summary = [];
      const selectionList = this.getSelectionList();
      const index = this.indexCustomerData();
      for (const s in selectionList) {
        const item = selectionList[s];

        const miles = item.MilesCompensationAcceptedText == "Accepted";
        const bond = item.AEBondCompensationAcceptedText == "Accepted";
        const monetary = item.MonetaryCompensationAcceptedText == "Accepted";
        const c = index[item.ClientId];

        // lets check if there is BeneficarySecondLastName
        let secondLastName = null;
        const customer = this.customerSelection[item.ClientId];

        if (customer.compensationType == "Aebond") {
          if (customer.data.lastName2 != null) {
            secondLastName = customer.data.lastName2.toUpperCase();
          }
        }
        const name =
          c.ClientGender +
          " " +
          c.BeneficiaryFirstName +
          " " +
          (c.BeneficiaryLastName == null ? "" : c.BeneficiaryLastName) +
          (secondLastName != null ? " " + secondLastName : "");
        if (miles) {
          summary.push({
            name: name,
            compensationType: this.$i18n.t("compensation.milesCompensation"),
            amount: c.AEMilesAmount + " " + this.$i18n.t("compensation.miles"),
          });
        } else if (bond) {
          summary.push({
            name: name,
            compensationType: this.$i18n.t("compensation.AEBond"),
            amount:
              c.AEBondAmount != null
                ? Number(c.AEBondAmount).toFixed(2) + " €"
                : "",
          });
        } else if (monetary) {
          summary.push({
            name: name,
            compensationType: this.$i18n.t("compensation.Monetary"),
            amount:
              c.MonetaryAmountInEUR != null
                ? Number(c.MonetaryAmountInEUR).toFixed(2) + " €"
                : "",
          });
        }
      }
      return summary;
    },
    getSelectionList() {
      const entries = Object.entries(this.customerSelection);
      if (entries.length == 0) {
        return [];
      }
      const selectionList = [];

      for (const c in this.customerData) {
        const d = this.customerData[c];
        if (this.hasNoCompensations(d)) {
          continue;
        }
        const clientId = d.ClientID;

        if (this.customerSelection[clientId] === undefined) {
          continue;
        }
        const compensationType =
          this.customerSelection[clientId].compensationType;
        const data = this.customerSelection[clientId].data;

        switch (compensationType) {
          case "miles":
            selectionList.push(this.compensationMiles(clientId, d, data));
            break;
          case "Aebond":
            selectionList.push(this.compensationAebond(clientId, d, data));
            break;
          case "Monetary":
            selectionList.push(this.compensationMonetary(d));
            break;
        }
      }
      return selectionList;
    },
    bankDetailsSapErrorsDialogToggle() {
      this.bankDetailsSapErrorsDialog.open =
        !this.bankDetailsSapErrorsDialog.open;
      // if (event) this.resetForm();
    },
    //comprueba que en la seleccion multiple de beneficiarios tenga rellenado el BeneficiaryDocNumber
    checkRequiredFields() {
      const list = this.getSelectionList();
      for (let item in list) {
        let clientForm = list[item];
        if (
          clientForm.BeneficiaryDocNumber === "" &&
          typeof this.$refs?.formMonetary == "undefined"
        ) {
          return false;
        }
      }
      return true;
    },
    checkRequiredBankFieldsRFGA() {
      if (typeof this.$refs?.formMonetary?.config != "undefined") {
        if (!validateValues(this.$refs?.formMonetary?.dataForSend)) {
          return false;
        }
        if (this.isNotValidIBAN()) {
          return false;
        }
        for (var field in this.$refs?.formMonetary?.config) {
          let found = this.$refs?.formMonetary?.config[field];

          let datastring = JSON.stringify(
            this.$refs?.formMonetary?.dataForSend
          );
          if (!datastring.includes(found.field)) {
            return false;
          }
        }
        return true;
      } else {
        return false;
      }
    },
    resetForm() {
      this.$refs.formMonetary.resetForm();
      this.$nextTick(() => {
        this.$refs.obs.reset();
      });
    },
    isNotValidIBAN() {
      let dataFormConfig;
      for (var field in this.$refs?.formMonetary?.config) {
        let found = this.$refs?.formMonetary?.config[field];
        if (
          found.field == "IBAN" &&
          this.$refs?.formMonetary?.dataForSend.IBAN
        ) {
          dataFormConfig = found;
        }
      }
      if (
        typeof dataFormConfig != "undefined" &&
        typeof this.$refs?.formMonetary?.dataForSend.IBAN != "undefined"
      ) {
        let cod = this.$refs?.formMonetary?.dataForSend.IBAN.substring(0, 2);
        if (
          cod != dataFormConfig.countryCode ||
          this.$refs?.formMonetary?.dataForSend.IBAN.length >
            parseInt(dataFormConfig.maxLength) ||
          this.$refs?.formMonetary?.dataForSend.IBAN.length <
            parseInt(dataFormConfig.minLength)
        ) {
          return true;
        }
      }
      return false;
    },
    formDataChanged(ev) {
      this.valid[ev.clientId] = ev.valid;
      this.compensationFormUpdated = new Date().getTime();
      // this.refundFormUpdated = new Date().getTime();
      // if (this.allValid()) {
      //   this.validationMessage = null;
      // }
      this.customerSelection[ev.clientId] = {
        compensationType: ev.compensationType,
        data: ev.data,
      };
      if (ev.compensationType == "Monetary") {
        this.refundBankDetails = {
          valid: ev.valid,
          data: ev.data,
        };
      }
    },
    postCompensation(request) {
      Api.postCompensation(request)
        .then((response) => {
          this.changeSpinner(false);
          const status = response.data.StatusCode;
          if (status === "0" || status == "0" || status == 0) {
            this.status = this.STATUS_ACCEPTOK;
          } else if (status === "10") {
            const errors = response.data.ErrorsSAP.map((e) => {
              if (e?.Description) return e?.Description;
              return this.$te("errorsSap." + e.Code)
                ? this.$t("errorsSap." + e.Code)
                : null;
            });
            this.bankDetailsSapErrorsDialog = {
              open: true,
              errors,
            };
            this.status = this.STATUS_OK;
          } else if (status == "8") {
            this.status = this.STATUS_CASECLOSED;
          } else {
            this.status = this.STATUS_ACCEPTERR;
          }
          if (this.status !== this.STATUS_OK) {
            const configByStatus = this.loadFeedBackViewConfigByStatusCode(
              this.status
            );
            this.loadFeedBack(configByStatus);
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          console.error(error);
          this.changeSpinner(false);
          this.status = this.STATUS_ACCEPTERR;
          const configByStatus = this.loadFeedBackViewConfigByStatusCode(
            this.status
          );
          this.loadFeedBack(configByStatus);
        });
    },
    send() {
      this.changeSpinner(true);
      const selectionList = this.getSelectionList();
      this.dialog = false;
      let request = {
        CaseId: this.tokenStore.caseId,
        Token: this.tokenStore.token,
        Compensation: selectionList,
      };
      // request.BankDetails = this.copyBankDetails(request);
      if (this.showBankDetails) {
        let data = this.$refs.formMonetary.dataForSend;
        if (data?.IBAN) data.IBAN = data.IBAN.toUpperCase();
        request.Compensation = request.Compensation.map((c) => ({
          ...c,
          ...data,
        }));
        request = { ...request, ...data };
      }

      if (
        !this.showBankDetails &&
        typeof this.$refs.formMonetary != "undefined"
      ) {
        if (this.$refs.formMonetary.dataForSend.IBAN) {
          this.$refs.formMonetary.dataForSend.IBAN =
            this.$refs.formMonetary.dataForSend.IBAN.toUpperCase();
        }
        request = { ...request, ...this.$refs.formMonetary.dataForSend };
      }
      this.postCompensation(request);
    },
  },
};
</script>

<style lang="scss" scoped>
#compensation-layout {
  .monetary-form {
    background-color: #f6f6f6;
    padding: 28px;
    border-radius: 10px;
    @media (max-width: 992px) {
      padding: 12px;
    }
  }
  .btn-bank {
    color: white;
    background-color: #2d69e1;
    margin-top: 10px;
    margin-bottom: 1rem;
    margin-left: auto;
    margin-right: auto;
    height: 48px;
    border-radius: 14px;
    font-size: 16px !important;
    padding: 12px 24px;
  }
}
.dash {
  letter-spacing: -2px;
  color: #074ff3;
}
.user-name {
  font-family: Aeradar-Bold, sans-serif;
  text-align: left;
  color: #2d69e1;
  font-size: 16px;
}
.send-dialog-title {
  font-family: Aeradar-Bold, sans-serif;
  font-size: 22px;
  line-height: 32px;
  margin-bottom: 12px;
  color: #2d69e1;
}

.send-dialog-resume {
  font-family: Lato, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 12px;
  color: #000;
}

.send-dialog-disclaimer {
  font-family: Lato, sans-serif;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 12px;
  color: #565656;
}

.header-text {
  font-family: Lato, sans-serif;
  font-weight: 700;
  font-size: 16px;
  color: #0f2367;
  line-height: 24px;
}
.w-75 {
  width: 75%;
}
.h-100 {
  height: 100%;
}
</style>
