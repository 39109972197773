import "@mdi/font/css/materialdesignicons.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import "vuetify/dist/vuetify.min.css";
import Vuetify from "vuetify/lib";
import {
  ClickOutside,
  Intersect,
  Mutate,
  Ripple,
  Resize,
  Scroll,
  Touch,
} from "vuetify/lib/directives";
import locale from "../configs/locale";
import de from "vuetify/es5/locale/de";
import es from "vuetify/es5/locale/es";
import en from "vuetify/es5/locale/en";
import pt from "vuetify/es5/locale/pt";
import fr from "vuetify/es5/locale/fr";
import it from "vuetify/es5/locale/it";
import Vue from "vue";

Vue.use(Vuetify, {
  directives: {
    ClickOutside,
    Intersect,
    Mutate,
    Ripple,
    Resize,
    Scroll,
    Touch,
  },
});

export default new Vuetify({
  icons: {
    iconfont: "mdi",
  },
  lang: {
    locales: { es, en, pt, fr, de, it },
    current: locale.defaultLocale,
  },
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: "#2d69e1",
        secondary: "#b428cd",
        accent: "#8c9eff",
        error: "#eb0000",
      },
    },
  },
});
