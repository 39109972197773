<!-- Para recoger los valores default que vienen de la PAI REST en los input VTextFieldWithValidation y VSelectWithValidation incluir -> :default="c.value" 
  si no se incluye el formulario carga en blanco -->
<template slot="content">
  <v-container fluid>
    <v-form v-on:submit.prevent>
      <v-row>
        <v-col :xs="12" :sm="4">
          <ValidationProvider rules="required">
            <v-autocomplete
              background-color="white"
              v-model.trim="country"
              :items="filteredCountries"
              :label="$t('bankDetails.countryLabel')"
              @change="loadFormByCountry($event)"
              filled
              class="pa-2"
            ></v-autocomplete>
          </ValidationProvider>
        </v-col>
      </v-row>

      <v-row v-if="config && country" no-gutters>
        <v-col
          v-for="(c, index) in config"
          cols="12"
          :sm="6"
          :md="!c.combined ? 4 : 6"
          :class="getClassesForCombined(c, index)"
          :key="c.field"
        >
          <div class="pa-2">
            <VTextFieldWithValidation
              v-if="c.type == 'text'"
              :rules="c.rules"
              :disabled="hasAssociation(c)"
              :counter="c.rules && c.rules.max ? c.rules.max : null"
              :label="c.label"
              :hide-details="!c.label"
              :placeholder="c.placeholder"
              v-model="dataForSend[c.field]"
            />
            <VSelectWithValidation
              v-if="c.type == 'select'"
              :rules="c.rules"
              :items="c.items"
              :placeholder="c.placeholder"
              v-model="dataForSend[c.field]"
              :label="c.label"
              @change="onChangeSelect(c, $event)"
            />
          </div>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import Api from "@/api/api";
import { ValidationProvider } from "vee-validate";
import VTextFieldWithValidation from "@/components/dynamic/VTextFieldWithValidation.vue";
import VSelectWithValidation from "@/components/dynamic/VSelectWithValidation.vue";
import { getRulesByField, getRulesByFieldBR } from "@/configs/validations";
import { mapStores, mapActions } from "pinia";
import { useCountriesStore } from "../../stores/countriesStore";
import { useUiStore } from "@/stores/uiStore";
import { useLanguageStore } from "@/stores/languageStore";

export default {
  name: "DynamicFormComponent",
  components: {
    // FieldContainer,
    VTextFieldWithValidation,
    VSelectWithValidation,
    ValidationProvider,
  },
  data: () => ({
    caseId: null,
    config: null,
    country: null,
    dataForSend: {},
    language: null,
    locale: null,
    token: null,
  }),
  created() {
    this.loadCountries();
  },
  computed: {
    ...mapStores(useCountriesStore, useLanguageStore),
    filteredCountries() {
      return this.countriesStore.countryOptions(true);
    },
  },
  methods: {
    ...mapActions(useUiStore, ["changeSpinner"]),
    getClassesForCombined(actual, index) {
      let classes = {};
      const previous = this.config[index - 1];
      const isXs = this.$vuetify.breakpoint.xs;
      if (actual.combined) {
        classes["combined grey lighten-2"] = actual.combined;
        if (!previous?.pyLabel) {
          classes[isXs ? "rounded-t" : "rounded-l"] = true;
        }
        if (previous?.pyLabel === actual?.pyLabel) {
          classes[isXs ? "rounded-b" : "rounded-r"] = true;
        }
      }
      return classes;
    },

    setDefaultCountry() {
      if (this?.$attrs?.values?.["CountryCode"]) {
        this.country = this.filteredCountries.find(
          (opt) => opt?.value?.isocode === this?.$attrs?.values["CountryCode"]
        )?.value;
        this.loadFormByCountry({
          isocode: this?.$attrs?.values["CountryCode"],
        });
      }
    },
    async resetForm() {
      this.dataForSend = { CountryCode: this.country.isocode };
    },
    onChangeSelect({ field, associated }, value) {
      if (!associated || !field) return;
      let associatedField = this.config.findIndex((x) => x.field == associated);
      if (!Number.isInteger(associatedField)) return;
      const customValidations = {
        AccountHolderDocType: () => {
          this.config[associatedField].rules = {
            ...this.config[associatedField].rules,
            validateDoc: [value],
          };
        },
      };
      if (customValidations[field]) customValidations[field]();
    },
    hasAssociation(reference) {
      const fieldAssociated = this.config?.find(
        (f) => f.associated === reference.field
      );
      if (!fieldAssociated) return;
      return !this.dataForSend[fieldAssociated?.field];
    },
    async loadCountries() {
      try {
        this.changeSpinner(false);
        this.setDefaultCountry();
      } catch (error) {
        console.error(error);
        this.changeSpinner(false);
      }
    },
    mapFieldsForm(form) {
      return form?.map((d) => {
        let mapped = {
          field: d?.Field,
          countryCode: d?.CountryCode,
          maxLength: d?.MaxSize,
          minLength: d?.MinSize,
          label: d?.FieldName,
          type: d?.TypeDescription,
          combined: d?.CombinedField,
          required: d?.Required?.toLowerCase() === "true",
          placeholder: d?.ToolTipText,
          message: d?.pyMessage,
          pyLabel: d?.pyLabel,
          rules: {},
        };
        if (mapped.type === "select") {
          mapped["items"] = d?.pyItemGroup?.map((i) => ({
            name: i.Name,
            value: i.Value,
          }));
          // Create association for select and text input
          if (mapped.field === "AccountHolderDocType") {
            mapped.associated = "AccountHolderDocNumber";
          }
        }
        if (this?.$attrs?.values?.[mapped.field]) {
          mapped.value = this.$attrs.values[mapped.field];
        }
        /**
         * Comprobar si la ruta actual es "compensation-component" y el país es "BR" (Brasil).
         * Si se cumplen ambas condiciones, aplica las validaciones sobre dos campos Clave de banco y Número de cuenta.
         * En caso contrario, aplica las validaciones por defecto.
         */
        if (
          this.$router?.currentRoute?.name === "compensation-component" &&
          this.country.isocode === "BR"
        ) {
          mapped.rules = {
            ...getRulesByFieldBR(mapped),
          };
          return mapped;
        }
        mapped.rules = { ...getRulesByField(mapped) };
        return mapped;
      });
    },
    async loadFormByCountry(country) {
      if (!country?.isocode && !this.country?.isocode) return;
      try {
        this.changeSpinner(true);
        this.config = null;
        this.dataForSend = {};
        // this.resetForm();
        const r = await Api.dynamicForm(
          country.isocode || this.country.isocode,
          this.languageStore.language.toUpperCase()
        );
        const data = r.data;
        const json = data?.JsonResponse;
        this.config = this.mapFieldsForm(json);
        this.changeSpinner(false);
      } catch (error) {
        console.error(error);
        this.changeSpinner(false);
      }
    },
  },
  watch: {
    country(data) {
      this.dataForSend["CountryCode"] = data?.isocode;
    },
  },
};
</script>

<style scoped>
.w-50 {
  width: 50%;
}
.w-100 {
  width: 100%;
}
.form-container {
  background-color: #f6f6f6;
}
</style>
