var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"modalContent",staticClass:"dialog-wrapper",class:{ 'small-modal': _vm.isSmallModal },on:{"scroll":_vm.handleScroll}},[(_vm.showAEBond)?[_c('h2',{domProps:{"innerHTML":_vm._s(_vm.$t('compensation.tcAEBond.header'))}}),_c('ul',{staticClass:"section-content"},[_c('li',{domProps:{"innerHTML":_vm._s(_vm.$t('compensation.tcAEBond.line1'))}}),_c('li',{domProps:{"innerHTML":_vm._s(_vm.$t('compensation.tcAEBond.line2'))}}),_c('li',{domProps:{"innerHTML":_vm._s(_vm.$t('compensation.tcAEBond.line3'))}}),_c('li',{domProps:{"innerHTML":_vm._s(_vm.$t('compensation.tcAEBond.line4'))}}),_c('li',{domProps:{"innerHTML":_vm._s(_vm.$t('compensation.tcAEBond.line5'))}}),_c('li',{domProps:{"innerHTML":_vm._s(_vm.$t('compensation.tcAEBond.line6'))}}),_c('li',{domProps:{"innerHTML":_vm._s(_vm.$t('compensation.tcAEBond.line7'))}}),_c('li',{domProps:{"innerHTML":_vm._s(_vm.$t('compensation.tcAEBond.line8'))}})]),_c('br'),_c('p',{staticClass:"section-content",domProps:{"innerHTML":_vm._s(_vm.$t('compensation.tcAEBond.line9'))}})]:_vm._e(),(_vm.showMonetary)?[_c('h2',{staticClass:"section-header"},[_vm._v(" "+_vm._s(_vm.$t("compensation.tcMonetaryCompensation.header"))+" ")]),_c('div',{staticClass:"section-content"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('compensation.tcMonetaryCompensation.line1'))}}),_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('compensation.tcMonetaryCompensation.line2'))}})]),_c('br')]:_vm._e(),(_vm.showMiles)?[_c('h2',{staticClass:"section-header"},[_vm._v(" "+_vm._s(_vm.$t("compensation.tcMilesCompensation.header"))+" ")]),_c('div',{staticClass:"section-content"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('compensation.tcMilesCompensation.line1'))}}),_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('compensation.tcMilesCompensation.line2'))}})]),_c('br')]:_vm._e(),(_vm.showUnderstoodButton)?_c('button',{class:[
      'cta',
      {
        'btn-bank': _vm.understoodButtonEnabled,
        'btn-bank-disabled': !_vm.understoodButtonEnabled,
      },
    ],style:({
      marginTop: _vm.isSmallModal && _vm.understoodButtonEnabled ? '30px' : '20px',
    }),attrs:{"disabled":!_vm.understoodButtonEnabled},on:{"click":_vm.handleUnderstood}},[_vm._v(" "+_vm._s(_vm.$t("generic_ok"))+" ")]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }