<template>
  <v-container class="searcher" :class="{ 'center-error': isExpiredToken }">
    <section v-if="!isExpiredToken">
      <SearchReservationComponent />
    </section>
    <section v-else class="expired-token">
      <ErrorView
        :title="$t('searchReservationComponent.tokenExpired')"
        :subtitle="$t('searchReservationComponent.tokenExpired')"
        :hideLink="true"
      />
    </section>
  </v-container>
</template>
<script>
import SearchReservationComponent from "../components/SearchReservation.vue";
import ErrorView from "../components/common/ErrorView";
import { mapActions, mapStores } from "pinia";
import { useUiStore } from "@/stores/uiStore";
import { useTokenStore } from "@/stores/tokenStore";

export default {
  name: "SearchReservationPage",
  components: { SearchReservationComponent, ErrorView },
  created() {
    this.changeSpinner(false);
  },
  mounted() {
    if (this.isExpiredToken) {
      this.uiStore.setError({
        message: this.$t("searchReservationComponent.tokenExpired"),
      });
    }
  },
  computed: {
    ...mapStores(useTokenStore, useUiStore),
    isExpiredToken() {
      // return false;
      return this.tokenStore?.isExpiredToken;
    },
  },
  methods: {
    ...mapActions(useUiStore, ["changeSpinner"]),
  },
};
</script>

<style lang="scss">
.searcher {
  margin: 0 auto;
  max-width: 1280px;
  height: 70%;
}
.center-error {
  display: flex;
  justify-content: center;
  align-items: center;
}
.expired-token {
  width: fit-content;
  box-shadow: 0px 5px 10px rgba(204, 204, 204, 0.3);
  padding: 50px;
  border-radius: 20px;
}
</style>
