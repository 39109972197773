<template>
  <div
    class="dialog-wrapper"
    :class="{ 'small-modal': isSmallModal }"
    ref="modalContent"
    @scroll="handleScroll"
  >
    <template v-if="showAEBond">
      <h2 v-html="$t('compensation.tcAEBond.header')"></h2>
      <ul class="section-content">
        <li v-html="$t('compensation.tcAEBond.line1')"></li>
        <li v-html="$t('compensation.tcAEBond.line2')"></li>
        <li v-html="$t('compensation.tcAEBond.line3')"></li>
        <li v-html="$t('compensation.tcAEBond.line4')"></li>
        <li v-html="$t('compensation.tcAEBond.line5')"></li>
        <li v-html="$t('compensation.tcAEBond.line6')"></li>
        <li v-html="$t('compensation.tcAEBond.line7')"></li>
        <li v-html="$t('compensation.tcAEBond.line8')"></li>
      </ul>
      <br />
      <p class="section-content" v-html="$t('compensation.tcAEBond.line9')"></p>
    </template>

    <template v-if="showMonetary">
      <h2 class="section-header">
        {{ $t("compensation.tcMonetaryCompensation.header") }}
      </h2>
      <div class="section-content">
        <p v-html="$t('compensation.tcMonetaryCompensation.line1')"></p>
        <p v-html="$t('compensation.tcMonetaryCompensation.line2')"></p>
      </div>
      <br />
    </template>

    <template v-if="showMiles">
      <h2 class="section-header">
        {{ $t("compensation.tcMilesCompensation.header") }}
      </h2>
      <div class="section-content">
        <p v-html="$t('compensation.tcMilesCompensation.line1')"></p>
        <p v-html="$t('compensation.tcMilesCompensation.line2')"></p>
      </div>
      <br />
    </template>

    <!-- Botón "Entendido" -->
    <button
      v-if="showUnderstoodButton"
      :class="[
        'cta',
        {
          'btn-bank': understoodButtonEnabled,
          'btn-bank-disabled': !understoodButtonEnabled,
        },
      ]"
      :disabled="!understoodButtonEnabled"
      @click="handleUnderstood"
      :style="{
        marginTop: isSmallModal && understoodButtonEnabled ? '30px' : '20px',
      }"
    >
      {{ $t("generic_ok") }}
    </button>
  </div>
</template>

<script>
export default {
  name: "CombinedModal",
  props: {
    dialog: {
      type: Function,
      required: true,
    },
    showAEBond: {
      type: Boolean,
      default: false,
    },
    showMiles: {
      type: Boolean,
      default: false,
    },
    showMonetary: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showUnderstoodButton: false,
      understoodButtonEnabled: false,
      isSmallModal: false,
      modalContent: null,
    };
  },
  methods: {
    handleScroll() {
      if (!this.modalContent) return;

      const scrollTop = this.modalContent.scrollTop;
      const scrollHeight = this.modalContent.scrollHeight;
      const clientHeight = this.modalContent.clientHeight;
      const scrollThreshold = 10;

      const isAtBottom =
        scrollTop + clientHeight >= scrollHeight - scrollThreshold;
      this.understoodButtonEnabled = isAtBottom || scrollHeight <= clientHeight;
    },
    handleUnderstood() {
      this.$emit("understood");
      this.resetScrollPosition();
    },
    resetScrollPosition() {
      if (this.modalContent) {
        this.modalContent.scrollTop = 0;
        this.handleScroll();
      }
    },
    resetState() {
      this.isSmallModal =
        (this.showMiles && !this.showAEBond && !this.showMonetary) ||
        (this.showMonetary && !this.showAEBond && !this.showMiles);

      this.showUnderstoodButton = true;
      this.understoodButtonEnabled = false;

      this.$nextTick(() => {
        this.checkIfButtonShouldBeEnabled();
      });
    },
    checkIfButtonShouldBeEnabled() {
      if (this.isSmallModal) {
        const scrollHeight = this.modalContent.scrollHeight;
        const clientHeight = this.modalContent.clientHeight;
        this.understoodButtonEnabled = scrollHeight <= clientHeight;
      } else {
        this.understoodButtonEnabled = true;
      }
    },
  },
  watch: {
    showAEBond() {
      this.resetState();
    },
    showMiles() {
      this.resetState();
    },
    showMonetary() {
      this.resetState();
    },
  },
  mounted() {
    this.modalContent = this.$refs.modalContent;
    this.resetState();
  },
};
</script>

<style scoped>
/* Estilos CSS específicos del componente modal */
h2 {
  font-family: Aeradar-Bold, sans-serif;
  text-align: center;
  color: #2d69e1;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 14px;
}

li,
p {
  text-align: left;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 0px;
}

.cta {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  align-self: unset;
  font-family: Lato;
  text-decoration: none;
  padding: 12px 64px;
  margin-top: 20px;
  width: min-content;
  border-radius: 14px;
  background-color: #2d69e1;
  font-family: Lato;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

/* Estilos para el botón "Entendido" cuando está habilitado */
.cta.btn-bank {
  background-color: #2d69e1; /* Color de fondo habilitado */
  color: #fff; /* Color de texto habilitado */
  cursor: pointer; /* Cursor habilitado */
}

/* Estilos para el botón "Entendido" cuando está deshabilitado */
.cta.btn-bank-disabled {
  background-color: #ccc; /* Color de fondo deshabilitado */
  color: #666; /* Color de texto deshabilitado */
  cursor: not-allowed; /* Cursor deshabilitado */
}

/* Estilos CSS específicos del componente modal */
.dialog-wrapper {
  padding: 20px;
  text-align: left;
  display: grid;
  overflow-y: auto; /* Permitir scroll vertical si es necesario */
  max-height: 80vh; /* Altura máxima predeterminada del modal */
  transition: max-height 0.3s ease; /* Transición suave de altura */
}

/* Nueva clase para añadir margin-top entre secciones */
.section-header {
  margin-top: 20px; /* Ajusta el valor según sea necesario */
}
</style>
