<template>
  <div>
    <CompensationSelection v-if="!showComponent" @next-form="formSubmited">
    </CompensationSelection>

    <DynamicFormCompensation
      v-if="showComponent"
      :proposalOption="selectedOptions"
      :passengerList="passengerData"
      :milesPassengerList="passengerDataMiles"
    ></DynamicFormCompensation>
  </div>
</template>

<script>
import DynamicFormCompensation from "./DynamicFormCompensation.vue";
import CompensationSelection from "./CompensationSelection";

export default {
  name: "compensationGlobalComponente",
  components: {
    DynamicFormCompensation,
    CompensationSelection,
  },
  data: () => ({
    selectedOptions: [],
    showComponent: false,
    passengerData: null,
    passengerDataMiles: null,
  }),
  mounted() {
    this.$on("next-form", this.formSubmited);
  },
  methods: {
    formSubmited(proposal) {
      this.selectedOptions = proposal[0];
      this.passengerData = proposal[1];
      this.passengerDataMiles = proposal[2];
      this.showComponent = true;
    },
  },
};
</script>

<style></style>
