import * as countries from "./countries";

const defaultConfig = {
  country: {
    label: "bankDetails.countryLabel",
    validator: "[v => !!v || 'Item is required']",
  },
  accountHolderName: {
    label: "bankDetails.accountHolderName",
    alphanumeric: true,
  },
  accountHolderDocType: {
    label: "compensation.tipoDocumento",
  },
  accountHolderDocNumber: {
    label: "bankDetails.accountHolderDocNumber",
  },
  accountNumber: {
    label: "bankDetails.accountNumber",
    visible: true,
  },
  bankCode: {
    visible: false,
    required: false,
    label: "bankDetails.bankCode",
  },
  CBU1: {
    visible: false,
    required: false,
    label: "bankDetails.CBU1",
  },
  CBU2: {
    visible: false,
    required: false,
    label: "bankDetails.CBU2",
  },
  CTN: {
    visible: false,
    required: false,
    label: "bankDetails.CTN",
  },
  CLABE: {
    visible: false,
    required: false,
    label: "bankDetails.CLABE",
  },
  CCI1: {
    visible: false,
    required: false,
    label: "bankDetails.CCI1",
  },
  CCI2: {
    visible: false,
    required: false,
    label: "bankDetails.CCI2",
  },
  ABA: {
    visible: false,
    required: false,
    label: "bankDetails.ABA",
  },
  controlDigit: {
    visible: false,
    required: false,
    label: "bankDetails.controlDigit",
  },
  ClaveBanco: {
    visible: false,
    required: false,
    label: "bankDetails.ClaveBanco",
  },
  IBAN: {
    visible: true,
    label: "bankDetails.IBAN",
  },
  swift: {
    label: "bankDetails.swift",
    visible: true,
  },
  swiftCoresp: {
    label: "bankDetails.SWIFT2",
    visible: false,
  },
  swift2: {
    visible: false,
    required: false,
    label: "bankDetails.swift2",
  },
  bankName: {
    visible: true,
    label: "bankDetails.bankName",
    required: true,
  },
  bankAddress: {
    visible: true,
    label: "bankDetails.bankAddress",
    required: false,
  },
  refBank: {
    label: "bankDetails.refBank",
    visible: false,
    required: false,
  },
  accountTypeBankDetails: {
    label: "bankDetails.accountTypeBankDetails",
    visible: false,
    required: false,
  },
};

const ibanCountryConfig = {
  DE: { ibanLength: countries.DE.ibanLength },
  AD: { ibanLength: countries.AD.ibanLength },
  AT: { ibanLength: countries.AT.ibanLength },
  BE: { ibanLength: countries.BE.ibanLength },
  BG: { ibanLength: countries.BG.ibanLength },
  CY: { ibanLength: countries.CY.ibanLength },
  CI: { ibanLength: countries.CI.ibanLength },
  HR: { ibanLength: countries.HR.ibanLength },
  DK: { ibanLength: countries.DK.ibanLength },
  AE: { ibanLength: countries.AE.ibanLength },
  SI: { ibanLength: countries.SI.ibanLength },
  ES: { ibanLength: countries.ES.ibanLength },
  EE: { ibanLength: countries.EE.ibanLength },
  FI: { ibanLength: countries.FI.ibanLength },
  AL: { ibanLength: countries.AL.ibanLength },
  AO: { ibanLength: countries.AO.ibanLength },
  BA: { ibanLength: countries.BA.ibanLength },
  BH: { ibanLength: countries.BH.ibanLength },
  CM: { ibanLength: countries.CM.ibanLength },
  CV: { ibanLength: countries.CV.ibanLength },
  EG: { ibanLength: countries.EG.ibanLength },
  FO: { ibanLength: countries.FO.ibanLength },
  GE: { ibanLength: countries.GE.ibanLength },
  GL: { ibanLength: countries.GL.ibanLength },
  GQ: { ibanLength: countries.GQ.ibanLength },
  JO: { ibanLength: countries.JO.ibanLength },
  KW: { ibanLength: countries.KW.ibanLength },
  KZ: { ibanLength: countries.KZ.ibanLength },
  LB: { ibanLength: countries.LB.ibanLength },
  ME: { ibanLength: countries.ME.ibanLength },
  MK: { ibanLength: countries.MK.ibanLength },
  ML: { ibanLength: countries.ML.ibanLength },
  MR: { ibanLength: countries.MR.ibanLength },
  MU: { ibanLength: countries.MU.ibanLength },
  QA: { ibanLength: countries.QA.ibanLength },
  RS: { ibanLength: countries.RS.ibanLength },
  SA: { ibanLength: countries.SA.ibanLength },
  TN: { ibanLength: countries.TN.ibanLength },
  TR: { ibanLength: countries.TR.ibanLength },
  UA: { ibanLength: countries.UA.ibanLength },
  FR: {
    ibanLength: 27,
  },
  GI: {
    ibanLength: 23,
  },
  GB: {
    ibanLength: 22,
  },
  GR: {
    ibanLength: 27,
  },
  HU: {
    ibanLength: 28,
  },
  IE: {
    ibanLength: 22,
  },
  IL: { ibanLength: countries.IL.ibanLength },
  IT: {
    ibanLength: 27,
  },
  LI: {
    ibanLength: 21,
  },
  LV: {
    ibanLength: 21,
  },
  LT: {
    ibanLength: 20,
  },
  LU: {
    ibanLength: 20,
  },
  MA: { ibanLength: countries.MA.ibanLength },
  MT: {
    ibanLength: 31,
  },
  MC: {
    ibanLength: 27,
  },
  NO: {
    ibanLength: 15,
  },
  NL: {
    ibanLength: 18,
  },
  PL: {
    ibanLength: 28,
  },
  PT: {
    ibanLength: 25,
  },
  CZ: {
    ibanLength: 24,
  },
  DO: { ibanLength: countries.DO.ibanLength },
  SK: {
    ibanLength: 24,
  },
  RO: {
    ibanLength: 24,
  },
  SM: {
    ibanLength: 27,
  },
  SN: { ibanLength: countries.SN.ibanLength },
  SE: {
    ibanLength: 24,
  },
  CH: {
    ibanLength: 21,
  },
};

const countryConfig = {
  ES: { ...countries.ES.countryConfig },
  EUR: { ...countries.EUR.countryConfig },
  IN: { ...countries.IN.countryConfig },
  AR: { ...countries.AR.countryConfig },
  // AZ: { ...countries.AZ.countryConfig },
  AU: { ...countries.AU.countryConfig },
  CX: { ...countries.CX.countryConfig },
  CO: { ...countries.CO.countryConfig },
  CI: { ...countries.CI.countryConfig },
  AZ: countries.AZ.countryConfig,
  CR: countries.CR.countryConfig,
  GT: countries.GT.countryConfig,
  HN: countries.HN.countryConfig,
  BO: countries.BO.countryConfig,
  BR: countries.BR.countryConfig,
  CA: countries.CA.countryConfig,
  CL: countries.CL.countryConfig,
  CN: countries.CN.countryConfig,
  CU: countries.CU.countryConfig,
  IL: countries.IL.countryConfig,
  MA: countries.MA.countryConfig,
  MX: countries.MX.countryConfig,
  PA: countries.PA.countryConfig,
  PY: countries.PY.countryConfig,
  AE: countries.AE.countryConfig,
  EC: countries.EC.countryConfig,
  PE: countries.PE.countryConfig,
  PR: countries.PR.countryConfig,
  DO: countries.DO.countryConfig,
  RU: countries.RU.countryConfig,
  SN: countries.SN.countryConfig,
  UY: countries.UY.countryConfig,
  US: countries.US.countryConfig,
  VE: countries.VE.countryConfig,
  OTH: countries.OTH.countryConfig,
};

function copyProperties(source, target) {
  for (const p in source) {
    target[p] = source[p];
  }
  return target;
}

function mergeWithDefault(defaultConfig, config) {
  if (!config || !defaultConfig) return;
  let newConfig = JSON.parse(JSON.stringify(defaultConfig)); // <-- deep copy. preserva default en su estado original
  for (const k in newConfig) {
    if (!Object.prototype.hasOwnProperty.call(config, k)) {
      // newConfig[k] = defaultConfig[k];
      newConfig[k] = copyProperties(defaultConfig[k], newConfig[k]);
    } else {
      newConfig[k] = config[k];
    }
  }
  return newConfig;
}

export default {
  extraConfig: ibanCountryConfig,
  countryConfiguration: countryConfig,
  defaultConfig: defaultConfig,
  merge: mergeWithDefault,
};
