<template src="./MonetaryTemplate.html"></template>
<style scoped>
.input-div {
  padding-right: 4px;
}
</style>

<script>
import Vue from "vue";
import Vuelidate from "vuelidate";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import Layout from "@/components/Layout";

import Countries from "@/configs/formConfiguration";

Vue.use(Vuelidate);

/*
 *  colocar elementos del modulo en el orden:  data,  computed, watch, created  ,  methods   .  si existe mounted() colocar luego de created.
 */
export default {
  name: "MonetaryFormComponent",
  components: { Layout, Vuelidate },
  props: [
    "validate",
    "item",
    "formModel",
    "countries",
    "selection",
    "customerData",
    "initData",
    "initValid",
    "showErrors",
    "title",
    "bankDetails",
  ],
  data() {
    return {
      monetaryValid: {},
      validatorLoaded: false,
      valid: false,
      country: null,
      name: "world",
      ibanchanged: false,
      formData: {
        accountNumber: null,
        IBAN: null,
        bankCode: null,
        accountHolderName: null,
        accountHolderDocNumber: null,
        accountHolderDocType: null,
        swift: null,
        swiftCoresp: null,
        bankName: null,
        CBU1: null,
        CBU2: null,
        CTN: null,
        CLABE: null,
        CCI1: null,
        CCI2: null,
        controlDigit: null,
        ABA: null,
        ClaveBanco: null,
        bankAddress: null,
        refBank: null,
        accountTypeBankDetails: null,
      },
    };
  },
  computed: {
    accountTypes() {
      return [
        { text: this.$i18n.t("text.currentAccount"), value: "currentAccount" },
        { text: this.$i18n.t("text.savingAccount"), value: "savingAccount" },
      ];
    },
    countryPicked() {
      return (
        this.country != null &&
        this.country.code != null &&
        this.country.code.length > 0
      );
    },
    documentTypes() {
      return [
        { text: this.$i18n.t("text.dniapr"), value: "DNI" },
        { text: this.$i18n.t("text.nif"), value: "NIF" },
        { text: this.$i18n.t("text.nie"), value: "NIE" },
        { text: this.$i18n.t("compensation.passport"), value: "PASAPORTE" },
        { text: this.$i18n.t("compensation.others"), value: "OTROS" },
      ];
    },
    rules() {
      const c = this.countryConfig;
      return c;
    },
    countryConfig() {
      try {
        if (!this.country) return;
        return Countries.merge(
          Countries.defaultConfig,
          Countries.countryConfiguration[this.country.code]
        );
      } catch (e) {
        return {};
      }
    },
    countryOptions() {
      return this.countries.map((c) => {
        return {
          text: c.CountryName,
          value: {
            name: c.CountryName,
            code: c.RegionCode,
            isocode: c.CountryCode,
          },
        };
      });
    },
  },
  watch: {
    validate() {
      if (
        Object.prototype.hasOwnProperty.call(this, "$v") &&
        Object.prototype.hasOwnProperty.call(this.$v, "formData")
      ) {
        this.$v.formData.$touch();
      }
    },
    showErrors(newValue) {
      if (newValue > 0) {
        this.$v.$touch();
      }
    },
    $v: {
      deep: true,
      handler(newValue) {
        //  if(oldValue.$invalid != newValue.$invalid){
        let valid = !newValue.$invalid;

        if (
          !this.country ||
          this.country.code == null ||
          this.country.name == null
        ) {
          valid = false;
        }

        this.$emit("validationStateChanged", {
          clientId: this.item != null ? this.item.ClientID : "",
          compensationType: "Monetary",
          valid: valid,
        });

        // }
      },
    },
    country(newValue) {
      let valid = !this.$v.$invalid;
      if (
        !this.country ||
        this.country.name == null ||
        this.country.data == null
      ) {
        valid = false;
      }
      this.formData.country = newValue;
      const ev = {
        valid: valid,
        dirty: this.$v.$dirty,
        country: newValue,
        clientId: this.item != null ? this.item.ClientID : "",
        compensationType: "Monetary",
        data: this.formData,
      };
      // this.$v.$touch();
      this.$v.$reset();
      this.$emit("formReady", ev);
      // this.$emit('formReset');
    },
    formData: {
      deep: true,
      handler(newValue) {
        if (!this.validatorLoaded) {
          this.validatorLoaded = true;
        }
        const data = newValue;
        data.country = this.country;
        let valid = !this.$v.$invalid;
        if (
          !this.country ||
          this.country.name == null ||
          this.country.code == null
        ) {
          valid = false;
        }
        this.$emit("formReady", {
          valid: valid,
          country: this.country,
          clientId: this.item != null ? this.item.ClientID : "",
          compensationType: "Monetary",
          data: data,
        });
      },
    },
  },
  created() {
    if (
      this.initData != null &&
      Object.prototype.hasOwnProperty.call(this.initData, "compensationType") &&
      this.initData.compensationType == "Monetary"
    ) {
      this.formData = this.initData.data;
      this.country = this.formData.country;
    }

    // this.country.code = this.bankDetails.CountryCode;
    // this.country.name = this.bankDetails.CountryName;
    /* this.country = {
      code: 'es',
      name: this.bankDetails.CountryName
    } */
  },
  mounted() {
    if (this.bankDetails != null) {
      const details = {
        // accountNumber: this.bankDetails.AccountNumber
        accountNumber: this.bankDetails.AccountNumber,
        IBAN: this.bankDetails.IBAN,
        bankCode: this.bankDetails.BankCode,
        accountHolderName: this.bankDetails.AccountHolderName,
        accountHolderDocNumber: this.bankDetails.AccountHolderDocNumber,
        accountHolderDocType: this.bankDetails.AccountHolderDocType,
        swift: this.bankDetails.SWIFT,
        swiftCoresp: this.bankDetails.swiftCoresp,
        bankName: this.bankDetails.BankName,
        Cbu1: this.bankDetails.CBU1,
        Cbu2: this.bankDetails.CBU2,
        Ctn: this.bankDetails.CTN,
        Clabe: this.bankDetails.CLABE,
        Cci1: this.bankDetails.CCI1,
        Cci2: this.bankDetails.CCI2,
        ControlDigit: this.bankDetails.controlDigit,
        Aba: this.bankDetails.ABA,
        claveBanco: this.bankDetails.ClaveBanco,
        bankAddress: this.bankDetails.FullAddress,
        refBank: this.bankDetails.RefBank,
        accountTypeBankDetails: this.bankDetails.AccountTypeBankDetails,
      };

      this.country = {
        // TODO: solicitar caso con CountryCode preestablecido para validar que se carga.
        isocode: this.bankDetails.CountryCode,
        name: this.bankDetails.CountryName,
        code: this.getRegionForCountryCode(this.bankDetails.CountryCode),
      };

      this.formData = details;
    }
    let valid = false;
    if (
      !this.country ||
      this.country.code == null ||
      this.country.name == null
    ) {
      this.$emit("formReady", {
        valid: false,
        country: null,
        clientId: this.item != null ? this.item.ClientID : "",
        compensationType: "Monetary",
        data: {},
      });
    }
    if (this.country == null || this.country == undefined) {
      valid = false;
    }
    this.$emit("validationStateChanged", {
      clientId: this.item != null ? this.item.ClientID : "",
      compensationType: "Monetary",
      valid: valid,
    });
  },
  // cargar validations en base a countryConfig. todos los campos obligatorios. agregar reglas de IBAN, swift, etc..

  validations() {
    const config = this.countryConfig;
    if (config == null || config == undefined) return {};
    const keys = Object.keys(config);
    const countryCode = this.country ? this.country.code : "ES";
    const isoCode = this.country ? this.country.isocode : "es";
    const rules = keys.reduce(
      (acc, cur) => {
        const item = config[cur];
        if (
          Object.prototype.hasOwnProperty.call(item, "visible") &&
          item.visible &&
          Object.prototype.hasOwnProperty.call(item, "required") &&
          item.required == true
        ) {
          acc[cur] = { required };
        }
        // Validación del IBAN CRM-5422
        if (this.hasIbanLength() && cur == "IBAN") {
          acc[cur] = {
            validateIBANLength(val) {
              if (!val || val.length == 0) {
                return true;
              }
              // si es el que nos viene
              if (!this.ibanchanged && val.length > 0) {
                if (!this.isIbanChanged(val)) {
                  return true;
                }
              }
              if (this.hasIbanLength()) {
                return val.length == this.getIbanLength();
              }
              return true;
            },
            beginsWithCountryCode(val) {
              if (!val || val.length == 0) {
                return true;
              }
              if (!this.ibanchanged && val.length > 2) {
                if (!this.isIbanChanged(val)) {
                  return true;
                }
              }
              if (val != null && val.length > 2) {
                if (
                  val.substring(0, 2).toUpperCase() == isoCode.toUpperCase()
                ) {
                  return true;
                }
              }
              return false;
            },
          };
        }
        // regla de IBAN required en paises europeos separada de validacion del formato
        if (countryCode == "EUR" && cur == "IBAN") {
          if (!acc[cur]) {
            acc[cur] = {};
          }
          acc[cur].required = required;
        }
        if (countryCode == "EUR" && cur == "swift") {
          acc[cur] = {
            required,
            minLength: minLength(8),
            maxLength: maxLength(11),
          };
        }
        return acc;
      },
      {
        // campos required por defecto
        accountHolderName: { required },
        accountHolderDocNumber: { required },
      }
    );
    // validaciones por defecto para others
    if (countryCode == "OTH") {
      const validateOTH = () => {
        return (
          required(this.formData.accountNumber) || required(this.formData.IBAN)
        );
      };
      if (!rules.IBAN) {
        rules.IBAN = {};
      }
      if (!rules.accountNumber) {
        rules.accountNumber = {};
      }
      rules.IBAN.validateOTH = validateOTH;
      rules.accountNumber.validateOTH = validateOTH;
    }
    if (countryCode == "ES") {
      rules.accountHolderDocType = { required };
      rules.accountHolderDocNumber = {
        required,
        docNumberES(val) {
          const docType = this.formData.accountHolderDocType;
          if (docType == null) return false;
          return this.validateDocNumber(docType, val);
        },
      };
    }
    if (config.IBAN && config.IBAN.required) {
      rules.IBAN.required = required;
    }
    return {
      formData: rules,
    };
  },
  methods: {
    isIbanChanged(newIban) {
      if (
        this.bankDetails &&
        this.bankDetails.IBAN &&
        this.bankDetails.IBAN !== ""
      ) {
        this.ibanchanged = this.bankDetails.IBAN !== newIban;
        return this.bankDetails.IBAN !== newIban;
      } else {
        return true;
      }
    },

    getIbanLength() {
      const isoCode = this.country?.isocode;
      return Countries.extraConfig[isoCode].ibanLength;
    },
    hasIbanLength() {
      return (
        Object.prototype.hasOwnProperty.call(
          Countries.extraConfig,
          this.country?.isocode
        ) &&
        Object.prototype.hasOwnProperty.call(
          Countries.extraConfig[this.country?.isocode],
          "ibanLength"
        )
      );
    },
    getRegionForCountryCode(code) {
      for (const i in this.countries) {
        const item = this.countries[i];
        if (item.CountryCode == code) {
          return item.RegionCode;
        }
      }
    },
    onSelectAccountType(field) {
      this.formData.accountTypeBankDetails = field;
    },
    touch(field) {
      if (
        Object.prototype.hasOwnProperty.call(this, "$v") &&
        Object.prototype.hasOwnProperty.call(this.$v.formData, field)
      ) {
        try {
          this.$v.formData[field].$touch();
          // eslint-disable-next-line no-empty
        } catch (err) {}
      }
    },
    fieldError(fieldName) {
      if (this.formData == null) {
        return;
      }
      if (!Object.prototype.hasOwnProperty.call(this.$v, "formData")) {
        return;
      }
      const fieldValue = this.$v.formData[fieldName];
      if (fieldValue != null && fieldValue.$dirty) {
        if (
          Object.prototype.hasOwnProperty.call(fieldValue, "required") &&
          !fieldValue.required
        ) {
          return this.$t("bankDetails.requiredField", {
            fieldName: this.labelFor(fieldName),
          });
        }
        if (
          fieldName.trim() == "accountHolderDocNumber" &&
          Object.prototype.hasOwnProperty.call(fieldValue, "docNumberES") &&
          !fieldValue.docNumberES
        ) {
          return this.$t("bankDetails.docNumberError");
        }
        if (fieldName.trim() == "IBAN") {
          if (
            fieldName.trim() == "IBAN" &&
            Object.prototype.hasOwnProperty.call(
              fieldValue,
              "validateIBANLength"
            ) &&
            !fieldValue.validateIBANLength
          ) {
            return this.$t("bankDetails.IBANdigitsGeneric", {
              ibanDigits: this.getIbanLength(),
            });
          }
          if (
            Object.prototype.hasOwnProperty.call(
              fieldValue,
              "beginsWithCountryCode"
            ) &&
            !fieldValue.beginsWithCountryCode
          ) {
            return this.$t("bankDetails.IBANCountryError");
          }
        }
        if (fieldName.trim() == "swift") {
          if (fieldValue.$invalid) {
            return this.$t("bankDetails.swiftMinmaxValidation");
          }
        }
      }

      if (
        this.country.code == "OTH" &&
        (fieldName == "accountNumber" || fieldName == "IBAN")
      ) {
        const formData = this.$v.formData;
        if (
          !formData.validateOTH &&
          formData.IBAN.$dirty &&
          formData.accountNumber.$dirty
        ) {
          if (!formData.IBAN.$invalid || !formData.accountNumber.$invalid)
            return;
          return this.$t("bankDetails.othersValidation");
        }
        return null;
      }
      return undefined;
    },
    validateDni(s) {
      return new RegExp("^[0-9]{8}[A-Za-z]{1}$").test(s);
    },
    validateNie(s) {
      return new RegExp("^[A-Za-z]{1}[0-9]{7,8}[0-9A-Za-z]{1}$").test(s);
    },
    validateNif(s) {
      return new RegExp("^[A-Za-z]{1}[0-9]{8}$").test(s);
    },
    validateDocNumber(docType, docNumber) {
      /*
          regexes:
          dni:  new RegExp("^[0-9]{8}[A-Za-z]{1}$").test('11223144A');
          nie: new RegExp("^[A-Za-z]{1}[0-9]{7,8}[0-9A-Za-z]{1}$").test('X4978143T');
          nif: new RegExp("^[A-Za-z]{1}[0-9]{8}$").test('A82533851');
        */
      switch (docType) {
        case "DNI":
          return this.validateDni(docNumber);
        case "NIE":
          return this.validateNie(docNumber);
        case "NIF":
          return this.validateNif(docNumber);
      }
      return true;
    },
    labelFor(f) {
      if (this.countryConfig == null) {
        return f;
      }
      const label = this.countryConfig[f].label;
      return this.$i18n.t(label);
    },
    placeholderFor(f) {
      if (!this.countryConfig) {
        return f;
      }
      const placeholder = this.countryConfig[f].placeholder;
      if (!placeholder) return null;
      return this.$i18n.t(placeholder);
    },
    isFieldEnabled(field) {
      if (this.countryConfig[field]) {
        return this.countryConfig[field].visible;
      }
      return false;
    },
  },
};
</script>
