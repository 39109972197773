<template>
  <div class="data-clientComp">
    <div v-if="multiplePassengers">
      <h5 class="title-selection">
        {{ $t("compensationSelection.titleSelection") }}
      </h5>
      <v-flex xs12 sm4>
        <ValidationProvider rules="required">
          <VSelectWithValidation
            :rules="{ required: true }"
            v-model="optionSelected"
            @change="selectedPassenger"
            :items="milesPassengersList"
            :label="$t('directService.customerData.selectPassenger')"
          />
        </ValidationProvider>
      </v-flex>
    </div>
    <v-row v-if="dataMiles">
      <v-col cols="4">
        <VTextFieldWithValidation
          :rules="{ required: true, alphacar_spaces: true }"
          :counter="90"
          :label="$t('directService.customerData.name')"
          type="text"
          :readonly="defaultValues?.['FirstName'] !== ''"
          v-model="dataMiles['FirstName']"
        />
      </v-col>
      <v-col cols="4">
        <VTextFieldWithValidation
          :rules="{ required: true, alphacar_spaces: true }"
          :counter="90"
          :label="$t('directService.customerData.secondName')"
          type="text"
          :readonly="defaultValues?.['LastName'] !== ''"
          v-model="dataMiles['LastName']"
        />
      </v-col>
      <v-col cols="4">
        <div>
          <VTextFieldWithValidation
            :rules="{ required: false, alpha_spaces: true }"
            :counter="90"
            :label="$t('compensationSelection.clientForm.middleName')"
            type="text"
            v-model="dataMiles['SecondLastName']"
          />
        </div>
      </v-col>
    </v-row>
    <v-row v-if="dataMiles">
      <v-col cols="4">
        <div>
          <VTextFieldWithValidation
            :rules="{ required: true }"
            :counter="90"
            :label="$t('compensationSelection.sumaForm.sumaLevel')"
            type="text"
            readonly
            v-model="dataMiles['SUMAFrequentFlyerProgram']['Level']"
          />
        </div>
      </v-col>
      <v-col cols="4">
        <div>
          <VTextFieldWithValidation
            :rules="{ required: true }"
            :counter="90"
            :label="$t('compensationSelection.sumaForm.sumaCard')"
            type="text"
            readonly
            v-model="dataMiles['SUMAFrequentFlyerProgram']['Number']"
          />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import VTextFieldWithValidation from "@/components/dynamic/VTextFieldWithValidation.vue";
import { ValidationProvider } from "vee-validate";
import VSelectWithValidation from "@/components/dynamic/VSelectWithValidation.vue";

export default {
  name: "DataMillasComponent",
  components: {
    VTextFieldWithValidation,
    ValidationProvider,
    VSelectWithValidation,
  },
  data: () => ({
    dataMiles: null,
    optionSelected: null,
    milesPassengersList: null,
    defaultValues: null,
  }),
  props: {
    milesPassengers: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    if (this.milesPassengers.length === 1)
      this.selectedPassenger(this.milesPassengers[0]);
  },
  computed: {
    multiplePassengers() {
      return this.milesPassengers.length > 1;
    },
  },
  created() {
    this.loadPassengers();
  },
  methods: {
    selectedPassenger(passenger) {
      this.dataMiles = {
        ...passenger,
        SUMAFrequentFlyerProgram: passenger?.SUMAFrequentFlyerProgram || {},
      };
      this.defaultValues = {
        ...this.dataMiles,
      };
    },
    loadPassengers() {
      this.milesPassengersList = this.milesPassengers.map((e) => ({
        name: e.FirstName + " " + e.LastName,
        value: e,
      }));
    },
  },
};
</script>

<style scoped lang="scss">
.title-selection {
  font-family: Lato, -apple-system, blinkmacsystemfont, Segoe UI, Roboto,
    Helvetica Neue, arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    Segoe UI Symbol, icomoon;
  text-decoration: none;
  color: #0f2367;
}
.main-background {
  background-color: #fafafa;
}
.data-clientComp {
  margin: 1em 1em 2em 2em;
  @media (min-width: 960px) {
    max-width: 80%;
  }
  .passenger-info {
    font-size: 20px;
    font-family: Lato, -apple-system, blinkmacsystemfont, Segoe UI, Roboto,
      Helvetica Neue, arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
      Segoe UI Symbol, icomoon;
    margin-top: 16px;
    margin-bottom: 16px;
    text-decoration: none;
    color: #0f2367;
  }
  .dni-info {
    font-size: 14px;
    font-family: Lato, -apple-system, blinkmacsystemfont, Segoe UI, Roboto,
      Helvetica Neue, arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
      Segoe UI Symbol, icomoon;
    margin-bottom: 16px;
    text-decoration: none;
    color: #0f2367;
  }
  .btn-bank {
    color: white;
    background-color: #2d69e1;
    margin-top: 10px;
    margin-bottom: 1rem;
    margin-left: auto;
    margin-right: auto;
    height: 48px;
    border-radius: 14px;
    font-size: 16px !important;
    padding: 12px 24px;
  }
}
</style>
