import { defineStore } from "pinia";

export const useCompensationStore = defineStore("compensation", {
  state: () => ({
    passengerData: null,
    passengerDataMiles: null,
    proposalData: null,
    proposalObject: null,
    countryObject: null,
    jsonRes: null,
    jsonPost: null,
    pyId: null,
  }),
  getters: {},
  actions: {},
});
