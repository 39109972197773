<template lang="html">
  <div class="homepage">
    <div class="title"></div>
  </div>
</template>

<script>
export default {
  name: "HomePage",
};
</script>

<style lang="css" scoped>
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  margin: 0;
}
* {
  box-sizing: border-box;
}
.homepage {
  text-align: center;
  background-image: url(../assets/home.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  background-attachment: fixed;
  /*overflow-y: hidden;*/
  padding: 0 7px;
  width: 100%;
  height: 100%;
}

.title:after {
  content: "AE Customer Services";
}
.title:after {
  font-family: "OpenSans", sans-serif;
  font-weight: 900;
  text-transform: uppercase;
  word-spacing: 1px;
  line-height: 40px;
  font-size: 26px;
  color: #fff;
  width: 150px;
  height: 150px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -75px;
  margin-left: -180px;
  white-space: nowrap;
}
/*.title:after {
  font-family: "OpenSans", sans-serif;
  font-weight: 900;
  text-transform: uppercase;
  word-spacing: 1px;
  letter-spacing: 1px;
  line-height: 40px;
  font-size: 26px;
  color: #fff;
  margin-bottom: 14px;
  display: block;
  margin-top: 340px;
  display: flex;
  justify-content: center;
  align-items: center;
}*/
</style>
