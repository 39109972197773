import { defineStore } from "pinia";
import { loadLanguageAsync } from "../i18n/translation";
import { useTokenStore } from "./tokenStore";
export const useLanguageStore = defineStore("language", {
  state: () => ({
    language: null,
  }),
  getters: {},
  actions: {
    changeLanguage(lang) {
      const tokenStore = useTokenStore();
      this.language = lang?.toLowerCase() || tokenStore?.lang || "es";
      this.$locale = this.language.toLowerCase();
      loadLanguageAsync(this.language.toLowerCase());
    },
  },
});
