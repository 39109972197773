<template>
  <BankDetails />
</template>

<script>
import BankDetails from "@/components/bankdetails/BankDetails";

export default {
  name: "BankDetailsPage",
  components: {
    BankDetails,
  },
};
</script>
