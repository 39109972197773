// Isla de Navidad
const CX = {
  countryConfig: {
    bankCode: {
      visible: true,
      label: "bankDetails.BSB",
      required: true,
    },
    accountNumber: {
      visible: true,
      label: "bankDetails.accountNumber",
      required: true,
    },
    IBAN: {
      visible: false,
    },
    swift: {
      label: "SWIFT",
      visible: true,
      required: true,
    },
  },
};
export { CX };
