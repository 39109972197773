<script>
export default {
  name: "tc-Aebond",
  props: ["lang", "dialog"],
};
</script>

<template>
  <div class="dialog-wrapper">
    <h2 v-html="$t('compensation.tcAEBond.header')"></h2>
    <ul>
      <li v-html="$t('compensation.tcAEBond.line1')"></li>
      <li v-html="$t('compensation.tcAEBond.line2')"></li>
      <li v-html="$t('compensation.tcAEBond.line3')"></li>
      <li v-html="$t('compensation.tcAEBond.line4')"></li>
      <li v-html="$t('compensation.tcAEBond.line5')"></li>
      <li v-html="$t('compensation.tcAEBond.line6')"></li>
      <li v-html="$t('compensation.tcAEBond.line7')"></li>
      <li v-html="$t('compensation.tcAEBond.line8')"></li>
    </ul>
    <br />
    <p v-html="$t('compensation.tcAEBond.line9')"></p>
    <button class="cta" @click="dialog()">{{ $t("generic_ok") }}</button>
  </div>
</template>

<style scoped>
h2 {
  font-family: Aeradar-Bold, sans-serif;
  text-align: center;
  color: #2d69e1;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 14px;
}

li {
  text-align: left;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 0px;
}

.cta {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  align-self: unset;
  font-family: Lato;
  text-decoration: none;
  padding: 12px 64px;
  margin-top: 20px;
  width: min-content;
  border-radius: 14px;
  background-color: #2d69e1;
  font-family: Lato;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.dialog-wrapper {
  padding: 0px 24px;
  text-align: left;
  display: grid;
}
</style>
