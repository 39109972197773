const ES = {
  ibanLength: 24,
  countryConfig: {
    accountHolderDocNumber: {
      label: "compensation.numeroDocumento",
    },
    accountNumber: {
      visible: false,
    },
    swift: {
      label: "bankDetails.swift",
      visible: true,
      required: true,
    },
    swift2: {
      visible: false,
    },
    IBAN: {
      visible: true,
      label: "bankDetails.IBAN",
      required: true,
    },
    bankName: {
      visible: false,
      required: false,
    },
    bankAddress: {
      visible: false,
      required: false,
    },
  },
};

export { ES };
