<template>
  <v-container class="feedback-view">
    <ErrorView
      v-if="mode === 'error'"
      :hidetitle="hidetitle"
      :title="title"
      :subtitle="msg"
    />
    <SuccessView v-else :title="title" :subtitle="msg" />
  </v-container>
</template>

<script>
import ErrorView from "./ErrorView";
import SuccessView from "./SuccessView";

export default {
  name: "feedbackView",
  props: ["mode", "text", "title", "msg", "token", "caseId", "hidetitle"],
  components: {
    ErrorView,
    SuccessView,
  },
};
</script>
<style lang="scss" scoped>
.feedback-view {
  padding-top: 0;
  margin: 0 auto;
  display: flex;
  align-items: center;
  height: 90%;
  justify-content: center;
  @media (max-width: 640px) {
    height: 100%;
  }
}
</style>
