const ES = [
  {
    name: "ARABA/ALAVA",
    value: "ARABA/ALAVA",
  },
  {
    name: "ALBACETE",
    value: "ALBACETE",
  },
  {
    name: "ALICANTE - ALACANT",
    value: "ALICANTE - ALACANT",
  },
  {
    name: "ALMERIA",
    value: "ALMERIA",
  },
  {
    name: "AVILA",
    value: "AVILA",
  },
  {
    name: "BADAJOZ",
    value: "BADAJOZ",
  },
  {
    name: "ILLES BALEARS",
    value: "ILLES BALEARS",
  },
  {
    name: "BARCELONA",
    value: "BARCELONA",
  },
  {
    name: "BURGOS",
    value: "BURGOS",
  },
  {
    name: "CACERES",
    value: "CACERES",
  },
  {
    name: "CADIZ",
    value: "CADIZ",
  },
  {
    name: "CASTELLON/CASTELLO",
    value: "CASTELLON/CASTELLO",
  },
  {
    name: "CIUDAD REAL",
    value: "CIUDAD REAL",
  },
  {
    name: "CORDOBA",
    value: "CORDOBA",
  },
  {
    name: "A CORUÑA",
    value: "A CORUÑA",
  },
  {
    name: "CUENCA",
    value: "CUENCA",
  },
  {
    name: "GIRONA",
    value: "GIRONA",
  },
  {
    name: "GRANADA",
    value: "GRANADA",
  },
  {
    name: "GUADALAJARA",
    value: "GUADALAJARA",
  },
  {
    name: "GIPUZKOA",
    value: "GIPUZKOA",
  },
  {
    name: "HUELVA",
    value: "HUELVA",
  },
  {
    name: "HUESCA",
    value: "HUESCA",
  },
  {
    name: "JAEN",
    value: "JAEN",
  },
  {
    name: "LEON",
    value: "LEON",
  },
  {
    name: "LLEIDA",
    value: "LLEIDA",
  },
  {
    name: "LA RIOJA",
    value: "LA RIOJA",
  },
  {
    name: "LUGO",
    value: "LUGO",
  },
  {
    name: "MADRID",
    value: "MADRID",
  },
  {
    name: "MALAGA",
    value: "MALAGA",
  },
  {
    name: "MURCIA",
    value: "MURCIA",
  },
  {
    name: "NAVARRA",
    value: "NAVARRA",
  },
  {
    name: "OURENSE",
    value: "OURENSE",
  },
  {
    name: "ASTURIAS",
    value: "ASTURIAS",
  },
  {
    name: "PALENCIA",
    value: "PALENCIA",
  },
  {
    name: "LAS PALMAS",
    value: "LAS PALMAS",
  },
  {
    name: "PONTEVEDRA",
    value: "PONTEVEDRA",
  },
  {
    name: "SALAMANCA",
    value: "SALAMANCA",
  },
  {
    name: "S.C. TENERIFE",
    value: "S.C. TENERIFE",
  },
  {
    name: "CANTABRIA",
    value: "CANTABRIA",
  },
  {
    name: "SEGOVIA",
    value: "SEGOVIA",
  },
  {
    name: "SEVILLA",
    value: "SEVILLA",
  },
  {
    name: "SORIA",
    value: "SORIA",
  },
  {
    name: "TARRAGONA",
    value: "TARRAGONA",
  },
  {
    name: "TERUEL",
    value: "TERUEL",
  },
  {
    name: "TOLEDO",
    value: "TOLEDO",
  },
  {
    name: "VALENCIA",
    value: "VALENCIA",
  },
  {
    name: "VALLADOLID",
    value: "VALLADOLID",
  },
  {
    name: "BIZKAIA",
    value: "BIZKAIA",
  },
  {
    name: "ZAMORA",
    value: "ZAMORA",
  },
  {
    name: "ZARAGOZA",
    value: "ZARAGOZA",
  },
];
export { ES };
