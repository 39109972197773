<template>
  <layout align-center>
    <template slot="header"></template>
    <template slot="content">
      <v-responsive>
        <v-container fill-height>
          <br />
          <div class="text-not-found">
            <p>Oops! The requested resource is not available.</p>
          </div>
        </v-container>
      </v-responsive>
    </template>
    <template slot="footer"></template>
  </layout>
</template>

<script>
import Layout from "@/components/Layout";
export default {
  components: { Layout },
};
</script>

<style lang="scss">
.text-not-found {
  text-align: left;
  color: #074ff3;
  font-size: 22px;
}
</style>
