<template>
  <!-- id="bankdetails-layout" -->
  <div class="py-2 h-100">
    <FeedBackView
      v-if="feedBackView && feedBackView.title"
      :mode="feedBackView.mode"
      :title="feedBackView.title"
      :msg="feedBackView.msg"
    />
    <v-container v-if="pageStatus == PAGESTATUS_FORM">
      <ValidationObserver ref="obs" v-slot="{ invalid, dirty }">
        <h1 class="text-sm-left">{{ $t("bankDetails.titleFormB") }}</h1>
        <div class="text-bank-details d-flex flex-column">
          <DynamicForm ref="formMonetary" :values="defaultValues" />
          <v-btn
            class="btn-bank pa-5"
            :disabled="!dirty || invalid"
            @click="submit"
          >
            {{ $t("bankDetails.submit") }}</v-btn
          >
          <p class="confirm-bank text-center">
            {{ $t("bankDetails.confirmbank") }}
          </p>
        </div>
      </ValidationObserver>
    </v-container>

    <BankDetailsSapErrors
      :open="bankDetailsSapErrorsDialog.open"
      @close="bankDetailsSapErrorsDialogToggle"
      :errors="bankDetailsSapErrorsDialog.errors"
    />
  </div>
</template>

<script>
import Vuelidate from "vuelidate";
import FeedBackView from "../common/FeedbackView";
import BankDetailsSapErrors from "../dialogs/BankDetailsSapErrors.vue";
import Vue from "vue";
// import Layout from "@/components/Layout";
import DynamicForm from "@/components/dynamic/DynamicForm.vue";
import { ValidationObserver } from "vee-validate";
// import { loadLanguageAsync } from "../../i18n/translation";
import Api from "@/api/api.js";
import { mapActions, mapStores } from "pinia";
import { useCountriesStore } from "@/stores/countriesStore";
import { useLanguageStore } from "@/stores/languageStore";
import { useTokenStore } from "@/stores/tokenStore";
import { useUiStore } from "@/stores/uiStore";

Vue.use(Vuelidate);

export default {
  name: "BankDetails",
  components: {
    // Layout,
    FeedBackView,
    BankDetailsSapErrors,
    DynamicForm,
    ValidationObserver,
  },
  computed: {
    ...mapStores(useCountriesStore, useTokenStore, useLanguageStore),
    $crmEmail() {
      return process.env.VUE_APP_CRMEMAIL;
    },
  },
  created() {
    this.changeSpinner(true);
    this.token = this.tokenStore.token;
    this.caseId = this.tokenStore.caseId;
    this.loadBankDetails();
  },
  data: () => ({
    defaultValues: null,
    PAGESTATUS_FORM: 0,
    PAGESTATUS_SUCCESS: 1,
    PAGESTATUS_LOADERROR: 2,
    PAGESTATUS_SUBMITERROR: 3,
    PAGESTATUS_CUSTOMERROR: 4,
    PAGESTATUS_CASECLOSED: 5,
    PAGESTATUS_CASEONGOING: 6,
    PAGESTATUS_EXPIRED: 7,
    pageStatus: null,
    currencyCountries: null,
    bankDetailsSapErrorsDialog: {
      open: false,
      errors: [],
    },
    feedBackView: {
      mode: null,
      title: null,
      msg: null,
      hidetitle: false,
    },
    token: null,
    caseId: null,
  }),
  methods: {
    ...mapActions(useUiStore, ["changeSpinner"]),
    async loadBankDetails() {
      try {
        const { data } = await Api.bankDetailsLoad(this.caseId, this.token);
        this.defaultValues = data;
        const lang = data.Language != null ? data.Language : "en";
        this.languageStore.changeLanguage(lang);
        this.currencyCountries = data?.CurrencyCountries?.map((i) => i.Code);
        this.countriesStore.filterWithAvailableCurrency(this.currencyCountries);
        const statusCode = data.StatusCode;
        if (statusCode != this.PAGESTATUS_FORM) {
          const bankDetailsStatus = {
            2: this.PAGESTATUS_CASEONGOING,
            3: this.PAGESTATUS_EXPIRED,
            7: this.PAGESTATUS_CUSTOMERROR,
            8: this.PAGESTATUS_CASECLOSED,
            default: this.PAGESTATUS_LOADERROR,
          };
          this.pageStatus =
            bankDetailsStatus?.[statusCode] ?? bankDetailsStatus["default"];
          const loadedConfigByData = this.loadFeedBackViewConfigByStatusCode(
            this.pageStatus
          );
          this.loadFeedBack(loadedConfigByData);
        } else {
          this.pageStatus = this.PAGESTATUS_FORM;
        }
        this.changeSpinner(false);
      } catch (e) {
        this.changeSpinner(false);
        this.pageStatus = this.PAGESTATUS_LOADERROR;
      }
    },
    bankDetailsSapErrorsDialogToggle() {
      this.bankDetailsSapErrorsDialog.open =
        !this.bankDetailsSapErrorsDialog.open;
    },
    loadFeedBackViewConfigByStatusCode(statusCode) {
      const statusCodeConfig = {
        [this.PAGESTATUS_SUCCESS]: {
          mode: "success",
          title: this.$t("bankDetails.formSuccess"),
        },
        [this.PAGESTATUS_CASEONGOING]: {
          mode: "error",
          hidetitle: true,
          title: this.$t("compensation.alreadyWorking", {
            email: this.$crmEmail,
          }),
        },
        [this.PAGESTATUS_EXPIRED]: {
          mode: "error",
          hidetitle: false,
          title: this.$t("bankDetails.expired"),
        },
        [this.PAGESTATUS_CUSTOMERROR]: {
          mode: "error",
          title: this.$t("bankDetails.submitError"),
          text: this.$t("bankDetails.supportContact", {
            email: this.$crmEmail,
          }),
          msg: this.$t("bankDetails.alreadyCompletedError"),
        },
        [this.PAGESTATUS_CASECLOSED]: {
          mode: "error",
          title: this.$t("compensation.caseClosedError", {
            email: this.$crmEmail,
          }),
        },
        default: {
          mode: "error",
          title: this.$t("bankDetails.formLoadError"),
          text: this.$t("bankDetails.supportContact", {
            email: this.$crmEmail,
          }),
        },
      };
      return statusCodeConfig?.[statusCode] || statusCodeConfig["default"];
    },
    loadFeedBack({ mode = null, title = null, msg = null, hidetitle = false }) {
      this.feedBackView = {
        mode,
        title,
        msg,
        hidetitle,
      };
    },
    resetForm() {
      this.$refs.formMonetary.resetForm();
      this.$nextTick(() => {
        this.$refs.obs.reset();
      });
    },
    hasSapErrors(sapErrors) {
      if (!sapErrors) return;
      const errors = sapErrors.map((e) => {
        if (e?.Description) return e?.Description;
        return this.$te("errorsSap." + e.Code)
          ? this.$t("errorsSap." + e.Code)
          : null;
      });
      this.bankDetailsSapErrorsDialog = {
        open: true,
        errors,
      };
    },
    async submit() {
      try {
        this.changeSpinner(true);
        let body = this.$refs.formMonetary.dataForSend;
        if (body["IBAN"]) body["IBAN"] = body["IBAN"].toUpperCase();
        const { data } = await Api.bankDetailsSubmit(
          {
            ...body,
            Token: this.token,
            CaseId: this.caseId,
          },
          this.token,
          this.caseId
        );
        const status = {
          0: () => {
            this.pageStatus = this.PAGESTATUS_SUCCESS;
          },
          2: () => {
            this.pageStatus = this.PAGESTATUS_CUSTOMERROR;
          },
          8: () => {
            this.pageStatus = this.PAGESTATUS_CASECLOSED;
          },
          10: () => {
            this.hasSapErrors(data.ErrorsSAP);
            this.pageStatus = this.PAGESTATUS_FORM;
          },
          default: () => {
            this.pageStatus = this.PAGESTATUS_CUSTOMERROR;
          },
        };
        (status?.[data.StatusCode] || status?.["default"])();
        if (this.pageStatus !== this.PAGESTATUS_FORM) {
          const loadedConfigByData = this.loadFeedBackViewConfigByStatusCode(
            this.pageStatus
          );
          this.loadFeedBack(loadedConfigByData);
        }
        this.changeSpinner(false);
      } catch (error) {
        console.error({ error });
        this.changeSpinner(false);
        this.pageStatus = this.PAGESTATUS_CUSTOMERROR;
      }
    },
  },
};
</script>

<!-- <style src="./BankDetails.css" scoped></style> -->

<style type="scss" scoped>
.text-bank-details {
  background-color: #fafafa;
  padding: 28px;
  margin-top: 22px;
  border-radius: 10px;
}
.confirm-bank {
  font-size: small;
  max-width: 1280px;
  margin-top: 8px;
}
.h-100 {
  height: 100%;
}
</style>
