import { isValidPhoneNumber } from "libphonenumber-js";
import { extend } from "vee-validate";
import {
  required,
  min,
  regex,
  max,
  alpha_num,
  numeric,
  alpha,
  alpha_spaces,
  is_not,
} from "vee-validate/dist/rules";
import { i18n } from "../../i18n/translation";
// import { messages, LOCALE } from "../vee-validate";

// Install required rule.
extend("required", {
  ...required,
  // the values param is the placeholders values
  message: (_, values) => i18n.t("validations.messages.required", values),
});
extend("alpha_spaces", {
  ...alpha_spaces,
  // the values param is the placeholders values
  message: (_, values) => i18n.t("validations.messages.alpha_spaces", values),
});
extend("alpha_num", {
  ...alpha_num,
  // the values param is the placeholders values
  message: (_, values) => i18n.t("validations.messages.alpha_num", values),
});
extend("numeric", {
  ...numeric,
  // the values param is the placeholders values
  message: (_, values) => i18n.t("validations.messages.numeric", values),
});
extend("alpha", {
  ...alpha,
  // the values param is the placeholders values
  message: (_, values) => i18n.t("validations.messages.alpha", values),
});
// Install min rule.
extend("min", {
  ...min,
  message: (_, values) => i18n.t("validations.messages.min", values),
});

// Install min rule.
extend("max", {
  ...max,
  message: (_, values) => i18n.t("validations.messages.max", values),
});

// Install regex rule.
extend("regex", {
  ...regex,
  message: (_, values) => i18n.t("validations.messages.regex", values),
});

extend("ibanDigits", {
  message: (field, args) => {
    return i18n.t("bankDetails.IBANdigitsGeneric", {
      ibanDigits: args[0],
    });
  },
  validate: (value, args) => {
    const min = Number(args[0] || 0);
    const max = Number(args[1] || 0);
    if (min === 0 || max === 0) return true;
    return value.length <= min && value.length === max;
  },
});

extend("ibanCountryError", {
  message: (field, args) => {
    return i18n.t("bankDetails.IBANCountryError", {
      countryCode: args[0],
    });
  },
  validate: (value, args) =>
    value.slice(0, 2).toLowerCase() === args[0].toLowerCase(),
});

extend("isValidPhone", {
  message: (_, values) => i18n.t("validations.messages.regex", values),
  validate(value) {
    return isValidPhoneNumber(value);
  },
});
extend("alphanumcar_spaces", {
  message: "El campo {_field_} solo debe contener letras, números y espacios.",
  validate(value) {
    const validation = /^[a-zA-Z0-9\s.,!?áéíóúüñÁÉÍÓÚÜ]+$/;
    return validation.test(value); // verificación si se cumple la regla definida en la variable validation o no
  },
});
extend("num_car", {
  message: "El campo {_field_} solo debe contener letras, números y espacios.",
  validate(value) {
    const validation = /^[\d,.\s]+$/;
    return validation.test(value); // verificación si se cumple la regla definida en la variable validation o no
  },
});
extend("num_hyphen", {
  message: "El campo {_field_} solo debe contener números y guiones.",
  validate(value) {
    const validation = /^[0-9-]*$/;
    return validation.test(value); // verificación si se cumple la regla definida en la variable validation o no
  },
});
extend("is_not", {
  ...is_not,
  message: () => {
    return i18n.t("directService.customerData.alert");
  },
});
extend("alphacar_spaces", {
  message: "El campo {_field_} solo debe contener letras, números y espacios.",
  validate(value) {
    const validation = /^[a-zA-Z\sáéíóúüñÁÉÍÓÚÜ]+$/;
    return validation.test(value); // verificación si se cumple la regla definida en la variable validation o no
  },
});

extend("validateDoc", {
  message: (_, values) => {
    const newValues = { ...values, _field_: values[0] };
    return i18n.t("validations.messages.regex", newValues);
  },
  validate: (value, args) => {
    const validations = {
      DNI: new RegExp("^[0-9]{8}[A-Za-z]{1}$").test(value),
      NIE: new RegExp("^[A-Za-z]{1}[0-9]{7,8}[0-9A-Za-z]{1}$").test(value),
      NIF: new RegExp("^[A-Za-z]{1}[0-9]{8}$").test(value),
    };
    return validations[args[0]] ?? true;
  },
});
