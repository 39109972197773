const PT = [
  {
    name: "AVEIRO",
    value: "AVEIRO",
  },
  {
    name: "BEJABRAGA",
    value: "BEJABRAGA",
  },
  {
    name: "BRAGANÇA",
    value: "BRAGANÇA",
  },
  {
    name: "CASTELO",
    value: "CASTELO",
  },
  {
    name: "BRANCO",
    value: "BRANCO",
  },
  {
    name: "COIMBRA",
    value: "COIMBRA",
  },
  {
    name: "ÉVORA",
    value: "ÉVORA",
  },
  {
    name: "FARO",
    value: "FARO",
  },
  {
    name: "GUARDA",
    value: "GUARDA",
  },
  {
    name: "LEIRIA",
    value: "LEIRIA",
  },
  {
    name: "LISBOA",
    value: "LISBOA",
  },
  {
    name: "OPORTO",
    value: "OPORTO",
  },
  {
    name: "PORTALEGRE",
    value: "PORTALEGRE",
  },
  {
    name: "SANTARÉM",
    value: "SANTARÉM",
  },
  {
    name: "SETÚBAL",
    value: "SETÚBAL",
  },
  {
    name: "VIANA DO",
    value: "VIANA DO",
  },
  {
    name: "CASTELO",
    value: "CASTELO",
  },
  {
    name: "VILA REAL",
    value: "VILA REAL",
  },
  {
    name: "VISEU",
    value: "VISEU",
  },
];
export { PT };
