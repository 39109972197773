<template>
  <!-- <v-snackbar :value="snackbar">
    {{ text }}
    <template v-slot:action="{ attrs }">
      <v-btn
        :color="color"
        text
        v-bind="attrs"
        v-on:click="$emit('handlerClick')"
      >
        Cerrar
      </v-btn>
    </template>
  </v-snackbar> -->
  <v-dialog max-width="529" width="529" :value="snackbar">
    <v-card>
      <v-card-text class="pt-3">
        <div style="display: flex; flex-direction: column">
          <v-icon style="align-self: flex-end" @click="$emit('handlerClick')"
            >close</v-icon
          >
        </div>
        <div class="text-center" style="display: flex; flex-direction: column">
          <h2 class="mb-3 send-dialog-title">No se encuentra</h2>
          <v-btn
            class="btn-bank"
            style="width: 90%"
            @click="$emit('handlerClick')"
          >
            Cerrar
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "SnackBarComponent",

  props: {
    snackbar: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
<style scoped lang="scss">
.send-dialog-title {
  font-family: Aeradar-Bold, sans-serif;
  font-size: 22px;
  line-height: 32px;
  margin-bottom: 12px;
  color: #2d69e1;
}
</style>
