<template>
  <div class="h-100 dynamic-compensation">
    <section
      v-if="pageStatus == PAGESTATUS_SUCCESS"
      class="alert-component elevation-0"
    >
      <div
        v-for="(compen, j) in compensationStore.jsonPost.compensations"
        :key="j"
      >
        <p
          v-if="
            compen.type == 'CMON' &&
            formDataBank.cmon.CompensationDetails.CountryCode === 'ES'
          "
          style="margin-top: 20px; color: #0f2367"
        >
          {{ $t("compensationSelection.cmonResumeInf") }}
        </p>
      </div>
      <ConfirmationComponent
        :title="$t('directService.confirmation-title')"
      ></ConfirmationComponent>
      <div class="desc-compensation">
        <div style="margin-top: 15px; margin-bottom: 15px">
          {{ $t("compensation.compensationSelect") }}
        </div>
        <v-row>
          <v-col cols="6">
            <div>
              <span>{{ $t("compensation.compensationType") }}</span>
            </div>
          </v-col>
          <v-col cols="6">
            <div>{{ $t("compensation.compensationAmount") }}</div>
          </v-col>
        </v-row>
        <div
          v-for="(compen, j) in compensationStore.jsonPost.compensations"
          :key="j"
        >
          <v-row>
            <v-col cols="6">
              <div>
                <div v-if="compen.type == 'CMON'">
                  {{ $t("compensationSelection.monetary") }}
                </div>
                <div v-if="compen.type == 'CBON'">
                  {{ $t("compensationSelection.bono") }}
                </div>
                <div v-if="compen.type == 'CMIL'">
                  {{ $t("compensationSelection.millas") }}
                </div>
              </div>
            </v-col>
            <v-col cols="6">
              <div>
                <div v-if="compen.type == 'CMON'">
                  {{ formatAmount(compen.amount) }} {{ compen.currency }}
                </div>
                <div v-if="compen.type == 'CBON'">
                  {{ formatAmount(compen.amount) }} {{ compen.currency }}
                </div>
                <div v-if="compen.type == 'CMIL'">
                  {{ formatAmount(compen.amount) }} {{ compen.currency }}
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
    </section>
    <v-container v-if="pageStatus != PAGESTATUS_SUCCESS">
      <!-- ----------------------------------Bono--------------------------- -->
      <ValidationObserver ref="clientFormObs" tag="form" v-slot="{ valid }">
        <div
          v-show="
            proposalOption.includes(1) ||
            (proposalOption.includes(5) && showDataClient == true) ||
            (proposalOption.includes(3) && showDataClient == true) ||
            (proposalOption.includes(7) && showDataClient == true)
          "
        >
          <div>
            <h4 class="title-info">
              {{ $t("compensationSelection.clientForm.titleClient") }}
            </h4>
            <p class="ae-description">
              {{ $t("compensationSelection.subtitle") }} {{ tokenStore.id }}
            </p>
            <h4 class="bono-info">
              *{{ $t("directService.customerData.infoBono") }}
            </h4>
          </div>
          <DataClientComponent
            ref="dataClientForm"
            :passengers="passengerList"
          />
        </div>

        <div
          class="d-sm-flex"
          v-if="
            (proposalOption.includes(3) && showDataClient == true) ||
            (proposalOption.includes(5) && showDataClient == true) ||
            (proposalOption.includes(7) && showDataClient == true)
          "
        >
          <v-btn
            class="btn-confirm"
            style="margin-right: unset"
            color="primary"
            dark
            large
            :disabled="!valid"
            @click="nextCBON"
          >
            <span class="mr-1">{{
              $t("compensationSelection.btnContinue")
            }}</span>

            <v-icon dark>mdi-send</v-icon>
          </v-btn>
        </div>
        <div class="d-sm-flex" v-if="proposalOption.includes(1)">
          <v-btn
            class="btn-confirm"
            style="margin-right: unset"
            color="primary"
            dark
            large
            :disabled="!valid"
            @click="submitFormClient"
          >
            <span class="mr-1">{{
              $t("compensationSelection.btnConfirm")
            }}</span>
            <v-icon dark>mdi-send</v-icon>
          </v-btn>
        </div>
      </ValidationObserver>
      <!-- --------------------------------Millas--------------------------- -->
      <ValidationObserver ref="milesFormObs" tag="form" v-slot="{ valid }">
        <div
          v-show="
            proposalOption.includes(4) ||
            (proposalOption.includes(5) &&
              showDataMiles == true &&
              showDataClient == false) ||
            (proposalOption.includes(6) &&
              showDataMiles == true &&
              showDataBank == false) ||
            (proposalOption.includes(8) &&
              showDataMiles == true &&
              showDataBank == false &&
              showDataClient == false)
          "
        >
          <div>
            <h4 class="title-info">
              {{ $t("compensationSelection.clientForm.titleMillas") }}
            </h4>
            <p class="ae-description">
              {{ $t("compensationSelection.subtitle") }} {{ tokenStore.id }}
            </p>
          </div>
          <MillasComponent
            ref="dataMilesForm"
            :milesPassengers="milesPassengerList"
          />
        </div>
        <div
          class="d-sm-flex"
          v-if="
            (proposalOption.includes(6) &&
              showDataMiles == true &&
              showDataBank == false) ||
            (proposalOption.includes(8) &&
              showDataMiles == true &&
              showDataBank == false &&
              showDataClient == false)
          "
        >
          <v-btn
            class="btn-confirm"
            style="margin-right: unset"
            color="primary"
            dark
            large
            :disabled="!valid"
            @click="nextCMIL"
          >
            <span class="mr-1">{{
              $t("compensationSelection.btnContinue")
            }}</span>

            <v-icon dark>mdi-send</v-icon>
          </v-btn>
        </div>
        <div
          class="d-sm-flex"
          v-if="
            proposalOption.includes(4) ||
            (proposalOption.includes(5) &&
              showDataClient == false &&
              showDataBank == false)
          "
        >
          <v-btn
            class="btn-confirm"
            style="margin-right: unset"
            color="primary"
            dark
            large
            :disabled="!valid"
            @click="submitFormMiles"
          >
            <span class="mr-1">{{
              $t("compensationSelection.btnConfirm")
            }}</span>
            <v-icon dark>mdi-send</v-icon>
          </v-btn>
        </div>
      </ValidationObserver>
      <!-- --------------------------------Monetary--------------------------- -->
      <ValidationObserver ref="bankFormObs" tag="form" v-slot="{ valid }">
        <div
          v-show="
            proposalOption.includes(2) ||
            (proposalOption.includes(3) && showDataBank == true) ||
            (proposalOption.includes(6) &&
              showDataMiles == false &&
              showDataBank == true) ||
            (proposalOption.includes(8) &&
              showDataBank == true &&
              showDataClient == false &&
              showDataClient == false &&
              showDataMiles == false)
          "
        >
          <div>
            <h4 class="title-info">
              {{ $t("compensationSelection.clientForm.titleBank") }}
            </h4>
            <p class="ae-description">
              {{ $t("compensationSelection.subtitle") }} {{ tokenStore.id }}
            </p>
          </div>
          <DynamicForm
            ref="bankDetailsForm"
            :values="defaultValues"
          ></DynamicForm>
        </div>
        <div
          class="d-sm-flex"
          v-if="
            proposalOption.includes(2) ||
            (proposalOption.includes(3) &&
              showDataClient == false &&
              showDataBank == true) ||
            (proposalOption.includes(6) &&
              showDataBank == true &&
              showDataMiles == false) ||
            (proposalOption.includes(8) &&
              showDataBank == true &&
              showDataClient == false &&
              showDataMiles == false)
          "
        >
          <v-btn
            class="btn-confirm"
            style="margin-right: unset"
            color="primary"
            dark
            large
            :disabled="!valid"
            @click="submitFormBankDetails()"
          >
            <span class="mr-1">{{
              $t("compensationSelection.btnConfirm")
            }}</span>
            <v-icon dark>mdi-send</v-icon>
          </v-btn>
        </div>
      </ValidationObserver>
    </v-container>
    <BankDetailsSapErrors
      :open="bankDetailsSapErrorsDialog.open"
      @close="bankDetailsSapErrorsDialogToggle"
      :errors="bankDetailsSapErrorsDialog.errors"
    />
  </div>
</template>
<script>
import DataClientComponent from "./DataClientComponent.vue";
import BankDetailsSapErrors from "../dialogs/BankDetailsSapErrors.vue";
import DynamicForm from "../dynamic/DynamicForm.vue";
import { useCountriesStore } from "@/stores/countriesStore";
import { mapStores } from "pinia";
import { useLanguageStore } from "@/stores/languageStore";
import { useCompensationStore } from "@/stores/compensationStore";
import { useUiStore } from "@/stores/uiStore";
import { useTokenStore } from "@/stores/tokenStore";
import Api from "@/api/api.js";
import { ValidationObserver } from "vee-validate";
import ConfirmationComponent from "../ConfirmationComponent.vue";
import MillasComponent from "./MillasComponent.vue";

export default {
  name: "DynamicFormCompensation",
  components: {
    DataClientComponent,
    BankDetailsSapErrors,
    ValidationObserver,
    DynamicForm,
    ConfirmationComponent,
    MillasComponent,
  },
  data: () => ({
    defaultValues: null,
    currencyCountries: null,
    store: null,
    bankDetails: null,
    showDataClient: true,
    showDataBank: false,
    passengers: null,
    submittedSuccessfully: false,
    pageStatus: null,
    PAGESTATUS_FORM: 0,
    PAGESTATUS_SUCCESS: 1,
    bankDetailsSapErrorsDialog: {
      open: false,
      errors: [],
    },
    milesPassengers: null,
    showDataMiles: true,
    formDataClient: null,
    formDataBank: null,
    formDataMiles: null,
    soloBon: true,
  }),
  props: {
    proposalOption: {
      type: Array,
      default: () => [],
    },
    passengerList: {
      type: Array,
      default: () => [],
    },
    milesPassengerList: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapStores(
      useCountriesStore,
      useLanguageStore,
      useUiStore,
      useCompensationStore,
      useTokenStore
    ),
    isDataValid() {
      return this.$refs?.clientFormObs?.validate({ silent: true }) || false;
    },
  },
  mounted() {
    // setTimeout(() => {
    //   this.loadBankDetails();
    // }, 1000);
    this.loadBankDetails();
  },
  methods: {
    formatAmount(amount) {
      const amountTrunc = Math.trunc(amount);
      const hasDecimal = amount !== amountTrunc;
      if (hasDecimal) {
        const amountRound = Math.round(amount * 100) / 100;
        return amountRound.toLocaleString();
      } else {
        return amountTrunc.toLocaleString();
      }
    },
    async mapClientForm() {
      const clientForm =
        this.$refs.dataClientForm.dataClient ||
        this.compensationStore.passengerData;
      this.formDataClient = this.compensationStore.proposalObject;

      const passengerSelected = this.formDataClient.PassengerList.find((p) => {
        return p.PersonalDocuments.find(
          (c) => c.Number === clientForm["PersonalDocuments"]["Number"]
        );
      });
      delete this.formDataClient.PassengerList;
      this.formDataClient.BondPassenger = {
        pxObjClass: "AE-Data-Passenger",
        FirstName: clientForm["FirstName"],
        FullName: clientForm["FullName"],
        LastName: clientForm["LastName"],
        SecondLastName: clientForm["SecondLastName"],
        ContactId: passengerSelected?.ContactId,
        Email: passengerSelected?.Email,
        TKT: passengerSelected?.TKT,
        PNR: passengerSelected?.PNR,
        PaxType: passengerSelected?.PaxType,
        PersonalDocuments: [
          {
            FullDocumentDetails:
              clientForm["PersonalDocuments"]["FullDocumentDetails"],
            Number: clientForm["PersonalDocuments"]["Number"],
            pxObjClass: "",
            Type: clientForm["PersonalDocuments"]["Type"],
          },
        ],
      };
    },
    async nextCBON() {
      this.uiStore.changeSpinner(true);
      try {
        if (this.proposalOption.includes(5)) {
          this.mapClientForm();
          this.showDataClient = false;
          this.showDataMiles = true;
        } else if (
          this.proposalOption.includes(7) &&
          this.showDataMiles == true
        ) {
          this.mapClientForm();
          this.showDataClient = false;
          this.showDataMiles = true;
        }
        if (
          !this.proposalOption.includes(7) &&
          this.proposalOption.includes(3)
        ) {
          this.mapClientForm();
          let response = await Api.sendCompensationSelected(
            this.formDataClient,
            this.tokenStore.id
          );
          this.showDataClient = false;
          this.showDataBank = true;
          this.compensationStore.pyId = response.cmon.pyID;

          this.compensationStore.countryObject = response.countriesByCurrency;
          this.loadBankDetails();
        }
        this.uiStore.changeSpinner(false);
      } catch (e) {
        if (e?.response?.status === 400) {
          this.hasSapErrors(e.response.data.errorDescription.split(";"));
          this.pageStatus = this.PAGESTATUS_FORM;
        } else if (e?.response?.status === 404) {
          this.setCustomError({
            customError: this.$t("error.error404"),
          });
        } else if (e?.response?.status === 409) {
          this.setCustomError({
            customError: this.$t("error.error409"),
          });
        } else if (e?.response?.status === 410) {
          this.setCustomError({
            customError: this.$t("error.error410C"),
          });
        } else if (e?.response?.status === 423) {
          this.setCustomError({
            customError: this.$t("error.error423C"),
          });
        } else if (e?.response?.status) {
          this.setCustomError({ errorCode: e.response.status });
        }
        this.uiStore.changeSpinner(false);
      }
    },
    async submitFormClient() {
      try {
        this.mapClientForm();
        this.uiStore.changeSpinner(true);
        let response = await Api.sendFormCompensationData(
          this.formDataClient,
          this.tokenStore.id
        );
        this.compensationStore.jsonPost = response;
        this.pageStatus = this.PAGESTATUS_SUCCESS;
        this.uiStore.changeSpinner(false);
      } catch (e) {
        if (e?.response?.status === 400) {
          this.hasSapErrors(e.response.data.errorDescription.split(";"));
          this.pageStatus = this.PAGESTATUS_FORM;
        } else if (e?.response?.status === 404) {
          this.setCustomError({
            customError: this.$t("error.error404"),
          });
        } else if (e?.response?.status === 409) {
          this.setCustomError({
            customError: this.$t("error.error409"),
          });
        } else if (e?.response?.status === 410) {
          this.setCustomError({
            customError: this.$t("error.error410C"),
          });
        } else if (e?.response?.status === 423) {
          this.setCustomError({
            customError: this.$t("error.error423C"),
          });
        } else if (e?.response?.status) {
          this.setCustomError({ errorCode: e.response.status });
        }
        this.uiStore.changeSpinner(false);
      }
    },
    async mapMilesForm() {
      const milesForm =
        this.$refs.dataMilesForm.dataMiles ||
        this.compensationStore.passengerDataMiles;
      this.formDataMiles = this.compensationStore.proposalObject;
      delete this.formDataMiles.MilesPassengerList;
      this.formDataMiles.MilesPassenger = {
        pxObjClass: "AE-Data-Passenger",
        FirstName: milesForm["FirstName"],
        LastName: milesForm["LastName"],
        SecondLastName: milesForm["SecondLastName"],
        SUMAFrequentFlyerProgram: {
          Level: milesForm["SUMAFrequentFlyerProgram"]["Level"],
          Number: milesForm["SUMAFrequentFlyerProgram"]["Number"],
        },
      };
      if (this.formDataMiles.PassengerList) {
        delete this.formDataMiles.PassengerList;
      }
    },
    async nextCMIL() {
      this.uiStore.changeSpinner(true);
      try {
        if (
          this.proposalOption.includes(6) ||
          this.proposalOption.includes(8)
        ) {
          this.mapMilesForm();
          let mapObject = {
            ...this.formDataMiles,
            ...this.formDataClient,
          };
          let response = await Api.sendCompensationSelected(
            mapObject,
            this.tokenStore.id
          );
          this.showDataMiles = false;
          this.showDataBank = true;
          this.compensationStore.pyId = response.cmon.pyID;
          this.compensationStore.countryObject = response.countriesByCurrency;
          this.loadBankDetails();
        }
        this.uiStore.changeSpinner(false);
      } catch (e) {
        if (e?.response?.status === 400) {
          this.hasSapErrors(e.response.data.errorDescription.split(";"));
          this.pageStatus = this.PAGESTATUS_FORM;
        } else if (e?.response?.status === 404) {
          this.setCustomError({
            customError: this.$t("error.error404"),
          });
        } else if (e?.response?.status === 409) {
          this.setCustomError({
            customError: this.$t("error.error409"),
          });
        } else if (e?.response?.status === 410) {
          this.setCustomError({
            customError: this.$t("error.error410C"),
          });
        } else if (e?.response?.status === 423) {
          this.setCustomError({
            customError: this.$t("error.error423C"),
          });
        } else if (e?.response?.status) {
          this.setCustomError({ errorCode: e.response.status });
        }
        this.uiStore.changeSpinner(false);
      }
    },
    async submitFormMiles() {
      try {
        this.mapMilesForm();
        //con isFilledPassengerMiles se bloquea el envío de datos del cmil si no se rellenan los datos requeridos para el cmil
        //estos datos deberían venir ya de PEGA pero si no ocurriera el cmil no se puede crear en blanco
        if (this.isFilledPassengerMiles()) {
          this.uiStore.changeSpinner(true);
          if (this.proposalOption.includes(4)) {
            this.uiStore.changeSpinner(true);
            this.formDataMiles;
            let response = await Api.sendCompensationSelected(
              this.formDataMiles,
              this.tokenStore.id
            );
            this.compensationStore.jsonPost = response;
          }
          if (this.proposalOption.includes(5)) {
            let mapObject = {
              ...this.formDataClient,
              ...this.formDataMiles,
            };

            this.uiStore.changeSpinner(true);
            let response = await Api.sendFormCompensationData(
              mapObject,
              this.tokenStore.id
            );
            this.compensationStore.jsonPost = response;
          }
          this.pageStatus = this.PAGESTATUS_SUCCESS;
          this.uiStore.changeSpinner(false);
        }
      } catch (e) {
        if (e?.response?.status === 400) {
          this.hasSapErrors(e.response.data.errorDescription.split(";"));
          this.pageStatus = this.PAGESTATUS_FORM;
        } else if (e?.response?.status === 404) {
          this.setCustomError({
            customError: this.$t("error.error404"),
          });
        } else if (e?.response?.status === 409) {
          this.setCustomError({
            customError: this.$t("error.error409"),
          });
        } else if (e?.response?.status === 410) {
          this.setCustomError({
            customError: this.$t("error.error410C"),
          });
        } else if (e?.response?.status === 423) {
          this.setCustomError({
            customError: this.$t("error.error423C"),
          });
        } else if (e?.response?.status) {
          this.setCustomError({ errorCode: e.response.status });
        }
        this.uiStore.changeSpinner(false);
      }
    },
    isFilledPassengerMiles() {
      if (
        typeof this.formDataMiles != "undefined" &&
        typeof this.formDataMiles.MilesPassenger != "undefined" &&
        typeof this.formDataMiles.MilesPassenger.FirstName != "undefined" &&
        this.formDataMiles.MilesPassenger.FirstName != "" &&
        typeof this.formDataMiles.MilesPassenger.LastName != "undefined" &&
        this.formDataMiles.MilesPassenger.LastName != "" &&
        typeof this.formDataMiles.MilesPassenger.SUMAFrequentFlyerProgram !=
          "undefined" &&
        typeof this.formDataMiles.MilesPassenger.SUMAFrequentFlyerProgram
          .Number != "undefined" &&
        this.formDataMiles.MilesPassenger.SUMAFrequentFlyerProgram.Number != ""
      ) {
        return true;
      }
      return false;
    },
    loadBankDetails() {
      try {
        this.currencyCountries = this.compensationStore?.countryObject
          ?.filter((i) => i.code)
          ?.map((c) => c.code);
        this.countriesStore.filterWithAvailableCurrency(this.currencyCountries);
      } catch (error) {
        console.log(error);
      }
    },
    async submitFormBankDetails() {
      try {
        const bankForm = this.$refs.bankDetailsForm.dataForSend;

        this.formDataBank = {
          cmon: {
            pyID: this.compensationStore.pyId,
            CompensationDetails: {
              pxObjClass: "AE-Data-CompensationDetails",
              CountryCode: bankForm["CountryCode"],
              Language:
                this.tokenStore.lang != null ? this.tokenStore.lang : "es",
              AccountHolderName: bankForm["AccountHolderName"] || "",
              AccountHolderDocType: bankForm["AccountHolderDocType"] || "",
              AccountHolderDocNumber: bankForm["AccountHolderDocNumber"] || "",
              AccountNumber: bankForm["AccountNumber"] || "",
              BankName: bankForm["BankName"] || "",
              BankCode: bankForm["BankCode"] || "",
              SWIFT: bankForm["SWIFT"] || "",
              SWIFT2: bankForm["SWIFT2"] || "",
              IBAN: bankForm["IBAN"] || "",
              RefBank: bankForm["RefBank"] || "",
              ControlDigit: bankForm["ControlDigit"] || "",
              AccountTypeBankDetails: bankForm["AccountTypeBankDetails"] || "",
              AddressLine2: bankForm["AddressLine2"] || "",
            },
          },
        };
        this.uiStore.changeSpinner(true);
        let response = await Api.sendFormBankData(this.formDataBank);
        this.pageStatus = this.PAGESTATUS_SUCCESS;
        this.compensationStore.jsonPost = response;
        this.uiStore.changeSpinner(false);
      } catch (e) {
        if (e?.response?.status === 400) {
          this.hasSapErrors(e.response?.data?.errorDescription?.split(";"));
          this.pageStatus = this.PAGESTATUS_FORM;
        } else if (e?.response?.status === 404) {
          this.setCustomError({
            customError: this.$t("error.error404"),
          });
        } else if (e?.response?.status === 409) {
          this.setCustomError({
            customError: this.$t("error.error409"),
          });
        } else if (e?.response?.status === 410) {
          this.setCustomError({
            customError: this.$t("error.error410C"),
          });
        } else if (e?.response?.status === 423) {
          this.setCustomError({
            customError: this.$t("error.error423C"),
          });
        } else if (e?.response?.status) {
          this.setCustomError({ errorCode: e.response.status });
        }
        this.uiStore.changeSpinner(false);
      }
    },
    setCustomError({ errorCode, customError = "errorsSap.TPY-0100" }) {
      let error = "";
      if (!errorCode && customError) {
        error = customError;
      }
      if (errorCode) {
        let status = errorCode.toString();
        if (status.match(/^5\d{2}$/)) status = "5XXC";
        error = `error.error${status}`;
        if (!this.$te(error)) error = customError;
      }
      this.uiStore.setError({
        message: this.$t(error),
      });
    },
    bankDetailsSapErrorsDialogToggle() {
      this.bankDetailsSapErrorsDialog.open =
        !this.bankDetailsSapErrorsDialog.open;
    },
    hasSapErrors(sapErrors) {
      if (!sapErrors) return;
      this.bankDetailsSapErrorsDialog = {
        open: true,
        errors: sapErrors,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.title-info {
  font-size: 20px;
  font-family: Lato, -apple-system, blinkmacsystemfont, Segoe UI, Roboto,
    Helvetica Neue, arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    Segoe UI Symbol, icomoon;
  text-decoration: none;
  color: #0f2367;
  margin-left: 1em;
}

.btn-confirm {
  color: white;
  background-color: #2d69e1;
  margin-top: 10px;
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
  height: 48px;
  border-radius: 14px;
  font-size: 16px !important;
  padding: 12px 24px;
}
.desc-compensation {
  color: #000;
  font-family: AERadar;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.alert-component {
  width: 570px;
  box-shadow: 0px 5px 10px rgba(204, 204, 204, 0.3);
  // padding: 100px;
  border-radius: 20px;
  margin: 0 auto;
  font-family: AERadar;
  color: #000;
  // font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}
.ae-description {
  font-size: 14px;
  font-family: Lato, -apple-system, blinkmacsystemfont, Segoe UI, Roboto,
    Helvetica Neue, arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    Segoe UI Symbol, icomoon;
  margin-bottom: 16px;
  text-decoration: none;
  color: #0f2367;
  margin-left: 21px;
}
.bono-info {
  font-size: 14px;
  font-family: Lato, -apple-system, blinkmacsystemfont, Segoe UI, Roboto,
    Helvetica Neue, arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    Segoe UI Symbol, icomoon;
  margin-bottom: 16px;
  text-decoration: none;
  color: #0f2367;
  margin-left: 21px;
}
</style>
