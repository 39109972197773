import { defineStore } from "pinia";

export const useUiStore = defineStore("ui", {
  state: () => ({
    spinner: false,
    error: null,
    idleTimer: 45,
    errorMsg: null,
  }),
  actions: {
    changeSpinner(loading) {
      if (typeof loading !== "boolean") return;
      this.spinner = loading;
    },
    setError({ code, message }) {
      if (!code && !message) this.error = null;
      this.error = { code, message };
    },
    setIdleTimer(time) {
      this.idleTimer = null;
      setTimeout(() => (this.idleTimer = time), 300);
    },
  },
  getters: {
    hasError() {
      return this.error ? true : false;
    },
  },
});
