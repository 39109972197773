import { i18n } from "../i18n/translation";
import "./validations/vee-validations";
import { configure } from "vee-validate";
configure({
  // this will be used to generate messages.
  defaultMessage: (field, values) => {
    values._field_ = i18n.t(`fields.${field}`);
    return i18n.t(`validations.messages.${values._rule_}`, values);
  },
});

export function loadLocale(code) {
  const validCodes = { pt: "pt_PT" };
  const validCode = validCodes[code] || code;
  return import(`vee-validate/dist/locale/${validCode}.json`);
}
