const PR = {
  countryConfig: {
    accountHolderDocNumber: {
      label: "bankDetails.accountHolderDocNumber",
      required: true,
    },
    ABA: {
      visible: true,
      label: "bankDetails.ABA",
      placeholder: "bankDetails.ABAPlaceholder",
      required: true,
    },
    accountNumber: {
      visible: false,
      required: false,
      label: "bankDetails.accountNumber",
    },
    IBAN: {
      visible: false,
    },
    swift: {
      visible: true,
      required: true,
      label: "bankDetails.swift",
      placeholder: "bankDetails.swiftMinmaxValidation",
    },
    bankAddress: {
      visible: false,
      required: false,
    },
    bankName: {
      visible: true,
      label: "bankDetails.bankName",
      required: true,
    },
  },
};
export { PR };
