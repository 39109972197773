<script setup>
import { watch, defineProps } from "vue";
import { useIdle } from "@vueuse/core";
import { useUiStore } from "@/stores/uiStore";

const uiStore = useUiStore();
const props = defineProps({
  time: {
    type: Number,
    default: 1,
    required: true,
  },
});
const { idle, reset, lastActive } = useIdle(props.time * 60 * 1000);

watch(idle, (idleValue) => {
  if (idleValue) {
    uiStore.setError({
      message: uiStore.errorMsg || this.$t("error.errorExpired"),
    });
  }
});

watch(lastActive, (lastActivate) => {
  if (lastActivate) {
    reset();
  }
});
</script>

<!-- eslint-disable-next-line vue/valid-template-root -->
<template></template>
