<template>
  <div>
    <svg
      style="margin-right: auto; margin-left: auto"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 130.2 130.2"
      v-if="!isIE()"
    >
      <circle
        class="path circle"
        fill="none"
        stroke="#eb0000"
        stroke-width="6"
        stroke-miterlimit="10"
        cx="65.1"
        cy="65.1"
        r="62.1"
      ></circle>
      <line
        class="path line"
        fill="none"
        stroke="#eb0000"
        stroke-width="6"
        stroke-linecap="round"
        stroke-miterlimit="10"
        x1="34.4"
        y1="37.9"
        x2="95.8"
        y2="92.3"
      ></line>
      <line
        class="path line"
        fill="none"
        stroke="#eb0000"
        stroke-width="6"
        stroke-linecap="round"
        stroke-miterlimit="10"
        x1="95.8"
        y1="38"
        x2="34.4"
        y2="92.2"
      ></line>
    </svg>
    <div class="checkIEError" v-else></div>
  </div>
</template>

<script>
import browser from "browser-detect";
export default {
  name: "AnimationErrorComponent",
  data() {
    return {
      browserName: null,
    };
  },
  created() {
    this.getBrowser();
  },
  methods: {
    getBrowser() {
      this.browserName = browser().name;
    },
    isIE: function () {
      return this.browserName === "ie" || this.browserName === "edge";
    },
  },
};
</script>

<style scoped>
.checkIEError {
  background-size: contain;
  background-image: url(../assets/error.svg.png);
  width: 76px;
  height: 76px;
}

#app .container.fill-height {
  display: block;
}

.textcleared {
  text-align: left;
  color: #074ff3;
}

svg {
  width: 76px;
  display: block;
  margin: 20px 0 0;
}

.path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
}

.path.circle {
  -webkit-animation: dash 0.9s ease-in-out;
  animation: dash 0.9s ease-in-out;
}

.path.line {
  stroke-dashoffset: 1000;
  -webkit-animation: dash 0.9s 0.35s ease-in-out forwards;
  animation: dash 0.9s 0.35s ease-in-out forwards;
}

@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }

  100% {
    stroke-dashoffset: 0;
  }
}
</style>
