const validateDni = (s) => {
  return new RegExp("^[0-9]{8}[A-Za-z]{1}$").test(s);
};
const validateNie = (s) => {
  return new RegExp("^[A-Za-z]{1}[0-9]{7,8}[0-9A-Za-z]{1}$").test(s);
};
const validateNif = (s) => {
  return new RegExp("^[A-Za-z]{1}[0-9]{8}$").test(s);
};

const validateValues = (data) => {
  let valores = Object.values(data);
  for (let i = 0; i < valores.length; i++) {
    if (valores[i] == "" || valores[i] == null) {
      return false;
    }
  }
  return true;
};

export { validateDni, validateNie, validateNif, validateValues };
