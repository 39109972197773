const AU = {
  countryConfig: {
    bankCode: {
      visible: true,
      label: "bankDetails.BSB",
      placeholder: "bankDetails.bsbPlaceholder",
      required: true,
    },
    accountNumber: {
      visible: true,
      label: "bankDetails.accountNumber",
      required: true,
    },
    IBAN: {
      visible: false,
      required: false,
    },
    swift: {
      label: "SWIFT",
      visible: true,
      required: true,
    },
    bankAddress: {
      visible: false,
      required: false,
    },
    bankName: {
      visible: true,
      required: true,
      label: "bankDetails.bankName",
    },
  },
};
export { AU };
