<template>
  <div class="data-clientComp">
    <div>
      <div v-if="multiplePassengers">
        <v-flex xs12 sm4>
          <ValidationProvider rules="required">
            <VSelectWithValidation
              v-model="optionSelected"
              @change="selectedPassenger"
              :items="passengerList"
              :rules="{ required: true }"
              :label="$t('directService.customerData.selectPassenger')"
            />
          </ValidationProvider>
        </v-flex>
      </div>
      <v-row v-if="dataClient">
        <v-col cols="4">
          <VTextFieldWithValidation
            :rules="{ required: true, alphacar_spaces: true }"
            :counter="90"
            :label="$t('directService.customerData.name')"
            type="text"
            :readonly="defaultValues?.['FirstName'] !== ''"
            v-model="dataClient['FirstName']"
          />
        </v-col>
        <v-col cols="4">
          <VTextFieldWithValidation
            :rules="{ required: true, alphacar_spaces: true }"
            :counter="90"
            :label="$t('directService.customerData.secondName')"
            type="text"
            :readonly="defaultValues?.['LastName'] !== ''"
            v-model="dataClient['LastName']"
          />
        </v-col>
        <v-col cols="4">
          <div>
            <VTextFieldWithValidation
              :rules="{ required: false, alpha_spaces: true }"
              :counter="90"
              :label="$t('compensationSelection.clientForm.middleName')"
              type="text"
              v-model="dataClient['SecondLastName']"
            />
          </div>
        </v-col>
      </v-row>
    </div>
    <div v-if="dataClient" style="margin-left: 10px">
      <h4 class="dni-info">
        {{ $t("compensationSelection.clientForm.DNIinfo") }}
      </h4>
      <v-layout row wrap>
        <v-flex xs12 sm4>
          <v-select
            v-model="dataClient['PersonalDocuments']['Type']"
            filled
            background-color="white"
            :items="documentTypes"
            :label="$t('compensation.tipoDocumento')"
          ></v-select>
        </v-flex>
        <v-flex xs12 sm4 mt-5 mt-sm-0 class="pl-sm-2">
          <VTextFieldWithValidation
            :rules="{ required: true }"
            :label="$t('compensation.numeroDocumento')"
            type="text"
            v-model="dataClient['PersonalDocuments']['Number']"
          ></VTextFieldWithValidation>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>

<script>
import VTextFieldWithValidation from "@/components/dynamic/VTextFieldWithValidation.vue";
import { ValidationProvider } from "vee-validate";
import VSelectWithValidation from "@/components/dynamic/VSelectWithValidation.vue";

export default {
  name: "DataClientComponent",
  components: {
    VTextFieldWithValidation,
    ValidationProvider,
    VSelectWithValidation,
  },
  data: () => ({
    dataClient: null,
    optionSelected: null,
    defaultValues: null,
    passengerList: null,
  }),
  props: {
    passengers: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    if (!this.multiplePassengers) this.selectedPassenger(this.passengers[0]);
  },
  computed: {
    documentTypes: function () {
      return [
        {
          text: this.$i18n.t("text.passport"),
          value: "PP",
        },
        {
          text: this.$i18n.t("text.nif"),
          value: "NIF",
        },
        {
          text: this.$i18n.t("text.dniapr"),
          value: "DNI",
        },
        {
          text: this.$i18n.t("text.nie"),
          value: "NIE",
        },
        {
          text: this.$i18n.t("compensation.others"),
          value: "OT",
        },
      ];
    },
    multiplePassengers() {
      return this.passengers.length > 1;
    },
  },
  created() {
    this.loadPassengers();
  },
  methods: {
    selectedPassenger(passenger) {
      this.dataClient = {
        ...passenger,
        PersonalDocuments: passenger?.PersonalDocuments?.[0] || {},
      };
      this.defaultValues = {
        ...this.dataClient,
      };
    },
    loadPassengers() {
      this.passengerList = this.passengers.map((e) => ({
        name: e.FullName,
        value: e,
      }));
    },
  },
};
</script>

<style scoped lang="scss">
.main-background {
  background-color: #fafafa;
}
.data-clientComp {
  margin: 1em 1em 2em 1em;
  @media (min-width: 960px) {
    max-width: 80%;
  }
  .passenger-info {
    font-size: 20px;
    font-family: Lato, -apple-system, blinkmacsystemfont, Segoe UI, Roboto,
      Helvetica Neue, arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
      Segoe UI Symbol, icomoon;
    margin-top: 16px;
    margin-bottom: 16px;
    text-decoration: none;
    color: #0f2367;
  }
  .dni-info {
    font-size: 14px;
    font-family: Lato, -apple-system, blinkmacsystemfont, Segoe UI, Roboto,
      Helvetica Neue, arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
      Segoe UI Symbol, icomoon;
    margin-bottom: 16px;
    text-decoration: none;
    color: #0f2367;
  }
  .btn-bank {
    color: white;
    background-color: #2d69e1;
    margin-top: 10px;
    margin-bottom: 1rem;
    margin-left: auto;
    margin-right: auto;
    height: 48px;
    border-radius: 14px;
    font-size: 16px !important;
    padding: 12px 24px;
  }
}
</style>
