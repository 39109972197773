<template>
  <v-overlay :value="show">
    <ProgressComponent :size="128" :indeterminate="true" :width="12" />
  </v-overlay>
</template>
<script>
import ProgressComponent from "./ProgressComponent";
export default {
  name: "SpinnerComponent",
  components: {
    ProgressComponent,
  },
  props: {
    show: { type: Boolean, default: false },
  },
};
</script>
