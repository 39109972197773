import Vue from "vue";
import Router from "vue-router";
import Home from "./pages/Home.vue";
import Main from "./pages/Main";
import NotFound from "./pages/NotFound";
import BankDetails from "./pages/BankDetails";
import SearchReservationPage from "./pages/SearchReservation";
// import PruebaPage from "./pages/Prueba";
import Compensation from "./components/compensation/Compensation";
import { useTokenStore } from "./stores/tokenStore";
import { useCountriesStore } from "./stores/countriesStore";
import { useLanguageStore } from "./stores/languageStore";
import { useUiStore } from "./stores/uiStore";
import DirectService from "./components/DirectService";
import compensationGlobalComponente from "./components/compensationselection/compensationGlobalComponente";
// import NewCompensationComponente from "./components/newCompensation/NewCompensationComponente";

import Expired from "./pages/Expired.vue";
// import MaintenanceScreen from "./pages/MaintenanceScreen.vue";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    // { path: "/success", component: Success },
    // { path: "/error", component: ErrorView },
    // { path: "/confirm", component: Confirmation },
    // { path: "/genericerror", component: ErrorView },
    // { path: "/alreadyaccepted", component: AlreadyAccepted },
    { path: "/expired", component: Expired },
    { path: "/rrcc/accept/:token", component: Main },
    { path: "/rrcc/acceptance/:token", component: Main },
    {
      name: "bank-details",
      path: "/rrcc/bankdetails/:token",
      component: BankDetails,
    },
    {
      name: "compensation",
      path: "/rrcc/compensation/:token",
      component: Compensation,
    },
    {
      path: "/",
      name: "home",
      component: Home,
    },
    {
      name: "reservation-search",
      path: "/cat/reservation-search/:token",
      component: SearchReservationPage,
    },
    {
      name: "direct-service",
      path: "/cat/direct-service",
      component: DirectService,
    },
    {
      name: "compensation-component",
      path: "/cat/selectcompensation/:token",
      component: compensationGlobalComponente,
    },
    // {
    //   name: "newcompensation-component",
    //   path: "/incident/compensation/:token",
    //   component: NewCompensationComponente,
    // },
    { path: "/*", component: NotFound },
  ],
});

router.beforeEach(async (to, from, next) => {
  if (to?.params?.token) {
    const excludedCountries = ["reservation-search"];
    const tokenStore = useTokenStore();
    //let lang = "ES";
    const countriesStore = useCountriesStore();
    const languageStore = useLanguageStore();
    const uiStore = useUiStore();
    uiStore.changeSpinner(true);
    tokenStore.decrypt(to?.params?.token);
    // if (to.name !== "compensation-component") lang = tokenStore.lang;
    languageStore.changeLanguage();
    if (
      !excludedCountries.includes(to.name) &&
      countriesStore.lastLanguage !== tokenStore?.lang
    ) {
      await countriesStore.fetchCountriesByLanguage(tokenStore?.lang);
    }
    uiStore.changeSpinner(false);
  }
  next();
});
export default router;
