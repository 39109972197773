<template>
  <v-app
    style="
      display: flex;
      flex-direction: column;
      height: 100%;
      min-height: 100vh;
    "
  >
    <Header />
    <v-main id="main-div">
      <router-view v-if="!uiStore.hasError" />
      <section v-else class="error-component">
        <ErrorView :title="uiStore.error.message" :hideLink="true" />
      </section>
    </v-main>
    <Footer />
    <IdleComponent v-if="uiStore.idleTimer" :time="uiStore.idleTimer" />
    <SpinnerComponent :show="uiStore.spinner" />
  </v-app>
</template>

<script>
import Header from "./components/common/Header.vue";
import Footer from "./components/common/Footer.vue";
import IdleComponent from "./components/IdleComponent.vue";
import SpinnerComponent from "./components/SpinnerComponent.vue";
import { mapStores } from "pinia";
import { useUiStore } from "@/stores/uiStore";
import ErrorView from "@/components/common/ErrorView";
export default {
  name: "App",
  computed: {
    ...mapStores(useUiStore),
  },
  components: {
    IdleComponent,
    Header,
    Footer,
    SpinnerComponent,
    ErrorView,
  },
};
</script>

<style lang="scss" scoped>
.error-component {
  display: grid;
  place-items: center;
  height: 100%;
}
</style>
