<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <ValidationProvider :rules="rules">
              <v-text-field
                v-model="dateFormatted"
                label="Date"
                hint="YYYY-MM-DD"
                persistent-hint
                prepend-icon="mdi-calendar"
                v-bind="attrs"
                @blur="date = parseDate(dateFormatted)"
                v-on="on"
                :error-messages="errors"
                readonly
                @keydown.prevent
              ></v-text-field>
            </ValidationProvider>
          </template>
          <v-date-picker
            v-model="innerValue"
            no-title
            @input="updateDateFormatted"
            @change="menu = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ValidationProvider } from "vee-validate";

import moment from "moment";

export default {
  components: {
    ValidationProvider,
  },
  data() {
    return {
      date: null,
      dateFormatted: null,
      menu: false,
      innerValue: null,
      errors: [],
    };
  },
  props: {
    // must be included in props
    value: {
      type: null,
    },
    rules: {
      type: [Object, String],
      default: "",
    },
  },

  computed: {
    computedDateFormatted() {
      return moment(this.date).format("YYYY-MM-DD");
    },
  },

  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      if (newVal) {
        this.dateFormatted = moment(newVal).format("YYYY-MM-DD");
        this.$emit("input", this.formatDate(newVal));
      } else {
        this.dateFormatted = null;
      }
    },
    value(newVal) {
      if (newVal) {
        this.date = this.parseDate(newVal);
        this.dateFormatted = moment(this.date).format("YYYY-MM-DD");
      } else {
        this.date = null;
        this.dateFormatted = null;
      }
    },
  },

  methods: {
    formatDate(date) {
      if (!date) return null;

      return moment.utc(date).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
    },
    parseDate(date) {
      if (!date) return null;

      return moment(date, "YYYY-MM-DDTHH:mm:ss.SSSZ").toISOString();
    },
    updateDateFormatted() {
      if (this.innerValue) {
        this.dateFormatted = moment(this.innerValue).format("YYYY-MM-DD");
      }
    },
    validate() {
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          this.errors = this.$validator.errors.items.map((item) => item.msg);
        }
      });
    },
  },
};
</script>
