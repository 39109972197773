<template>
  <div class="error-view">
    <img src="../../assets/illustration-error-comunitation-down-copy.png" />
    <div class="error-view-text-wrapper">
      <p v-if="!hidetitle" class="error-view-title">
        {{ $t("error_view.title") }}
      </p>
      <p
        class="error-view-subtitle"
        v-bind:class="{ 'normal-text': hidetitle }"
      >
        {{ title }}
      </p>
      <a
        v-if="!hidetitle && !hideLink"
        class="cta"
        :href="`mailto:${$crmEmail}`"
      >
        {{ $t("error_view.cta") }}
      </a>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
export default Vue.extend({
  props: ["title", "subtitle", "ctatext", "hidetitle", "hideLink"],
});
</script>

<style scoped>
.error-view {
  display: flex;
  flex-direction: row;
}
.error-view-text-wrapper {
  display: flex;
  flex-direction: column;
  max-width: 369px;
  text-align: left;
  margin-left: 32px;
}
.error-view-title {
  font-family: Aeradar-Bold, sans-serif;
  font-size: 24px;
  line-height: 32px;
  color: black;
}
.error-view-subtitle {
  font-family: Lato;
  font-size: 14px;
  line-height: 22px;
  color: #565656;
}
.cta {
  align-self: unset;
  font-family: Lato;
  text-decoration: none;
  padding: 12px 64px;
  margin-top: 34px;
  width: min-content;
  border-radius: 14px;
  background-color: #2d69e1;
  font-family: Lato;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.cta:hover {
  background-color: #6297ff;
}

.normal-text {
  font-size: 18px;
  margin-top: 2rem;
}
@media (max-width: 640px) {
  .error-view {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  img {
    width: 181px;
  }
  .cta {
    align-self: center;
  }
  .error-view-text-wrapper {
    margin-left: 0px;
    margin-top: 46px;
    text-align: center;
  }
}
</style>
