<template>
  <v-progress-circular
    :indeterminate="indeterminate"
    :size="size"
    :color="color"
    :width="width"
  ></v-progress-circular>
</template>
<script>
export default {
  name: "ProgessComponent",
  props: {
    size: { type: [Number, String] },
    indeterminate: { type: Boolean },
    color: { type: [Number, String], default: "primary" },
    width: { type: [Number, String] },
  },
};
</script>
