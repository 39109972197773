<template>
  <div class="textconfirm">
    <animation-success></animation-success>
    <div class="success-view-title">
      <p class="success-view-subtitle">{{ title }}</p>
      <p class="success-view-subtitle">{{ subtitle }}</p>
      <p class="success-view-subtitle">{{ ctatext }}</p>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
export default Vue.extend({
  name: "ConfirmationComponent",
  props: ["title", "subtitle", "ctatext", "hidetitle", "hideLink"],
});
</script>

<style scoped>
.success-view-title {
  font-family: Aeradar-Bold, sans-serif;
  font-size: 24px;
  line-height: 32px;
  margin-top: 26px;
  color: black;
}
.success-view-subtitle {
  font-family: Lato;
  font-size: 18px;
  line-height: 26px;
  color: #0d0d0d;
}
</style>
