<template>
  <div style="height: 100%">
    <component
      :caseId="caseId"
      :clientInfo="clientInfo"
      :token="token"
      :locale="locale"
      :is="component"
      :title="title"
      :mode="mode"
      :text="text"
      :hidetitle="hidetitle"
      v-on:acceptError="genericError"
      v-on:confirmed="confirmed"
      v-on:alreadyAccepted="alreadyAccepted"
      v-on:expired="expired"
      v-on:genericError="genericError"
      v-on:timeout="timeout"
    ></component>
    <!-- <spinner :show="loading" /> -->
  </div>
</template>

<script>
import Api from "@/api/api.js";
import Layout from "@/components/Layout";
import FeedBackView from "@/components/common/FeedbackView";
import Timeout from "@/components/TimeOut";
import { loadLanguageAsync } from "../i18n/translation";
import { mapActions } from "pinia";
import { useUiStore } from "@/stores/uiStore";
export default {
  name: "MainPage",
  components: {
    Layout,
    FeedBackView,
    Timeout,
  },
  data() {
    return {
      clientInfo: {},
      token: "",
      locale: "en",
      title: null,
      text: null,
      mode: "",
      hidetitle: false,
      component: null,
      caseId: null,
    };
  },
  created() {
    if (this.$route.params.token == "DEBUG") {
      this.changeSpinner(false);
      return;
    }
    try {
      this.load();
    } catch (err) {
      this.changeSpinner(false);
      this.genericError();
    }
  },
  methods: {
    ...mapActions(useUiStore, ["changeSpinner"]),
    load() {
      const enc = this.$route.params.token;

      const self = this;
      const json = window.atob(enc); // .split("'").join('"');
      const req = JSON.parse(json);
      this.token = req.token;
      this.caseId = req.caseId;
      const language = req.language.toLowerCase();
      // this.$i18n.locale = language;
      loadLanguageAsync(language);
      this.locale = language;
      this.changeSpinner(true);

      Api.accept(self.caseId, self.token)
        .then(function (resp) {
          self.changeSpinner(false);
          let statusCode = resp.data.statusCode;
          if (!statusCode) {
            statusCode = resp.data.StatusCode;
          }

          if (
            !Object.prototype.hasOwnProperty.call(resp?.data, "statusCode") ||
            statusCode == null
          ) {
            self.genericError();
            return;
          }
          if (statusCode == Api.AcceptCompensation.ACCEPTED_SUCCESS) {
            self.confirmed();
          } else if (statusCode == Api.AcceptCompensation.ALREADY_ACCEPTED) {
            self.alreadyAccepted();
          } else if (statusCode == Api.AcceptCompensation.EXPIRED) {
            self.expired();
          } else {
            self.genericError();
          }
        })
        .catch((err) => {
          this.changeSpinner(false);
          if (err.code == "ECONNABORTED") {
            this.timeout();
            return;
          }
          this.genericError();
        });
    },
    confirmed() {
      this.title = this.$t("text.ack");
      this.text = null;
      this.mode = "success";
      this.component = FeedBackView;
    },
    alreadyAccepted() {
      this.title = this.$t("text.accepted");
      this.text = null;
      this.mode = "error";
      this.hidetitle = true;
      this.component = FeedBackView;
    },
    expired() {
      this.title = this.$t("text.expired");
      this.text = null;
      this.mode = "error";
      this.component = FeedBackView;
    },
    genericError() {
      this.title = this.$t("bankDetails.supportContact", {
        email: process.env.VUE_APP_CRMEMAIL,
      });
      this.text = null;
      this.mode = "error";
      this.component = FeedBackView;
    },
    timeout() {
      this.component = Timeout;
    },
  },
};
</script>
