import axios from "axios";

axios.defaults.baseURL = process.env.VUE_APP_APIURL;
axios.defaults.timeout = parseInt(process.env.VUE_APP_APITIMEOUT);
const baseUri = "prweb/PRRestService/AECSRestService/v1/Overbooking/";
const overbookingUri = baseUri + "OverbookingDetails";
const acceptUri = baseUri + "AcceptCompensation";
const bankDetailsUri =
  "prweb/PRRestService/AECSRestService/v1/Incident/BankDetails";
const countriesUri = `${process.env.VUE_APP_APIURL_BAGGAGE_SERVICE}countrieslist`;
const bankdetails = `${process.env.VUE_APP_APIURL_BAGGAGE_SERVICE}bankdetails`;
const compensationUri =
  "prweb/PRRestService/AECSRestService/v1/Claim/SelectCompensation";
const overbookingDetailsCodes = {
  PENDING_ACCEPT: "1",
  ALREADY_ACCEPTED: "2",
  EXPIRED: "3",
};
const acceptCompensationCodes = {
  ACCEPTED_SUCCESS: "0",
  ALREADY_ACCEPTED: "2",
  EXPIRED: "3",
};
const incidentUri =
  "prweb/PRRestService/AECSRestService/v1/Incident/SelectCompensation";

// TO AXIOS
function putAcceptance(requestData) {
  return axios.put(acceptUri, requestData, {
    "Content-Type": "application/json",
  });
  /* return new Promise(function(resolve, reject) {
    try {
      var xhttp = new XMLHttpRequest();
      xhttp.onreadystatechange = function() {
        if (xhttp.readyState == 4 && xhttp.status == 200) {

          const data = JSON.parse(xhttp.responseText);
          resolve({ data, status: xhttp.status });
        } else if (xhttp.readyState == 4 && xhttp.status != 0) {
          reject({ code: xhttp.status });
        }
      };
      xhttp.open("PUT", process.env.VUE_APP_APIURL + acceptUri, true);
      xhttp.timeout =   parseInt(process.env.VUE_APP_APITIMEOUT);// process.env.VUE_APP_APITIMEOUT
       try{
          xhttp.ontimeout(function(){ reject({})})
       }catch(err){
         xhttp.addEventListener("timeout",function(){ reject({})})

       }
      xhttp.send(JSON.stringify(requestData));
    } catch (err) {
      reject({ code: 0 });
    }
  }); */
}

function putBankDetailsXHR(requestData) {
  return axios.put(bankDetailsUri, requestData, {
    "Content-Type": "application/json",
  });
  /*
  return new Promise(function(resolve, reject) {
    try {
      var xhttp = new XMLHttpRequest();

      xhttp.onreadystatechange = function() {
        if (xhttp.readyState == 4 && xhttp.status == 200) {

          const data = JSON.parse(xhttp.responseText);
          resolve({ data, status: xhttp.status });
        } else if (xhttp.readyState == 4 && xhttp.status != 0) {
          reject({ code: xhttp.status });
        }
      };
        xhttp.timeout =   parseInt(process.env.VUE_APP_APITIMEOUT);// process.env.VUE_APP_APITIMEOUT
       try{
          xhttp.ontimeout(function(){ reject({})})
       }catch(err){
         xhttp.addEventListener("timeout",function(){ reject({})})

       }
      xhttp.open("PUT", process.env.VUE_APP_APIURL + bankDetailsUri, true);
      //    xhttp.setRequestHeader('token',token);
      //    xhttp.setRequestHeader('caseId',caseId);

      xhttp.send(JSON.stringify(requestData));

    } catch (err) {
      reject({ code: 0 });
    } }); */
}

export default {
  OverbookingDetails: overbookingDetailsCodes,
  AcceptCompensation: acceptCompensationCodes,
  loadCompensationOptions(caseId, token) {
    return axios.get(compensationUri, {
      params: {
        caseId,
        token,
      },
    });
  },
  postCompensation(request) {
    return axios.post(compensationUri, request, {
      "Content-Type": "application/json",
    });
    // return new Promise((resolve) => {
    //   resolve(() => {
    //     console.log(request);
    //   });
    // });
  },
  dynamicForm(country, language) {
    const url = bankdetails + `?country=${country}&lang=${language}`;
    return axios
      .get(url, null, {
        "Content-Type": "application/json",
      })
      .catch((err) => console.error(err));
  },
  bankDetailsSubmit(request, token, caseId) {
    return putBankDetailsXHR(request, token, caseId);
    // return new Promise((resolve) => {
    //   resolve(() => {
    //     console.log(caseId, token, putBankDetailsXHR);
    //   });
    // });
  },
  getGoldenId({ param, surnames }) {
    return axios.get(
      `${process.env.VUE_APP_APIURL_BAGGAGE_SERVICE}idgolden?${param}&name=${surnames}`
    );
  },
  getBaggageForm(pir, token) {
    return axios.get(
      `${process.env.VUE_APP_APIURL_BAGGAGE_SERVICE}baggageform/${pir}/${token}`
    );
  },
  getCompensationForm(id) {
    return axios.get(
      `${process.env.VUE_APP_APIURL_BAGGAGE_SERVICE}proposals/${id}`
    );
  },

  getNewCompensation(caseId, token) {
    return axios.get(incidentUri + `?pyID=${caseId}&token=${token}`);
  },
  sendCompensationData(formData) {
    console.log("sendCompensationData", formData);
    return axios.post(incidentUri, formData);
  },

  async sendFormBaggageData(formData, token) {
    const response = await axios.request({
      url: `${process.env.VUE_APP_APIURL_BAGGAGE_SERVICE}baggageform?token=${token}`,
      method: "PUT",
      baseURL: process.env.VUE_APP_APIURL_BAGGAGE_SERVICE,
      data: formData,
    });
    return response.data;
  },
  async sendFormBankData(formData) {
    const response = await axios.request({
      url: `${process.env.VUE_APP_APIURL_BAGGAGE_SERVICE}bankdetails`,
      method: "PUT",
      baseURL: process.env.VUE_APP_APIURL_BAGGAGE_SERVICE,
      data: formData,
    });
    return response.data;
  },
  async sendFormCompensationData(formData, id) {
    const response = await axios.request({
      url: `${process.env.VUE_APP_APIURL_BAGGAGE_SERVICE}proposals/${id}`,
      method: "POST",
      baseURL: process.env.VUE_APP_APIURL_BAGGAGE_SERVICE,
      data: formData,
    });
    return response.data;
  },
  async sendCompensationSelected(proposalObj, id) {
    const response = await axios.request({
      url: `${process.env.VUE_APP_APIURL_BAGGAGE_SERVICE}proposals/${id}`,
      method: "POST",
      baseURL: process.env.VUE_APP_APIURL_BAGGAGE_SERVICE,
      data: proposalObj,
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  },
  getBankDetails(lang, country) {
    return axios.get(
      `${process.env.VUE_APP_APIURL_BAGGAGE_SERVICE}bankdetails?lang=${lang}&country=${country}`
    );
  },

  bankDetailsLoad(caseId, token) {
    return axios.get(bankDetailsUri, {
      params: {
        caseId: caseId,
        token: token,
      },
    });
    // return new Promise((resolve) => {
    //   resolve(() => {
    //    console.log(caseId, token);
    //   });
    // });
  },
  getCountries(lang) {
    return axios.get(countriesUri, {
      params: {
        lang,
      },
    });
  },
  load(caseId, token) {
    return axios.get(overbookingUri, {
      params: {
        caseId,
        token,
      },
    });
  },
  accept(caseId, token) {
    return putAcceptance({
      caseId,
      token,
    });
  },
};
