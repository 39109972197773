<template>
  <div>
    <section></section>
    <!-- Sección para mostrar un mensaje de confirmación si el formulario se envió con éxito -->
    <section
      v-if="submittedSuccessfully && pageStatus == PAGESTATUS_SUCCESS"
      class="alert-component"
    >
      <ConfirmationComponent
        :title="$t('directService.confirmation-title')"
        :subtitle="$t('directService.confirmation-subtitle')"
        :ctatext="$t('directService.confirmation-text')"
      ></ConfirmationComponent>
    </section>
    <!-- Sección principal del formulario -->
    <div
      v-if="pageStatus == PAGESTATUS_FORM"
      class="main-background direct-service"
    >
      <div class="main-header">
        <h1>{{ $t("directService.title") }}</h1>
      </div>
      <v-container class="servicio-directo">
        <div class="section-heading mt-3">
          <h2>{{ $t("directService.title1") }}</h2>
          <p class="ae-description">
            {{ $t("directService.informationFields") }}
          </p>
        </div>
        <!-- Componente de pasos verticales -->
        <v-stepper v-model="actualStep" vertical class="elevation-0">
          <v-stepper-step step="1" editable>
            {{ $t("directService.customerData.title") }}
          </v-stepper-step>
          <!-- Contenido del primer paso: Formulario del cliente -->
          <v-stepper-content step="1">
            <ValidationObserver ref="clientForm" tag="form" v-slot="{ valid }">
              <ClientForm
                ref="clientFormulario"
                :name="name"
                :surname="surname"
              />
              <!-- Mostrar mensaje de error si hay errores de validación -->
              <div v-if="showError && !valid" class="error-message">
                <img
                  class="alert-error"
                  src="@/assets/alert.svg"
                  alt="Custom card Img"
                />{{ $t("directService.alert-error") }}
              </div>
              <div class="py-3">
                <v-btn
                  class="my-0 mx-auto d-block"
                  color="primary"
                  fab
                  @click="goToNextStep(valid)"
                >
                  <v-icon dark>mdi-arrow-down</v-icon>
                </v-btn>
              </div>
            </ValidationObserver>
          </v-stepper-content>

          <v-stepper-step step="2">
            {{ $t("directService.suitcaseData.title") }}
          </v-stepper-step>
          <!-- Contenido del segundo paso: Formulario de la maleta -->
          <v-stepper-content step="2" complete>
            <ValidationObserver
              ref="suitcaseForm"
              tag="form"
              v-slot="{ valid: isStep2Valid }"
            >
              <div v-if="baggageForm && suitCases.length">
                <SuitcaseForm
                  ref="suitcaseFormulario"
                  contactId
                  :suitCases="suitCases"
                />
              </div>
              <!-- Checkbox para aceptar las condiciones del servicio -->
              <VCheckboxWithValidation
                class="checkbox-style"
                v-model="conditionsCheck"
                :label="`Acepto las condiciones del Servicio,
      que establecen la sustitución de una maleta de similares características a las especificadas en este formulario,
      sin garantizar medidas exactas, marca, modelo o color.`"
              />
              <!-- Mostrar mensaje de error si hay errores de validación -->
              <div v-if="showError && !isStep2Valid" class="error-message">
                <img
                  class="alert-error"
                  src="@/assets/alert.svg"
                  alt="Custom card Img"
                />{{ $t("directService.alert-error") }}
              </div>
              <div
                v-if="!isStep2Valid || !conditionsCheck"
                class="validation-message"
              >
                Todos los datos obligatorios deben estar informados
              </div>
              <!-- Botón para enviar el formulario -->
              <div class="py-3">
                <v-btn
                  class="my-0 mx-auto d-block"
                  color="primary"
                  :disabled="!isStep2Valid || !conditionsCheck"
                  @click="submitFormData"
                  dark
                  large
                >
                  <span class="mr-1">Enviar</span>
                  <v-icon dark>mdi-send</v-icon>
                </v-btn>
              </div>
            </ValidationObserver>
          </v-stepper-content>
        </v-stepper>
      </v-container>
    </div>
  </div>
</template>
<script>
import { ValidationObserver } from "vee-validate";
import SuitcaseForm from "./SuitcaseForm.vue";
import ClientForm from "./ClientForm.vue";
import VCheckboxWithValidation from "@/components/dynamic/VCheckboxWithValidation.vue";
import { mapStores } from "pinia";
import { useTokenStore } from "@/stores/tokenStore";
import { useUiStore } from "@/stores/uiStore";
import Api from "@/api/api.js";
import ConfirmationComponent from "./ConfirmationComponent.vue";
import { parsePhoneNumber } from "libphonenumber-js";

export default {
  name: "DirectService",
  components: {
    ValidationObserver,
    SuitcaseForm,
    ClientForm,
    VCheckboxWithValidation,
    ConfirmationComponent,
  },
  data: () => ({
    defaultValues: null,
    valid: true,
    config: null,
    actualStep: 1,
    steps: 2,
    pir: null,
    error: false,
    contactId: null,
    suitCases: [],
    conditionsCheck: false,
    baggageForm: false,
    submittedSuccessfully: false,
    caseId: null,
    PAGESTATUS_FORM: 0,
    PAGESTATUS_SUCCESS: 1,
    PAGESTATUS_LOADERROR: 2,
    // PAGESTATUS_CUSTOMERROR: 4,
    // PAGESTATUS_EXPIRED: 7,
    pageStatus: null,
    name: null,
    surname: null,
    showError: false,
  }),
  watch: {
    // Observar cambios en las maletas y actualizarlas
    suitCases(t) {
      this.suitCase = t;
    },
    // Observar cambios en los pasos y actualizar el paso actual si es necesario
    steps(val) {
      if (this.actualStep > val) {
        this.actualStep = val;
      }
    },
    // Observar cambios en la propiedad editable del paso y restablecer el paso actual a 1 si es true
    "step.editable": function (newVal) {
      // Si 'editable' es true, establece 'actualStep' a 1
      if (newVal) {
        this.actualStep = 1;
      }
    },
  },
  computed: {
    ...mapStores(useTokenStore, useUiStore),
    // Computar si el token ha expirado
    isExpiredToken() {
      return this.tokenStore?.isExpiredToken;
    },
  },
  created() {
    // if (!this.tokenStore.number) {
    //   this.error = true;
    // }
    // this.pageStatus = this.PAGESTATUS_EXPIRED;
    // Obtener los parámetros de la ruta y asignarlos al nombre y apellido si existen
    const { name, surname } = this.$route.params;
    if (name && surname) {
      this.name = name;
      this.surname = surname;
    }
    // Verificar si el token ha expirado y manejar el error
    if (this.isExpiredToken) {
      this.uiStore.setError({
        message: this.$t("searchReservationComponent.tokenExpired"),
      });
    } else {
      this.pir = this.tokenStore.number;
      this.getData();
    }
  },
  methods: {
    // Método para obtener los datos del formulario de equipaje
    async getData() {
      this.uiStore.changeSpinner(true);
      try {
        const bf = await Api.getBaggageForm(
          this.tokenStore.number,
          this.tokenStore.pyId
        );
        this.contactId = bf.data.PIR.Passenger.ContactId;
        this.tokenStore.caseId = bf.data.pzInsKey;
        this.suitCases = bf.data.PIR.Baggage.map((e) => e.TAGBackup);
        this.tokenStore.idSuitCase = bf.data.PIR.Baggage.map((e) => ({
          tagBackup: e.TAGBackup,
          tagNumber: e.TAGNumber,
        }));
        this.baggageForm = true;
        this.pageStatus = this.PAGESTATUS_FORM;
        this.uiStore.changeSpinner(false);
      } catch (e) {
        if (e.response.status) {
          this.setCustomError({ errorCode: e.response.status });
        }
        this.uiStore.changeSpinner(false);
      }
    },
    // Método para establecer un error personalizado
    setCustomError({ errorCode, customError = "errorsSap.TPY-0100" }) {
      let error = "";
      if (!errorCode && customError) {
        error = customError;
      }
      if (errorCode) {
        let status = errorCode.toString();
        if (status.match(/^5\d{2}$/)) status = "5XX";
        error = `error.error${status}`;
        if (!this.$te(error)) error = customError;
      }
      this.uiStore.setError({
        message: this.$t(error),
      });
    },
    // Método para enviar los datos del formulario
    async submitFormData() {
      const dataClient = await this.$refs.clientFormulario.dataClientForSend;
      const dataSuitcase = await this.$refs.suitcaseFormulario
        .suitcaseDataForSend;
      const isValid =
        (await this.$refs.clientForm.validate()) &&
        // (await this.$refs.clientForm.countryConfig.name) !== "OTROS" &&
        (await this.$refs.suitcaseForm.validate());
      if (!isValid) return;
      const formData = {
        pzInsKey: this.tokenStore.caseId,
        PIR: {
          Number: this.pir,
          Baggage: Object.keys(dataSuitcase).map((d) => {
            return {
              TAGNumber: this.tokenStore.idSuitCase.find(
                (i) => i.tagBackup === d
              )?.tagNumber,
              TAGBackup: d,
              SubstitutionsFormData: {
                Brand: dataSuitcase[d]["brand"],
                Type: dataSuitcase[d]["model"],
                MaterialElement: {
                  pyValue: dataSuitcase[d]["introducedMaterial"],
                },
                Colour: dataSuitcase[d]["introducedColor"],
                Long: dataSuitcase[d]["long"],
                Width: dataSuitcase[d]["width"],
                Height: dataSuitcase[d]["high"],
                Wheels: dataSuitcase[d]["selectedWheels"],
                Description: dataSuitcase[d]["damageDescription"],
                BagDetails: dataSuitcase[d]["additionalDescription"],
                ExtendableHandles: dataSuitcase[d]["extendableHandle"],
                Closures: dataSuitcase[d]["hasClosures"],
                Zipper: dataSuitcase[d]["hasZippers"],
              },
            };
          }),
        },
        Substitutions: {
          AcceptConditions: this.conditionsCheck,
          IsTempAddress: dataClient["temporalAddress"],
          DeliveryFromDate: dataClient["fromdate"],
          DeliveryToDate: dataClient["todate"],
          Remarks: [
            {
              Value: dataClient["observations"],
            },
          ],
          Passenger: {
            pyID: this.tokenStore.idGolden,
            Name: dataClient["name"],
            Surnames: dataClient["secondName"],
            DocumentNumber: dataClient["documentation"],
            Phones: [
              {
                PhonePrefix: "+" + dataClient["mobilePrefixSelected"],
                pyPhoneNumber: parsePhoneNumber(dataClient["mobile"])
                  .nationalNumber,
                PhoneTypeList: "MOVIL",
              },
              {
                PhonePrefix: dataClient["telephonePrefixSelected"]
                  ? "+" + dataClient["telephonePrefixSelected"]
                  : null,
                pyPhoneNumber: dataClient["telephone"]
                  ? parsePhoneNumber(dataClient["telephone"]).nationalNumber
                  : null,
                PhoneTypeList: "FIJO",
              },
            ],
            Addresses: [
              {
                Country: {
                  countryname: dataClient["country"].name,
                  countryCode: dataClient["country"].value,
                },
                pyState: dataClient["provinces"],
                pyCity: dataClient["town"],
                pyStreet: dataClient["street"],
                Number: dataClient["number"],
                pyLabel: dataClient["floor"],
                pyPostalCode: dataClient["postalCode"],
              },
            ],
          },
        },
      };
      try {
        this.uiStore.changeSpinner(true);
        await Api.sendFormBaggageData(formData, this.tokenStore.pyId);
        this.submittedSuccessfully = true;
        this.pageStatus = this.PAGESTATUS_SUCCESS;
        this.uiStore.errorMsg = this.$t("errorsSap.TPY-0103");
        this.uiStore.changeSpinner(false);
      } catch (error) {
        if (error?.response?.status === 423) {
          this.setCustomError({
            customError: this.$t("errorsSap.TPY-0104"),
          });
        } else if (error?.response?.status === 406) {
          this.setCustomError({
            customError: this.$t("errorsSap.TPY-0105"),
          });
        } else if (error) {
          this.setCustomError({
            errorCode: error.response.status,
            customError: "directService.error",
          });
        }
      }
      this.uiStore.changeSpinner(false);
    },
    nextStep(n) {
      if (n === this.steps) {
        this.actualStep = 1;
      } else {
        this.actualStep = n + 1;
      }
    },
    /**
     * Método para manejar la navegación entre los pasos de un proceso multietapa.
     * @param {boolean} valid - Indica si el formulario del paso actual es válido o no.
     * Comprobar si el formulario es válido antes de pasar al siguiente paso.
     * Si el formulario es válido, se pasa al siguiente paso.
     * Si el formulario no es válido, se muestra un mensaje de error.
     */
    goToNextStep(valid) {
      if (valid) {
        if (this.actualStep === 1) {
          this.actualStep = 2;
          this.showError = false;
        } else if (this.actualStep === 2) {
          this.submitFormData();
          this.showError = false;
        }
      } else {
        this.showError = true;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.main-background {
  background-color: #fafafa;
}
.servicio-directo {
  margin: 0 auto;
  @media (min-width: 960px) {
    max-width: 50%;
  }

  .v-stepper {
    background-color: #fafafa;
  }
  .v-messages__message {
    line-height: 12px;
    word-break: normal;
    overflow-wrap: normal;
    word-wrap: normal;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: inherit;
  }
  .checkbox-style {
    font-size: 14px;
    margin-bottom: 16px;
    font-family: Lato Roboto;
    font-size: 14px;
    color: #565656;
  }
}
.alert-component {
  width: fit-content;
  box-shadow: 0px 5px 10px rgba(204, 204, 204, 0.3);
  padding: 50px;
  border-radius: 20px;
  margin: 0 auto;
}
.main-header {
  height: 150px;
  font-size: 25px;
  display: flex;
  justify-content: center;
  align-items: baseline;
  background-color: #2d69e1;
  h1 {
    font-family: AERadar;
    color: white;
    @media (max-width: 920px) {
      font-size: 40px;
    }
  }
}
.section-heading {
  color: #0f2367;
  font-family: AERadar;
  font-size: 16px;
  padding-bottom: 15px;
}
.ae-description {
  font-size: 14px;
  font-family: Lato, -apple-system, blinkmacsystemfont, Segoe UI, Roboto,
    Helvetica Neue, arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    Segoe UI Symbol, icomoon;
  margin-bottom: 16px;
  text-decoration: none;
  color: #565656;
}
.error-message {
  background: transparent;
  border: none;
  color: red;
  display: flow;
  font-size: 14px;
  line-height: 1.2;
  margin: -11px 0 0;
  display: flex;
  justify-content: center;
  align-items: baseline;
}
.alert-error {
  width: 25px;
}
.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: rgba(221, 221, 221, 2.5) !important;
}
.validation-message {
  display: flex;
  font-size: 14px;
  font-family: Lato, -apple-system, blinkmacsystemfont, Segoe UI, Roboto,
    Helvetica Neue, arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    Segoe UI Symbol, icomoon;
  font-weight: 700;
  color: #565656;
  align-items: center;
  justify-content: center;
}
</style>
