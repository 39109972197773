<template>
  <ValidationObserver ref="obs" v-slot="{ valid }">
    <v-card elevation="1" outlined class="search-ticket-container">
      <v-card-text class="pa-0">
        <div class="d-block d-sm-flex">
          <section class="pa-6">
            <h2 class="header">{{ $t("searchReservationComponent.title") }}</h2>
            <v-radio-group v-model="radioOpt" row @change="resetValidations">
              <v-radio
                :label="$t('searchReservationComponent.locator')"
                :value="1"
                @change="setLocata"
              ></v-radio>
              <!-- <v-radio
                label="Localizador y datos del vuelo"
                :value="2"
              ></v-radio> -->
              <v-radio
                :label="$t('searchReservationComponent.ticketNumber')"
                :value="3"
                @change="setTicket"
              ></v-radio>
              <!-- <v-radio
                :label="$t('searchReservationComponent.tagNumber')"
                :value="4"
              ></v-radio> -->
            </v-radio-group>
            <v-form>
              <VTextFieldWithValidation
                v-if="radioOpt === 1 || radioOpt === 2"
                :rules="{ required: true, alpha_num: true, max: 6, min: 6 }"
                :counter="6"
                label="Localizador"
                v-model="locator"
              />
              <VTextFieldWithValidation
                v-if="radioOpt === 3"
                :rules="{
                  required: radioOpt === 3,
                  num_hyphen: true,
                }"
                :label="$t('searchReservationComponent.ticketNumber')"
                v-model.trim="ticketNumber"
              />

              <VTextFieldWithValidation
                v-if="radioOpt === 4"
                :rules="{ required: radioOpt === 4 }"
                :label="$t('searchReservationComponent.tagNumber')"
                v-model="tagNumber"
              />

              <span @click="dialog = true" class="ticket-dialog-activator">
                <v-icon color="primary"> mdi-help-circle </v-icon>
                {{ $t("searchReservationComponent.ticketDialog") }}
              </span>
              <!-- <div class="d-flex gap-2" v-if="radioOpt === 2">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on }">
                    <VTextFieldWithValidation
                      :rules="{ required: radioOpt === 2 }"
                      label="Fecha de salida del vuelo"
                      class="w-50"
                      readonly
                      append-icon="mdi-calendar"
                      v-model="date"
                      v-on="on"
                      :hide-details="true"
                      :hide-validations="true"
                    />
                  </template>
                  <v-date-picker
                    color="primary"
                    v-model="date"
                    no-title
                    scrollable
                    :locale="languageStore.locale"
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu = false">
                      Cancelar
                    </v-btn>
                    <v-btn text color="primary" @click="$refs.menu.save(date)">
                      Guardar
                    </v-btn>
                  </v-date-picker>
                </v-menu>
                <VTextFieldWithValidation
                  :rules="{ required: radioOpt === 2 }"
                  label="Ciudad de origen"
                  class="w-50"
                  v-model="originCity"
                  :hide-validations="true"
                />
              </div> -->
              <VTextFieldWithValidation
                v-if="radioOpt === 1 || radioOpt === 3 || radioOpt === 4"
                :rules="{
                  required: radioOpt === 1 || radioOpt === 3 || radioOpt === 4,
                  alpha_spaces: true,
                }"
                :label="$t('searchReservationComponent.surnames')"
                v-model="surnames"
                :hide-validations="true"
              />
              <v-btn
                class="send-btn elevation-0"
                :class="{ 'mx-auto d-block w-100': $vuetify.breakpoint.xs }"
                color="primary"
                @click="sendBtn"
                :disabled="!valid"
              >
                {{ $t("searchReservationComponent.searchBtn") }}
              </v-btn>
            </v-form>
          </section>
          <aside
            class="d-flex justify-center"
            :class="{
              'rounded-r-lg': $vuetify.breakpoint.smAndUp,
              'rounded-b-lg': $vuetify.breakpoint.xs,
            }"
          >
            <img
              class="svg-icon"
              src="../assets/suma-plane.svg"
              height="132px"
              width="234px"
            />
            <div class="d-flex information">
              <h2>{{ $t("searchReservationComponent.aside.registered") }}</h2>
              <p class="text">
                {{ $t("searchReservationComponent.aside.sumaInfo") }}
              </p>
              <!-- <v-btn text class="suma-btn" @click="sumaBtn">
                ¡Quiero ser SUMA!
              </v-btn> -->
            </div>
          </aside>
        </div>
      </v-card-text>
    </v-card>

    <v-dialog v-model="dialog" persistent width="auto">
      <v-card
        v-if="radioOpt === 1 || radioOpt === 2"
        class="ticket-number-dialog"
      >
        <p>
          {{ $t("searchReservationComponent.locatorDialog.info") }}
        </p>
        <p>
          {{ $t("searchReservationComponent.locatorDialog.explanation") }}
        </p>
        <p>
          <small>
            {{ $t("searchReservationComponent.locatorDialog.example") }}:
          </small>
        </p>
        <div class="ticket-number">USR8B2</div>
        <v-card-actions class="justify-center">
          <v-btn
            @click="dialog = false"
            class="ticket-btn"
            color="primary"
            text
          >
            {{ $t("searchReservationComponent.ticketNumberDialog.understood") }}
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-if="radioOpt === 3" class="ticket-number-dialog">
        <p>
          {{ $t("searchReservationComponent.ticketNumberDialog.info") }}
        </p>
        <p>
          {{ $t("searchReservationComponent.ticketNumberDialog.explanation") }}
        </p>
        <p>
          <small>
            {{ $t("searchReservationComponent.ticketNumberDialog.example") }}:
          </small>
        </p>
        <div class="ticket-number">9862413687992</div>
        <v-card-actions class="justify-center">
          <v-btn
            @click="dialog = false"
            class="ticket-btn"
            color="primary"
            text
          >
            {{ $t("searchReservationComponent.ticketNumberDialog.understood") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <SnackBarComponent :snackbar="error" @handlerClick="error = false" />
    <div>
      <v-dialog v-model="showPopup" max-width="500px">
        <v-card>
          <v-card-title class="header">Seleccionar pasajeros</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-radio-group v-model="selectedPassengerId">
              <v-list>
                <v-list-item v-for="(passenger, id) in passengers" :key="id">
                  <v-list-item-action>
                    <v-radio :value="passenger"></v-radio>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title
                      >{{ passenger.passengerName }}
                      {{ passenger.passengerSurname }}</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-radio-group>
          </v-card-text>
          <v-card-actions>
            <v-btn
              class="send-btn elevation-1"
              :class="{ 'mx-auto d-block w-100': $vuetify.breakpoint.xs }"
              color="blue-darken-1"
              @click="closePopup"
              >Cerrar</v-btn
            >
            <v-btn
              class="send-btn elevation-0"
              style="margin-left: 67px"
              :class="{ 'mx-auto d-block w-100': $vuetify.breakpoint.xs }"
              color="primary"
              :disabled="selectedPassengerId === null"
              @click="continueWithPassenger"
              >Continuar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import VTextFieldWithValidation from "@/components/dynamic/VTextFieldWithValidation.vue";
import SnackBarComponent from "@/components/SnackBarComponent.vue";
import { useTokenStore } from "@/stores/tokenStore";
import { mapStores, mapActions } from "pinia";
import Api from "@/api/api";
import { useUiStore } from "@/stores/uiStore";
export default {
  name: "SearchReservationComponent",
  data: () => ({
    dataForSend: {},
    date: null,
    dialog: false,
    flightDate: null,
    locator: null,
    menu: false,
    originCity: null,
    radioOpt: 3,
    surnames: null,
    tagNumber: null,
    ticketNumber: null,
    valid: true,
    error: false,
    param: "ticket",
    passengerList: true,
    showPopup: false,
    selectedPassengerId: null,
    passengers: [],
  }),
  components: {
    SnackBarComponent,
    ValidationObserver,
    VTextFieldWithValidation,
  },
  computed: {
    ...mapStores(useTokenStore),
  },
  methods: {
    openPopup() {
      this.showPopup = true;
    },
    closePopup() {
      this.showPopup = false;
      this.changeSpinner(false);
    },
    continueWithPassenger() {
      const selectedPassenger = this.passengers.find(
        (passenger) => passenger.idGolden === this.selectedPassengerId.idGolden
      );
      if (selectedPassenger) {
        this.tokenStore.$state = { idGolden: selectedPassenger.idGolden };
        this.nextForm({
          params: {
            name: selectedPassenger.passengerName,
            surname: selectedPassenger.passengerSurname,
          },
        });
        this.closePopup();
      }
    },
    ...mapActions(useUiStore, ["changeSpinner"]),
    resetValidations() {
      this.$refs.obs.reset();
    },
    setLocata() {
      this.param = "locata";
    },
    setTicket() {
      this.param = "ticket";
    },
    nextForm({ params }) {
      this.$router.push({
        name: "direct-service",
        params,
      });
    },
    async sendBtn() {
      // eslint-disable-next-line no-useless-escape
      const clearInputRegex = /[&\/\\#, +()$~%.'":*?<>{}]/g;
      //this.ticketNumber = this.ticketNumber.substring(0, 13);
      this.changeSpinner(true);
      const isValid = await this.$refs.obs.validate();
      if (!isValid) return;
      this.dataForSend = {
        surnames: this.surnames,
        pyId: this.tokenStore?.pyId,
        number: this.tokenStore?.number,
      };
      if (this.radioOpt == 1) {
        this.dataForSend["locator"] = this.locator.replace(clearInputRegex, "");
      }
      if (this.radioOpt == 3) {
        this.dataForSend["ticketNumber"] = this.ticketNumber.replace(
          clearInputRegex,
          ""
        );
      }
      // if (this.radioOpt == 4) {
      //   this.dataForSend["tagNumber"] = this.tagNumber;
      // }
      try {
        const { data } = await Api.getGoldenId({
          surnames: this.surnames,
          param:
            this.param == "ticket"
              ? `ticket=${this.ticketNumber.substring(0, 13)}`
              : `locata=${this.locator}`,
        });
        // data.results.push({
        //   passengerName: "MARIA",
        //   passengerSurname: "ROSALES TRUJILLO",
        //   idGolden: "5221431",
        // });
        if (data.results.length === 1) {
          this.tokenStore.$state = { idGolden: data.results[0].idGolden };
          this.nextForm({
            params: {
              name: data.results[0].passengerName,
              surname: data.results[0].passengerSurname,
            },
          });
          this.changeSpinner(false);
        } else if (data.results.length > 1) {
          this.passengers = data.results;
          this.showPopup = true;
          this.changeSpinner(false);
        }
      } catch (error) {
        this.error = true;
        this.changeSpinner(false);
      }
    },
  },
};
</script>
<style scoped lang="scss">
.w-50 {
  width: 50%;
}
.gap-2 {
  gap: 2rem;
}
.v-card {
  background-color: #fafafa;
  border-radius: 10px;
}
.header {
  color: #000;
  font-size: 1.5rem;
  font: 700 1.25rem/1.2 AERadar;
  margin-bottom: 26px;
}

section {
  width: 100%;
}
aside {
  align-items: center;
  background-color: #2d69e1;
  // border-radius: 0 10px 10px 0;
  color: #fff;
  flex-basis: 45%;
  flex-direction: column;
  .information {
    align-items: center;
    flex-direction: column;
    margin: 0 24px;
    padding: 24px;
    h2 {
      font: 700 1rem/1.5 Lato, -apple-system, blinkmacsystemfont, Segoe UI,
        Roboto, Helvetica Neue, arial, sans-serif, "Apple Color Emoji",
        "Segoe UI Emoji", Segoe UI Symbol, icomoon;
    }
    .text {
      text-align: center;
      font: 0.75rem/1.5 Lato, -apple-system, blinkmacsystemfont, Segoe UI,
        Roboto, Helvetica Neue, arial, sans-serif, "Apple Color Emoji",
        "Segoe UI Emoji", Segoe UI Symbol, icomoon;
    }
  }
  .svg-icon {
    height: 132px;
    margin: 0 0 20px;
    width: 234px;
  }
}

.send-btn {
  border-radius: 14px;
  height: 48px !important;
  width: 200px;
}

.w-100 {
  width: 100%;
}

.ticket-btn {
  text-transform: none;
}
.suma-btn {
  color: white;
  font-size: 14px;
  text-transform: none;
}
.ticket-number-dialog {
  border-radius: 10px;
  padding: 24px;
  text-align: center;
  width: 395px;

  p {
    font: 400 0.85rem/1.5 Lato, -apple-system, blinkmacsystemfont, Segoe UI,
      Roboto, Helvetica Neue, arial, sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", Segoe UI Symbol, icomoon;
  }

  .ticket-number {
    background-color: #f6f6f6;
    color: #0d0d0d;
    font: 700 1.25rem/1.8 AeRadar;
    padding: 4px;
    text-align: center;
  }
}

.ticket-dialog-activator {
  align-items: center;
  align-items: center;
  color: #0d0d0d;
  cursor: pointer;
  display: flex;
  font: 400 0.85rem/1.5 Lato, -apple-system, blinkmacsystemfont, Segoe UI,
    Roboto, Helvetica Neue, arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", Segoe UI Symbol, icomoon;
  gap: 5px;
  margin: 0 0 15px 10px;
  width: fit-content;
  .v-icon {
    font-size: 1.2rem;
  }
}
</style>
