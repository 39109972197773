<template>
  <ValidationProvider :name="$attrs.label" :rules="rules">
    <v-text-field
      :disabled="$attrs.disabled"
      :error-messages="!$attrs.hideDetails ? errors : null"
      :placeholder="$attrs.placeholder"
      :success="!$attrs.hideValidations ? null : valid"
      background-color="white"
      filled
      slot-scope="{ errors, valid }"
      v-bind="$attrs"
      v-model="innerValue"
      v-on="$listeners"
    >
    </v-text-field>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";

export default {
  components: {
    ValidationProvider,
  },
  props: {
    rules: {
      type: [Object, String],
      default: "",
    },
    // must be included in props
    value: {
      type: null,
    },
  },
  data: () => ({
    innerValue: "",
  }),
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      this.$emit("input", newVal);
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal;
    },
  },
  created() {
    if (this.value) this.innerValue = this.value;
    if (!this.value && this.$attrs.default) {
      this.innerValue = this.$attrs.default || "";
    }
  },
};
</script>
