<template>
  <header :class="{ header: !isLightTheme(), 'header-light': isLightTheme() }">
    <v-container class="header-wrap">
      <div class="header-logo">
        <img
          v-if="!isLightTheme()"
          src="../../assets/ae_logo_2.png"
          alt="Air Europa"
        />
        <img
          v-if="isLightTheme()"
          src="../../assets/aea-logo-blue.svg"
          alt="Air Europa"
        />
      </div>
    </v-container>
  </header>
</template>

<script>
export default {
  name: "HeaderComponent",
  data: () => ({
    lightTheme: ["direct-service"],
  }),
  methods: {
    isLightTheme() {
      return this.lightTheme.includes(this.$router?.currentRoute?.name);
    },
  },
};
</script>

<style scoped>
.header {
  background-color: #2d69e1;
}
.header-light {
  background-color: #fff;
}

.header-wrap {
  margin: 0 auto;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-flex;
  display: flex;
  padding-left: 15px;
  padding-right: 15px;
}
.header-logo {
  display: -webkit-flex;
  display: flex;
  width: 221px;
  padding-bottom: 20px;
  padding-top: 20px;
}
.header-logo span {
  display: none;
}
img {
  width: 100%;
}
@media (min-width: 1025px) {
  img {
    max-width: 1110px;
  }
}

@media (min-width: 1025px) {
  .header-wrap {
    height: 80px;
    padding-left: 0;
    padding-right: 0;
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (max-width: 768px) {
  .header-logo {
    margin-left: auto;
    margin-right: auto;
  }
  img {
    max-width: 190px;
  }
}
</style>
