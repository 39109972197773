<template class="h-100 compensation-selection">
  <div>
    <section
      v-if="pageStatus == PAGESTATUS_SUCCESS"
      class="alert-component elevation-0"
    >
      <ConfirmationComponent
        :title="$t('directService.confirmation-title')"
      ></ConfirmationComponent>
      <div class="desc-compensation">
        <div style="margin-top: 15px; margin-bottom: 15px">
          {{ $t("compensation.compensationSelect") }}
        </div>
        <v-row>
          <v-col cols="6">
            <div>
              <span>{{ $t("compensation.compensationType") }}</span>
            </div>
          </v-col>
          <v-col cols="6">
            <div>{{ $t("compensation.compensationAmount") }}</div>
          </v-col>
        </v-row>
        <div v-for="(compen, j) in jsonRes" :key="j">
          <v-row>
            <v-col cols="6">
              <div>
                <div v-if="compen.type == 'CMON'">
                  {{ $t("compensationSelection.monetary") }}
                </div>
                <div v-if="compen.type == 'CBON'">
                  {{ $t("compensationSelection.bono") }}
                </div>
                <div v-if="compen.type == 'CMIL'">
                  {{ $t("compensationSelection.millas") }}
                </div>
              </div>
            </v-col>
            <v-col cols="6">
              <div>
                <div v-if="compen.type == 'CMON'">
                  {{ formatAmount(compen.amount) }} {{ compen.currency }}
                </div>
                <div v-if="compen.type == 'CBON'">
                  {{ formatAmount(compen.amount) }} {{ compen.currency }}
                </div>
                <div v-if="compen.type == 'CMIL'">
                  {{ formatAmount(compen.amount) }} {{ compen.currency }}
                </div>
              </div>
            </v-col>
          </v-row>
          <div
            v-if="compen.type == 'CMON'"
            style="margin-top: 20px; color: #0f2367"
          >
            {{ $t("compensationSelection.cmonResumeInf") }}
          </div>
        </div>
      </div>
    </section>
    <v-container v-if="pageStatus == PAGESTATUS_FORM">
      <div style="margin: 10px 40px 10px 40px">
        <div class="section-heading mt-3">
          <h2>{{ $t("compensationSelection.title") }}</h2>
          <p class="ae-description">
            {{ $t("compensationSelection.subtitle") }} {{ tokenStore.id }}
          </p>
        </div>
        <div v-for="(prop, i) in proposals" :key="i + '-proposal'">
          <div
            v-if="proposals && proposals.length > 1"
            class="compensation-Description"
          >
            <div v-if="i === 0">
              {{ $t("compensationSelection.mainProposal") }}
            </div>
            <div v-else>
              {{
                i === 0 ? "" : $t("compensationSelection.alternativeProposal")
              }}
            </div>
          </div>
          <v-card class="card-style" width="800">
            <div style="margin-left: 20px">
              <v-row>
                <v-col
                  cols="3"
                  v-for="(cbon, o) in getCBON(prop.CompensationsByProposal)"
                  :key="o + '-bono'"
                >
                  <div
                    @click="infoBon"
                    class="compensation-style"
                    v-if="cbon.Type === 'CBON'"
                  >
                    <div>
                      {{ $t("compensationSelection.bono") }}
                      <img
                        class="icon-style"
                        src="../../assets//icons-action-alert@2x.png"
                      />
                    </div>
                  </div>
                </v-col>
                <v-col
                  cols="3"
                  v-for="(cmon, g) in getCMON(prop.CompensationsByProposal)"
                  :key="g + '-mon'"
                >
                  <div
                    @click="infoMon"
                    class="compensation-style"
                    v-if="cmon.Type === 'CMON'"
                  >
                    {{ $t("compensationSelection.monetary") }}
                    <img
                      class="icon-style"
                      src="../../assets//icons-action-alert@2x.png"
                    />
                  </div>
                </v-col>
                <div
                  v-for="(cmil, l) in getCMIL(prop.CompensationsByProposal)"
                  :key="l + '-mill'"
                >
                  <v-col cols="3" v-if="cmil.Type === 'CMIL'">
                    <div @click="infoMil" class="compensation-style">
                      {{ $t("compensationSelection.millas") }}
                      <img
                        class="icon-style"
                        src="../../assets//icons-action-alert@2x.png"
                      />
                    </div>
                  </v-col>
                </div>
              </v-row>
              <v-radio-group v-model="proposalSelected">
                <v-row>
                  <v-col
                    cols="3"
                    v-for="(bono, j) in getCBON(prop.CompensationsByProposal)"
                    :key="j + '-cbon'"
                  >
                    <div v-if="proposalsFiltered.includes('CBON')">
                      <div class="type-compensation">
                        {{ formatAmount(bono.CompensationDetails.Amount) }}
                        {{ bono.CompensationDetails.Currency }}
                      </div>
                    </div>
                  </v-col>
                  <v-col
                    cols="3"
                    v-for="(monetary, f) in getCMON(
                      prop.CompensationsByProposal
                    )"
                    :key="f + '-cmon'"
                  >
                    <div v-if="proposalsFiltered.includes('CMON')">
                      <div class="type-compensation">
                        {{ formatAmount(monetary.CompensationDetails.Amount) }}
                        {{ monetary.CompensationDetails.Currency }}
                      </div>
                    </div>
                  </v-col>
                  <v-col
                    cols="3"
                    v-for="(millas, t) in getCMIL(prop.CompensationsByProposal)"
                    :key="t + '-cmil'"
                  >
                    <div v-if="proposalsFiltered.includes('CMIL')">
                      <div class="type-compensation">
                        {{ formatAmount(millas.CompensationDetails.Amount) }}
                        {{ millas.CompensationDetails.Currency }}
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="3" class="ml-auto">
                    <div>
                      <v-radio :value="i"></v-radio>
                    </div>
                  </v-col>
                </v-row>
              </v-radio-group>
            </div>
          </v-card>
        </div>
      </div>

      <!-- Botón Leer las condiciones -->
      <div class="d-sm-flex">
        <v-btn
          class="btn-bank"
          style="margin-right: unset"
          @click="showModal"
          v-if="showReadConditions && proposalSelected !== null"
          >{{ $t("compensationSelection.btnRead") }}</v-btn
        >
      </div>

      <div class="text-center mt-3">
        <!-- Contenedor principal -->
        <div class="d-sm-flex flex-column align-items-center">
          <!-- Frase encima del botón confirmar -->
          <div
            class="phrase-confirm mb-3"
            v-if="
              showNextButton &&
              !infoDialogCombined &&
              showReadConditions == false
            "
          >
            {{ $t("compensationSelection.phraseConfirm") }}
          </div>

          <!-- Botón confirmar -->
          <v-btn
            class="btn-bank"
            style="margin-right: unset"
            @click="nextForm"
            v-if="
              showNextButton &&
              !infoDialogCombined &&
              showReadConditions == false
            "
          >
            {{ $t("compensationSelection.btnConf") }}
          </v-btn>
        </div>
      </div>
    </v-container>
    <v-dialog
      v-model="infoDialogCombined"
      width="500"
      @click:outside="handleModalClose"
    >
      <v-card>
        <div class="pt-3" style="display: flex; flex-direction: column">
          <v-icon style="align-self: flex-end" @click="handleModalClose"
            >close</v-icon
          >
        </div>
        <CombinedModal
          :dialog="disableDialog"
          :showAEBond="showAEBond"
          :showMiles="showMiles"
          :showMonetary="showMonetary"
          @close="handleModalClose"
          @understood="understood"
        ></CombinedModal>
      </v-card>
    </v-dialog>
    <v-dialog v-model="infoDialogBon" width="500">
      <v-card>
        <div class="pt-3" style="display: flex; flex-direction: column">
          <v-icon style="align-self: flex-end" @click="infoDialogBon = false"
            >close</v-icon
          >
        </div>
        <TcAebond :dialog="disableDialog"></TcAebond>
      </v-card>
    </v-dialog>
    <v-dialog v-model="infoDialogMon" width="500">
      <v-card>
        <div class="pt-3" style="display: flex; flex-direction: column">
          <v-icon style="align-self: flex-end" @click="infoDialogMon = false"
            >close</v-icon
          >
        </div>
        <TcMonetary :dialog="disableDialog"></TcMonetary>
      </v-card>
    </v-dialog>
    <v-dialog v-model="infoDialogMil" width="500">
      <v-card>
        <div class="pt-3" style="display: flex; flex-direction: column">
          <v-icon style="align-self: flex-end" @click="infoDialogMil = false"
            >close</v-icon
          >
        </div>
        <TcMiles :dialog="disableDialog"></TcMiles>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Api from "@/api/api.js";

import { mapStores, mapWritableState } from "pinia";
import { useTokenStore } from "@/stores/tokenStore";
import { useUiStore } from "@/stores/uiStore";
import { useCompensationStore } from "@/stores/compensationStore";
import ConfirmationComponent from "../ConfirmationComponent.vue";
import TcAebond from "../compensation/TcAebond.vue";
import TcMonetary from "../compensation/TcMonetary.vue";
import TcMiles from "../compensation/TcMiles.vue";
import CombinedModal from "../compensation/CombinedModal.vue";

export default {
  name: "CompensationSelection",
  components: {
    ConfirmationComponent,
    TcAebond,
    TcMonetary,
    TcMiles,
    CombinedModal,
  },
  data: () => ({
    selectedOptions: [],
    passengerList: {},
    milesPassengerList: {},
    PAGESTATUS_FORM: 0,
    PAGESTATUS_SUCCESS: 1,
    PAGESTATUS_LOADERROR: 2,
    pageStatus: null,
    proposalSelected: null,
    proposals: null,
    proposalsFiltered: [],
    infoDialogBon: false,
    infoDialogMon: false,
    infoDialogMil: false,
    infoDialogCombined: false,
    showAEBond: false,
    showMiles: false,
    showMonetary: false,
    showNextButton: false,
    showReadConditions: true,
  }),
  computed: {
    ...mapStores(useTokenStore, useUiStore),
    ...mapWritableState(useCompensationStore, [
      "countryObject",
      "jsonRes",
      "proposalObject",
      "pyId",
    ]),
    isExpiredToken() {
      // return false;
      return this.tokenStore?.isExpiredToken;
    },
    getProposals() {
      return this.jsonRes.Analysis.Proposals;
    },
  },
  created() {
    this.getData();
  },
  watch: {
    selectedOptions: function (value) {
      this.proposalObject = this.jsonRes;

      this.$emit("next-form", [
        value,
        this.proposalObject.PassengerList,
        this.proposalObject.MilesPassengerList,
      ]);
    },
    proposalSelected: function (newVal) {
      if (newVal !== null) {
        // Restablecer la propiedad showReadConditions a true cuando se selecciona una propuesta
        this.showReadConditions = true;
      }
    },
  },
  methods: {
    removeNumber(des) {
      return des.replace(/\s\d+$/, "");
    },
    getCBON(c) {
      return c.filter((c) => c.Type === "CBON");
    },
    getCMON(p) {
      return p.filter((p) => p.Type === "CMON");
    },
    getCMIL(e) {
      return e.filter((e) => e.Type === "CMIL");
    },
    proposalType() {
      this.proposalsFiltered = [
        ...new Set(
          this.getProposals
            .flatMap((e) => e.CompensationsByProposal)
            .map((e) => e.Type)
        ),
      ];
    },

    disableDialog() {
      this.infoDialogBon = false;
      this.infoDialogMon = false;
      this.infoDialogMil = false;
    },
    infoBon() {
      if (this.proposalsFiltered.includes("CBON")) this.infoDialogBon = true;
    },
    infoMon() {
      if (this.proposalsFiltered.includes("CMON")) this.infoDialogMon = true;
    },
    infoMil() {
      if (this.proposalsFiltered.includes("CMIL")) this.infoDialogMil = true;
    },
    async getData() {
      this.uiStore.changeSpinner(true);
      try {
        if (!this.jsonRes) {
          const response = await Api.getCompensationForm(this.tokenStore.id);
          this.jsonRes = response.data;
        }
        if (this.jsonRes.compensations && !this.jsonRes.countriesByCurrency) {
          this.pageStatus = this.PAGESTATUS_SUCCESS;
          this.uiStore.changeSpinner(false);
        } else if (
          this.jsonRes.compensations &&
          this.jsonRes.countriesByCurrency &&
          this.jsonRes.cmon
        ) {
          this.countryObject = this.jsonRes.countriesByCurrency;
          this.pyId = this.jsonRes.cmon.pyID;
          this.selectedOptions.push(2);
        } else {
          this.proposals = this.jsonRes?.Analysis?.Proposals.map((e, i) => ({
            ...e,
            id: i,
          }));
          this.passengerList = this.jsonRes.PassengerList;
          this.milesPassengerList = this.jsonRes.MilesPassengerList;
          this.proposalType();
          this.pageStatus = this.PAGESTATUS_FORM;

          this.uiStore.changeSpinner(false);
        }
      } catch (e) {
        if (e?.response?.status === 400) {
          this.hasSapErrors(e.response.data.errorDescription.split(";"));
          this.pageStatus = this.PAGESTATUS_FORM;
        } else if (e?.response?.status === 404) {
          this.setCustomError({
            customError: this.$t("error.error404"),
          });
        } else if (e?.response?.status === 406) {
          this.setCustomError({
            customError: this.$t("error.error404"),
          });
        } else if (e?.response?.status === 409) {
          this.setCustomError({
            customError: this.$t("error.error409"),
          });
        } else if (e?.response?.status === 410) {
          this.setCustomError({
            customError: this.$t("error.error410C"),
          });
        } else if (e?.response?.status) {
          this.setCustomError({ errorCode: e.response.status });
        }
        this.uiStore.changeSpinner(false);
      }
    },
    setCustomError({ errorCode, customError = "errorsSap.TPY-0100" }) {
      let error = "";
      if (!errorCode && customError) {
        error = customError;
      }
      if (errorCode) {
        let status = errorCode.toString();
        if (status.match(/^5\d{2}$/)) status = "5XXC";
        error = `error.error${status}`;
        if (!this.$te(error)) error = customError;
      }
      this.uiStore.setError({
        message: this.$t(error),
      });
    },
    async showModal() {
      let item = this.proposals[this.proposalSelected];
      this.showMiles = item.CompensationsByProposal.some(
        (c) => c.Type === "CMIL"
      );
      this.showAEBond = item.CompensationsByProposal.some(
        (c) => c.Type === "CBON"
      );
      this.showMonetary = item.CompensationsByProposal.some(
        (c) => c.Type === "CMON"
      );
      this.showReadConditions = false; // Esto desactiva el botón "Leer las condiciones"
      this.infoDialogCombined = true; // Esto abre el diálogo combinado
    },
    async nextForm() {
      const proposal = this.proposalSelected;
      this.proposals.find((e) => e.id === proposal).SelectedByClient = "true";
      // eslint-disable-next-line no-unused-vars
      this.proposals.map(({ id, ...p }) => p);
      this.jsonRes.Analysis.Proposals = this.proposals;
      const selectedProposal = this.proposals.find(
        (e) => e.SelectedByClient == "true"
      );
      const isCBON = selectedProposal.CompensationsByProposal.some(
        (c) => c.Type === "CBON"
      );
      const isCMON = selectedProposal.CompensationsByProposal.some(
        (c) => c.Type === "CMON"
      );
      const isCMIL = selectedProposal.CompensationsByProposal.some(
        (c) => c.Type === "CMIL"
      );

      if (!isCBON && !isCMON && !isCMIL) return;
      if (isCBON && isCMON && !isCMIL) {
        this.selectedOptions.push(3);
      } else if (isCBON && !isCMON && !isCMIL) {
        this.selectedOptions.push(1);
      } else if (isCBON && !isCMON && isCMIL) {
        this.selectedOptions.push(5);
      } else if (!isCBON && isCMON && !isCMIL) {
        const response = await this.sendMonetaryProposal();
        let countryObject = response.countriesByCurrency;
        this.countryObject = countryObject;
        this.pyId = response?.cmon?.pyID; // comentado para quitar el error del pyID
        this.selectedOptions.push(2);
      } else if (!isCBON && isCMON && isCMIL) {
        this.selectedOptions.push(6);
      } else if (!isCBON && !isCMON && isCMIL) {
        this.selectedOptions.push(4);
      } else if (isCBON && isCMON && isCMIL) {
        this.selectedOptions.push(7, 8);
      }
      this.showReadConditions = true;
    },
    async sendMonetaryProposal() {
      try {
        this.uiStore.changeSpinner(true);
        let proposalObj = this.jsonRes;
        return await Api.sendCompensationSelected(
          proposalObj,
          this.tokenStore.id
        );
      } catch (e) {
        if (e?.response?.status === 400) {
          this.hasSapErrors(e.response.data.errorDescription.split(";"));
          this.pageStatus = this.PAGESTATUS_FORM;
        } else if (e?.response?.status === 404) {
          this.setCustomError({
            customError: this.$t("error.error404"),
          });
        } else if (e?.response?.status === 409) {
          this.setCustomError({
            customError: this.$t("error.error409"),
          });
        } else if (e?.response?.status === 410) {
          this.setCustomError({
            customError: this.$t("error.error410C"),
          });
        } else if (e?.response?.status === 423) {
          this.setCustomError({
            customError: this.$t("error.error423C"),
          });
        } else if (e?.response?.status) {
          this.setCustomError({ errorCode: e.response.status });
        }
        this.uiStore.changeSpinner(false);
      }
    },
    formatAmount(amount) {
      const amountTrunc = Math.trunc(amount);
      const hasDecimal = amount !== amountTrunc;
      if (hasDecimal) {
        const amountRound = Math.round(amount * 100) / 100;
        return amountRound.toLocaleString();
      } else {
        return amountTrunc.toLocaleString();
      }
    },
    understood() {
      // Cerrar el modal y mostrar el botón "Confirmar"
      this.infoDialogCombined = false;
      this.showNextButton = true;
      this.showReadConditions = false; // Desactivar el botón "Leer las condiciones"
    },
    handleModalClose() {
      this.proposalSelected = null; // Desmarcar la opción seleccionada
      this.infoDialogCombined = false;
      this.showNextButton = false;
      this.showReadConditions = true;
    },
  },
};
</script>

<style scoped lang="scss">
.section-heading {
  color: #0f2367;
  font-family: AERadar;
  font-size: 16px;
  padding-bottom: 15px;
}

.ae-description {
  font-size: 14px;
  font-family: Lato, -apple-system, blinkmacsystemfont, Segoe UI, Roboto,
    Helvetica Neue, arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    Segoe UI Symbol, icomoon;
  margin-bottom: 16px;
  text-decoration: none;
  color: #0f2367;
}
.p-description {
  font-size: 12px;
  font-family: Lato, -apple-system, blinkmacsystemfont, Segoe UI, Roboto,
    Helvetica Neue, arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    Segoe UI Symbol, icomoon;
  color: #0f2367;
}
.type-compensation {
  font-size: 14px;
  font-family: AERadar;
  font-size: 16px;
  color: #0f2367;
}
.compensation-Description {
  font-size: 14px;
  font-family: AERadar;
  font-size: 16px;
  color: #2d69e1;
}
.compensation-style {
  font-size: 14px;
  font-family: AERadar;
  font-size: 16px;
  color: #0f2367;
  display: flex;
  align-items: center;
  cursor: pointer;
}
/* Media query para aplicar flex-wrap solo en diseños responsivos */
@media (max-width: 768px) {
  .compensation-style {
    flex-wrap: wrap;
  }
}
.icon-style {
  margin-left: 6px;
  width: 14px;
  height: 14px;
}
.btn-bank {
  color: white;
  background-color: #2d69e1;
  margin-top: 10px;
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
  height: 48px;
  border-radius: 14px;
  font-size: 16px !important;
  padding: 12px 24px;
}
/* Media query para diseños responsivos */
@media (max-width: 768px) {
  .btn-bank {
    font-size: 14px !important; /* Reducir tamaño de fuente en pantallas pequeñas */
    padding: 10px 20px; /* Ajustar padding para que el botón no sea tan alto */
    height: 40px; /* Reducir altura del botón en pantallas pequeñas */
  }
}

/* Media query adicional si es necesario para pantallas aún más pequeñas */
@media (max-width: 480px) {
  .btn-bank {
    font-size: 12px !important; /* Ajustar aún más el tamaño de fuente */
    padding: 8px 16px; /* Reducir aún más el padding del botón */
    height: 36px; /* Ajustar altura para pantallas muy pequeñas */
  }
}
/* Estilos personalizados para la frase encima del botón */
.phrase-confirm {
  font-family: Lato, -apple-system, blinkmacsystemfont, Segoe UI, Roboto,
    Helvetica Neue, arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    Segoe UI Symbol, icomoon; /* Tipo de fuente */
  font-size: 14px; /* Tamaño de fuente */
  font-weight: bold;
  color: #0f2367; /* Color del texto */
  text-align: end; /* Centrado a la derecha, al final del botón */
}
/* Estilos para pantallas pequeñas (móviles) */
@media (max-width: 576px) {
  .phrase-confirm {
    text-align: center; /* Centrar el texto */
    margin-bottom: 10px; /* Espacio debajo del texto */
  }
}

/* Estilos para pantallas medianas (tabletas) */
@media (min-width: 577px) and (max-width: 768px) {
  .phrase-confirm {
    text-align: center; /* Centrar el texto */
    margin-bottom: 10px; /* Espacio debajo del texto */
  }
}

/* Estilos para el contenedor principal con posicionamiento relativo */
.position-relative {
  position: relative;
}
.card-style {
  margin-top: 20px;
  margin-bottom: 15px;
}
.desc-compensation {
  color: #000;
  font-family: AERadar;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.alert-component {
  width: 570px;
  box-shadow: 0px 5px 10px rgba(204, 204, 204, 0.3);
  padding: 100px;
  border-radius: 20px;
  margin: 0 auto;
  font-family: AERadar;
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}
</style>
