<template>
  <div class="success-view">
    <img src="../../assets/group.png" />
    <p class="success-view-title">{{ $t("generic_success") }}</p>
    <p class="success-view-subtitle">{{ title }}</p>
  </div>
</template>

<script>
import Vue from "vue";
export default Vue.extend({
  props: ["title", "subtitle"],
});
</script>

<style scoped>
.success-view {
  max-width: 400px;
}
.success-view-title {
  font-family: Aeradar-Bold, sans-serif;
  font-size: 24px;
  line-height: 32px;
  margin-top: 26px;
  color: black;
}
.success-view-subtitle {
  font-family: Lato;
  font-size: 18px;
  line-height: 26px;
  color: #0d0d0d;
}
img {
  width: 88px;
}

@media (max-width: 640px) {
  .success-view {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  img {
    width: 66px;
  }
}
</style>
