import { defineStore } from "pinia";
import Api from "@/api/api.js";
import { getCountries } from "../api/dapi";
// import { useUiStore } from "@/stores/uiStore";

export const useCountriesStore = defineStore("countries", {
  state: () => ({
    lastLanguage: null,
    countriesList: null,
    countriesListFilteredByCountry: null,
    countriesDapi: null,
  }),
  getters: {},
  actions: {
    async loadCountriesDapi() {
      try {
        const countries = await getCountries();
        this.countriesDapi = countries?.data?.data || [];
      } catch (error) {
        console.error(error);
      }
    },
    async fetchCountriesByLanguage(lang) {
      // const uiStore = useUiStore();
      // uiStore.changeSpinner(true);
      try {
        const { data } = await Api.getCountries(lang);
        this.lastLanguage = lang;
        this.countriesList = data?.CountriesList || [];
      } catch (error) {
        console.error(error);
      }
      // uiStore.changeSpinner(false);
    },
    filterWithAvailableCurrency(currenciesAvailable) {
      if (!currenciesAvailable) return;
      this.countriesListFilteredByCountry = this.countriesList.filter((e) =>
        currenciesAvailable.includes(e.CountryCode)
      );
    },
    countryOptions(filteredByCountry = false) {
      return this[
        filteredByCountry && this.countriesListFilteredByCountry
          ? "countriesListFilteredByCountry"
          : "countriesList"
      ]?.map((c) => ({
        text: c?.CountryName,
        value: {
          name: c?.CountryName,
          code: c?.RegionCode,
          isocode: c?.CountryCode,
        },
      }));
    },
  },
});
