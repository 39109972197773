import { defineStore } from "pinia";

export const useTokenStore = defineStore("token", {
  state: () => ({
    token: null,
    caseId: null,
    lang: null,
    urlToken: null,
    validDateTime: null,
    pyId: null,
    idGolden: null,
    idSuitCase: null,
    number: null,
    id: null,
  }),
  getters: {
    isExpiredToken() {
      if (!this?.validDateTime) return true;
      const tokenDateTime = new Date(this.validDateTime);
      const validTokenDateTime = new Date();
      validTokenDateTime.setDate(validTokenDateTime.getDate() - 7);
      if (tokenDateTime < validTokenDateTime) return true;
      return false;
    },
  },
  actions: {
    decrypt(token) {
      if (!token) return;
      const decryptedToken = window.atob(token);
      const req = JSON.parse(decryptedToken);

      this.urlToken = token;
      this.token = req?.token;
      this.caseId = req?.caseId;
      this.lang = req.language || req.Language || "en";
      this.validDateTime = req?.DateTimeVal1;
      this.number = req?.Number;
      this.pyId = req?.pyID;
      this.id = req?.id;
    },
  },
});
