import { extend } from "vee-validate";
import { i18n } from "@/i18n/translation";
extend("containsHyphen", {
  validate: (value) => /^.*-.*$/.test(value),
  message: () => {
    return i18n.t("rulesBr.accountnumberHyphen");
  },
});
extend("noDot", {
  validate: (value) => /^[^.]*$/.test(value),
  message: () => {
    return i18n.t("rulesBr.accountnumberNoDot");
  },
});

function getRulesByField(field) {
  const nameOfField = field?.field?.toLowerCase();
  let rules = {};
  if (field.required) rules["required"] = field.required;
  if (nameOfField && customRulesByField[nameOfField]) {
    return { ...rules, ...customRulesByField[nameOfField](field) };
  }
  if (field.maxSize) rules["max"] = field.maxLength;
  if (field.maxSize) rules["min"] = field.minLength;
  return rules;
}

const customRulesByField = {
  iban: (field) => ({
    ibanCountryError: [field.countryCode],
    ibanDigits: [field?.minLength, field?.maxLength],
  }),
};

function getRulesByFieldBR(field) {
  const nameOfField = field?.field?.toLowerCase();
  let rules = {};
  if (field.required) rules["required"] = field.required;
  if (nameOfField && customRulesByField[nameOfField]) {
    return { ...rules, ...customRulesByField[nameOfField](field) };
  }
  if (field.maxSize) rules["max"] = field.maxLength;
  if (field.maxSize) rules["min"] = field.minLength;

  // Agregar validación para aceptar solo 7 números para el campo Clave de banco
  if (nameOfField === "bankcode") {
    rules["min"] = 7;
    rules["max"] = 7;
    rules["numeric"] = [(v) => /^\d+$/.test(v) || i18n.t("rulesBr.bankcode")];
  }

  // Agregar validación para el campo Número de cuenta
  if (nameOfField === "accountnumber") {
    rules["containsHyphen"] = (v) =>
      /^.*-.*$/.test(v) || i18n.t("rulesBr.accountnumberHyphen");
    rules["noDot"] = (v) =>
      /^[^.]*$/.test(v) || i18n.t("rulesBr.accountnumberNoDot");
  }

  return rules;
}
export { getRulesByField, getRulesByFieldBR };
