const AZ = {
  countryConfig: {
    accountHolderDocNumber: {
      visible: true,
      label: "bankDetails.accountHolderDocNumber",
      required: true,
    },
    swift: {
      label: "SWIFT",
      placeholder: "bankDetails.swiftMinmaxValidation",
      visible: true,
      required: true,
    },
    accountNumber: {
      visible: true,
      label: "bankDetails.accountNumber",
      required: true,
    },
    IBAN: {
      visible: false,
    },
    controlDigit: {
      label: "bankDetails.controlDigit",
      visible: true,
      required: true,
    },
    bankName: {
      visible: true,
      required: true,
      label: "bankDetails.bankName",
    },
  },
};
export { AZ };
