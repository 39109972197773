var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h-100 dynamic-compensation"},[(_vm.pageStatus == _vm.PAGESTATUS_SUCCESS)?_c('section',{staticClass:"alert-component elevation-0"},[_vm._l((_vm.compensationStore.jsonPost.compensations),function(compen,j){return _c('div',{key:j},[(
          compen.type == 'CMON' &&
          _vm.formDataBank.cmon.CompensationDetails.CountryCode === 'ES'
        )?_c('p',{staticStyle:{"margin-top":"20px","color":"#0f2367"}},[_vm._v(" "+_vm._s(_vm.$t("compensationSelection.cmonResumeInf"))+" ")]):_vm._e()])}),_c('ConfirmationComponent',{attrs:{"title":_vm.$t('directService.confirmation-title')}}),_c('div',{staticClass:"desc-compensation"},[_c('div',{staticStyle:{"margin-top":"15px","margin-bottom":"15px"}},[_vm._v(" "+_vm._s(_vm.$t("compensation.compensationSelect"))+" ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t("compensation.compensationType")))])])]),_c('v-col',{attrs:{"cols":"6"}},[_c('div',[_vm._v(_vm._s(_vm.$t("compensation.compensationAmount")))])])],1),_vm._l((_vm.compensationStore.jsonPost.compensations),function(compen,j){return _c('div',{key:j},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',[(compen.type == 'CMON')?_c('div',[_vm._v(" "+_vm._s(_vm.$t("compensationSelection.monetary"))+" ")]):_vm._e(),(compen.type == 'CBON')?_c('div',[_vm._v(" "+_vm._s(_vm.$t("compensationSelection.bono"))+" ")]):_vm._e(),(compen.type == 'CMIL')?_c('div',[_vm._v(" "+_vm._s(_vm.$t("compensationSelection.millas"))+" ")]):_vm._e()])]),_c('v-col',{attrs:{"cols":"6"}},[_c('div',[(compen.type == 'CMON')?_c('div',[_vm._v(" "+_vm._s(_vm.formatAmount(compen.amount))+" "+_vm._s(compen.currency)+" ")]):_vm._e(),(compen.type == 'CBON')?_c('div',[_vm._v(" "+_vm._s(_vm.formatAmount(compen.amount))+" "+_vm._s(compen.currency)+" ")]):_vm._e(),(compen.type == 'CMIL')?_c('div',[_vm._v(" "+_vm._s(_vm.formatAmount(compen.amount))+" "+_vm._s(compen.currency)+" ")]):_vm._e()])])],1)],1)})],2)],2):_vm._e(),(_vm.pageStatus != _vm.PAGESTATUS_SUCCESS)?_c('v-container',[_c('ValidationObserver',{ref:"clientFormObs",attrs:{"tag":"form"},scopedSlots:_vm._u([{key:"default",fn:function({ valid }){return [_c('div',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.proposalOption.includes(1) ||
          (_vm.proposalOption.includes(5) && _vm.showDataClient == true) ||
          (_vm.proposalOption.includes(3) && _vm.showDataClient == true) ||
          (_vm.proposalOption.includes(7) && _vm.showDataClient == true)
        ),expression:"\n          proposalOption.includes(1) ||\n          (proposalOption.includes(5) && showDataClient == true) ||\n          (proposalOption.includes(3) && showDataClient == true) ||\n          (proposalOption.includes(7) && showDataClient == true)\n        "}]},[_c('div',[_c('h4',{staticClass:"title-info"},[_vm._v(" "+_vm._s(_vm.$t("compensationSelection.clientForm.titleClient"))+" ")]),_c('p',{staticClass:"ae-description"},[_vm._v(" "+_vm._s(_vm.$t("compensationSelection.subtitle"))+" "+_vm._s(_vm.tokenStore.id)+" ")]),_c('h4',{staticClass:"bono-info"},[_vm._v(" *"+_vm._s(_vm.$t("directService.customerData.infoBono"))+" ")])]),_c('DataClientComponent',{ref:"dataClientForm",attrs:{"passengers":_vm.passengerList}})],1),(
          (_vm.proposalOption.includes(3) && _vm.showDataClient == true) ||
          (_vm.proposalOption.includes(5) && _vm.showDataClient == true) ||
          (_vm.proposalOption.includes(7) && _vm.showDataClient == true)
        )?_c('div',{staticClass:"d-sm-flex"},[_c('v-btn',{staticClass:"btn-confirm",staticStyle:{"margin-right":"unset"},attrs:{"color":"primary","dark":"","large":"","disabled":!valid},on:{"click":_vm.nextCBON}},[_c('span',{staticClass:"mr-1"},[_vm._v(_vm._s(_vm.$t("compensationSelection.btnContinue")))]),_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-send")])],1)],1):_vm._e(),(_vm.proposalOption.includes(1))?_c('div',{staticClass:"d-sm-flex"},[_c('v-btn',{staticClass:"btn-confirm",staticStyle:{"margin-right":"unset"},attrs:{"color":"primary","dark":"","large":"","disabled":!valid},on:{"click":_vm.submitFormClient}},[_c('span',{staticClass:"mr-1"},[_vm._v(_vm._s(_vm.$t("compensationSelection.btnConfirm")))]),_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-send")])],1)],1):_vm._e()]}}],null,false,527365556)}),_c('ValidationObserver',{ref:"milesFormObs",attrs:{"tag":"form"},scopedSlots:_vm._u([{key:"default",fn:function({ valid }){return [_c('div',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.proposalOption.includes(4) ||
          (_vm.proposalOption.includes(5) &&
            _vm.showDataMiles == true &&
            _vm.showDataClient == false) ||
          (_vm.proposalOption.includes(6) &&
            _vm.showDataMiles == true &&
            _vm.showDataBank == false) ||
          (_vm.proposalOption.includes(8) &&
            _vm.showDataMiles == true &&
            _vm.showDataBank == false &&
            _vm.showDataClient == false)
        ),expression:"\n          proposalOption.includes(4) ||\n          (proposalOption.includes(5) &&\n            showDataMiles == true &&\n            showDataClient == false) ||\n          (proposalOption.includes(6) &&\n            showDataMiles == true &&\n            showDataBank == false) ||\n          (proposalOption.includes(8) &&\n            showDataMiles == true &&\n            showDataBank == false &&\n            showDataClient == false)\n        "}]},[_c('div',[_c('h4',{staticClass:"title-info"},[_vm._v(" "+_vm._s(_vm.$t("compensationSelection.clientForm.titleMillas"))+" ")]),_c('p',{staticClass:"ae-description"},[_vm._v(" "+_vm._s(_vm.$t("compensationSelection.subtitle"))+" "+_vm._s(_vm.tokenStore.id)+" ")])]),_c('MillasComponent',{ref:"dataMilesForm",attrs:{"milesPassengers":_vm.milesPassengerList}})],1),(
          (_vm.proposalOption.includes(6) &&
            _vm.showDataMiles == true &&
            _vm.showDataBank == false) ||
          (_vm.proposalOption.includes(8) &&
            _vm.showDataMiles == true &&
            _vm.showDataBank == false &&
            _vm.showDataClient == false)
        )?_c('div',{staticClass:"d-sm-flex"},[_c('v-btn',{staticClass:"btn-confirm",staticStyle:{"margin-right":"unset"},attrs:{"color":"primary","dark":"","large":"","disabled":!valid},on:{"click":_vm.nextCMIL}},[_c('span',{staticClass:"mr-1"},[_vm._v(_vm._s(_vm.$t("compensationSelection.btnContinue")))]),_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-send")])],1)],1):_vm._e(),(
          _vm.proposalOption.includes(4) ||
          (_vm.proposalOption.includes(5) &&
            _vm.showDataClient == false &&
            _vm.showDataBank == false)
        )?_c('div',{staticClass:"d-sm-flex"},[_c('v-btn',{staticClass:"btn-confirm",staticStyle:{"margin-right":"unset"},attrs:{"color":"primary","dark":"","large":"","disabled":!valid},on:{"click":_vm.submitFormMiles}},[_c('span',{staticClass:"mr-1"},[_vm._v(_vm._s(_vm.$t("compensationSelection.btnConfirm")))]),_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-send")])],1)],1):_vm._e()]}}],null,false,2085971824)}),_c('ValidationObserver',{ref:"bankFormObs",attrs:{"tag":"form"},scopedSlots:_vm._u([{key:"default",fn:function({ valid }){return [_c('div',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.proposalOption.includes(2) ||
          (_vm.proposalOption.includes(3) && _vm.showDataBank == true) ||
          (_vm.proposalOption.includes(6) &&
            _vm.showDataMiles == false &&
            _vm.showDataBank == true) ||
          (_vm.proposalOption.includes(8) &&
            _vm.showDataBank == true &&
            _vm.showDataClient == false &&
            _vm.showDataClient == false &&
            _vm.showDataMiles == false)
        ),expression:"\n          proposalOption.includes(2) ||\n          (proposalOption.includes(3) && showDataBank == true) ||\n          (proposalOption.includes(6) &&\n            showDataMiles == false &&\n            showDataBank == true) ||\n          (proposalOption.includes(8) &&\n            showDataBank == true &&\n            showDataClient == false &&\n            showDataClient == false &&\n            showDataMiles == false)\n        "}]},[_c('div',[_c('h4',{staticClass:"title-info"},[_vm._v(" "+_vm._s(_vm.$t("compensationSelection.clientForm.titleBank"))+" ")]),_c('p',{staticClass:"ae-description"},[_vm._v(" "+_vm._s(_vm.$t("compensationSelection.subtitle"))+" "+_vm._s(_vm.tokenStore.id)+" ")])]),_c('DynamicForm',{ref:"bankDetailsForm",attrs:{"values":_vm.defaultValues}})],1),(
          _vm.proposalOption.includes(2) ||
          (_vm.proposalOption.includes(3) &&
            _vm.showDataClient == false &&
            _vm.showDataBank == true) ||
          (_vm.proposalOption.includes(6) &&
            _vm.showDataBank == true &&
            _vm.showDataMiles == false) ||
          (_vm.proposalOption.includes(8) &&
            _vm.showDataBank == true &&
            _vm.showDataClient == false &&
            _vm.showDataMiles == false)
        )?_c('div',{staticClass:"d-sm-flex"},[_c('v-btn',{staticClass:"btn-confirm",staticStyle:{"margin-right":"unset"},attrs:{"color":"primary","dark":"","large":"","disabled":!valid},on:{"click":function($event){return _vm.submitFormBankDetails()}}},[_c('span',{staticClass:"mr-1"},[_vm._v(_vm._s(_vm.$t("compensationSelection.btnConfirm")))]),_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-send")])],1)],1):_vm._e()]}}],null,false,1080960168)})],1):_vm._e(),_c('BankDetailsSapErrors',{attrs:{"open":_vm.bankDetailsSapErrorsDialog.open,"errors":_vm.bankDetailsSapErrorsDialog.errors},on:{"close":_vm.bankDetailsSapErrorsDialogToggle}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }