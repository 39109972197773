const CA = {
  countryConfig: {
    IBAN: {
      visible: false,
    },
    accountHolderName: {
      required: false,
      visible: false,
      label: "bankDetails.accountHolderName",
    },
    accountHolderDocNumber: {
      required: true,
      visible: true,
      label: "bankDetails.accountHolderDocNumber",
    },
    accountNumber: {
      visible: true,
      required: true,
      label: "bankDetails.accountNumber",
    },
    bankAddress: {
      visible: false,
      required: false,
    },
    CTN: {
      visible: true,
      label: "bankDetails.CTN",
      placeholder: "bankDetails.Transitnumber",
      required: true,
    },
    bankName: {
      visible: true,
      label: "bankDetails.bankName",
      required: true,
    },
    swift: {
      required: true,
      visible: true,
      label: "bankDetails.swift",
      placeholder: "bankDetails.swiftMinmaxValidation",
    },
  },
};
export { CA };
