<template>
  <div class="text-left">
    <v-dialog :value="opened" width="500" persistent>
      <v-card>
        <v-card-title class="text-h5 header">
          {{ $t("errorModalSap.title") }}
        </v-card-title>

        <v-card-text>
          <v-list-item
            v-for="(item, i) in filteredErrors"
            :key="i"
            class="pl-0"
          >
            <v-list-item-content class="text-left">
              <v-list-item-title>{{ item }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close">
            {{ $t("errorModalSap.btnAccept") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "bankDetailsSapErrors",
  props: {
    open: { type: Boolean, require: true },
    errors: { type: Array, require: true },
  },
  methods: {
    close() {
      this.$emit("close", true);
    },
  },
  computed: {
    opened() {
      return this.open;
    },
    filteredErrors() {
      return this.errors.filter((e) => e !== null);
    },
  },
};
</script>

<style scoped>
.header {
  background-color: #2d69e1;
  color: white;
}
</style>
