<template>
  <v-app>
    <div class="headerlayout"></div>
    <slot name="content">
      <v-content>
        <v-container class="content-box" style="padding-bottom: 260px" fluid>
          <router-view></router-view>
        </v-container>
      </v-content>
    </slot>
  </v-app>
</template>

<script>
export default {
  name: "LayoutComponent",
  props: {
    loading: {
      default: false,
      type: Boolean,
    },
  },
};
</script>
