<template>
  <ValidationProvider :name="$attrs.label" :rules="rules">
    <v-select
      :error-messages="errors"
      :messages="$attrs.messages"
      :placeholder="$attrs.placeholder"
      :success="!$attrs.hideValidations ? null : valid"
      background-color="white"
      filled
      item-text="name"
      item-value="value"
      slot-scope="{ errors, valid }"
      v-bind="$attrs"
      v-model="innerValue"
      v-on="$listeners"
      :menu-props="{ bottom: true, offsetY: true }"
    ></v-select>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";

export default {
  components: {
    ValidationProvider,
  },
  props: {
    rules: {
      type: [Object, String],
      default: "",
    },
    value: {
      type: null,
    },
  },
  data: () => ({
    innerValue: "",
  }),
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      this.$emit("input", newVal);
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal;
    },
  },
  created() {
    if (this.value) this.innerValue = this.value;
    if (!this.value && this.$attrs.default) {
      this.innerValue =
        this.$attrs.items.find(
          (i) =>
            i.name.toLowerCase() === this.$attrs.default.toLowerCase() ||
            i.value.toLowerCase() === this.$attrs.default.toLowerCase()
        )?.value || null;
    }
  },
};
</script>
