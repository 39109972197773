const EC = {
  countryConfig: {
    accountHolderDocNumber: {
      visible: true,
      label: "bankDetails.documentCedula",
      placeholder: "bankDetails.documentCedulaPlaceholderEC",
      required: true,
    },
    accountNumber: {
      visible: true,
      label: "bankDetails.accountNumber",
      required: true,
    },
    IBAN: {
      visible: false,
      required: false,
    },
    accountTypeBankDetails: {
      label: "bankDetails.accountTypeBankDetails",
      visible: true,
      required: true,
    },
    bankAddress: {
      visible: false,
      required: false,
    },
    bankName: {
      visible: false,
      required: false,
    },
    swift: {
      visible: true,
      required: true,
      label: "bankDetails.swift",
    },
  },
};
export { EC };
