<template>
  <div class="aebond">
    <div>
      <!--<p class="title">
      Compensación Bono AE:
    </p>-->
      <p class="info-style">{{ $t("compensationSelection.cbonDes") }}</p>
      <p v-if="!isMoreThanOnePax" class="head-title">
        Confirma tus datos para tu Bono AE
      </p>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-layout row wrap>
          <v-flex xs12 sm4>
            <div
              class="v-input v-text-field v-text-field--solo-flat v-input--is-label-active v-input--is-dirty v-input--is-readonly theme--light"
            >
              <div class="v-input__control">
                <div style="margin-bottom: 0px !important">
                  <div class="v-text-field__slot" style="position: relative">
                    <label
                      aria-hidden="true"
                      class="v-label v-label--active theme--light"
                      style="left: 0px; right: auto; position: absolute"
                      >{{ $t("compensation.nombre") }}</label
                    >
                    <input
                      aria-label="Nombre"
                      readonly="readonly"
                      type="text"
                      :value="item.BeneficiaryFirstName"
                    />
                  </div>
                </div>
                <div class="v-text-field__details">
                  <div class="v-messages theme--light">
                    <div class="v-messages__wrapper"></div>
                  </div>
                </div>
              </div>
            </div>
          </v-flex>
          <v-flex xs12 sm4>
            <div
              class="v-input v-text-field v-text-field--solo-flat v-input--is-label-active v-input--is-dirty v-input--is-readonly theme--light"
            >
              <div class="v-input__control">
                <div style="margin-bottom: 0px !important; position: relative">
                  <div class="v-text-field__slot">
                    <label
                      aria-hidden="true"
                      class="v-label v-label--active theme--light"
                      style="left: 0px; right: auto; position: absolute"
                      >{{ $t("compensation.apellido") }}</label
                    >
                    <input
                      aria-label="Apellido"
                      readonly="readonly"
                      type="text"
                      :value="item.BeneficiaryLastName"
                    />
                  </div>
                </div>
                <div class="v-text-field__details">
                  <div class="v-messages theme--light">
                    <div class="v-messages__wrapper"></div>
                  </div>
                </div>
              </div>
            </div>
          </v-flex>
          <v-flex xs12 sm4>
            <v-text-field
              background-color="white"
              :label="$t('compensation.segundoApellido')"
              filled
              v-model="formData['lastName2']"
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout row wrap>
          <v-flex xs12 sm4>
            <v-select
              v-model="formData['documentType']"
              filled
              background-color="white"
              :items="documentTypes"
              :label="$t('compensation.tipoDocumento')"
              :error-messages="fieldError('documentType')"
              :messages="$t('compensation.dniSpanishOnly')"
            ></v-select>
          </v-flex>
          <v-flex xs12 sm4 mt-5 mt-sm-0 class="pl-sm-2">
            <v-text-field
              :label="$t('compensation.numeroDocumento')"
              v-model="formData['documentNumber']"
              filled
              background-color="white"
              @change="$v.formData.documentNumber.$touch()"
              @blur="$v.formData.documentNumber.$touch()"
              :error-messages="fieldError('documentNumber')"
            ></v-text-field>
          </v-flex>
        </v-layout>
      </v-form>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Vuelidate from "vuelidate";

import { required } from "vuelidate/lib/validators";
Vue.use(Vuelidate);

export default {
  name: "AebondComponent",
  props: ["validate", "item", "initData", "showErrors", "isMoreThanOnePax"],
  validations: function () {
    const self = this;
    return {
      formData: {
        documentType: { required },
        documentNumber: {
          required,
          docNumberES: function (val) {
            const docType = self.formData.documentType;
            if (docType == null) return false; // ojo: es required?
            return self.validateDocNumber(docType, val);
          },
        },
      },
    };
  },
  data: function () {
    return {
      validatorLoaded: false,
      valid: false,
      formData: {
        firstName: "",
        lastName: "",
        lastName2: "",
        documentType: "",
        documentNumber: "",
      },
    };
  },
  computed: {
    documentTypes: function () {
      return [
        { text: this.$i18n.t("text.dniapr"), value: "DNI" },
        { text: this.$i18n.t("text.nie"), value: "NIE" },
        { text: this.$i18n.t("text.nif"), value: "NIF" },
        { text: this.$i18n.t("text.passport"), value: "PASAPORTE" },
        {
          text: this.$i18n.t("compensation.others"),
          value: "SIN_DOCUMENTACION",
        },
      ];
    },
  },
  watch: {
    showErrors: function (newValue) {
      if (newValue > 0) {
        this.$v.$touch();
      }
    },
    validate: function () {
      this.$v.$touch();
    },
    formData: {
      deep: true,
      handler: function (newValue) {
        // revisar si es valido con vuelidate:

        this.$emit("formReady", {
          valid: !this.$v.$invalid,
          dirty: this.$v.$dirty,
          clientId: this.item.ClientID,
          compensationType: "Aebond",
          data: newValue,
        });
      },
    },
  },
  created: function () {
    if (
      this.initData != null &&
      Object.prototype.hasOwnProperty.call(this.initData, "compensationType") &&
      this.initData.compensationType == "Aebond"
    ) {
      // this.formData = this.initData.data;

      try {
        this.formData.lastName2 =
          this.initData.data.item.BeneficarySecondLastName;
        this.formData.documentNumber =
          this.initData.data.item.BeneficiaryDocNumber;
        this.formData.documentType = this.initData.data.item.BeneficiaryDocType;
        // eslint-disable-next-line no-empty
      } catch (err) {}
    }
    if (this.isValid()) {
      this.$emit("formReady", {
        valid: !this.$v.$invalid,
        dirty: this.$v.$dirty,
        clientId: this.item.ClientID,
        compensationType: "Aebond",
        data: this.formData,
      });
    }
  },
  methods: {
    isValid: function () {
      return this.$v.$invalid;
    },
    fieldError: function (fieldName) {
      if (fieldName.trim() == "documentType") {
        if (
          !this.$v.formData.documentType.required &&
          this.$v.formData.documentType.$dirty
        ) {
          return this.$t("bankDetails.requiredField", {
            fieldName: this.$t("compensation.tipoDocumento"),
          });
        }
      }
      if (
        this.$v.formData[fieldName].$dirty &&
        !this.$v.formData[fieldName].required
      ) {
        // return 'El campo es obligatorio';
        const literals = {
          documentNumber: "compensation.numeroDocumento",
          documentType: "compensation.tipoDocumento",
        };

        return this.$t("bankDetails.requiredField", {
          fieldName: this.$t(literals[fieldName]),
        });
      }

      if (
        fieldName == "documentNumber" &&
        !this.$v.formData.documentNumber.docNumberES &&
        this.$v.formData.documentNumber.$dirty
      ) {
        return this.$t("bankDetails.docNumberError");
      }

      return undefined;
    },
    validateDni(s) {
      return new RegExp("^[0-9]{8}[A-Za-z]{1}$").test(s);
    },
    validateNie(s) {
      return new RegExp("^[A-Za-z]{1}[0-9]{7,8}[0-9A-Za-z]{1}$").test(s);
    },
    validateNif(s) {
      return new RegExp("^[A-Za-z]{1}[0-9]{8}$").test(s);
    },
    validateDocNumber(docType, docNumber) {
      /*
        regexes:
        dni:  new RegExp("^[0-9]{8}[A-Za-z]{1}$").test('11223144A');
        nie: new RegExp("^[A-Za-z]{1}[0-9]{7,8}[0-9A-Za-z]{1}$").test('X4978143T');
        nif: new RegExp("^[A-Za-z]{1}[0-9]{8}$").test('A82533851');
      */
      switch (docType) {
        case "DNI":
          return this.validateDni(docNumber);
        case "NIE":
          return this.validateNie(docNumber);
        case "NIF":
          return this.validateNif(docNumber);
      }
      return true;
    },
  },
};
</script>
<style scoped>
.aebond {
  background-color: #f6f6f6;
  padding: 28px;
  margin-top: 22px;
  border-radius: 10px;
}
.head-title {
  font-family: Aeradar-Bold, sans-serif;
  font-size: 22px;
  letter-spacing: 0;
  margin-bottom: 18px;
  color: #0f2367;
}
label {
  color: #6c6c6c;
}
@media (max-width: 992px) {
  .aebond {
    padding: 12px;
  }
}
.info-style {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  color: gray;
  background-color: white;
  margin-bottom: 18px;
  width: 50%;
  padding: 5px;
}
</style>
