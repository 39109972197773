<template>
  <layout align-center>
    <template slot="header"></template>
    <template slot="content">
      <v-responsive>
        <v-container fill-height>
          <br />
          <div class="text-timeout">
            <p class="text-sm-left time">{{ $t("text.timeout") }}</p>
          </div>
        </v-container>
      </v-responsive>
    </template>
    <template slot="footer"></template>
  </layout>
</template>

<script>
import Layout from "@/components/Layout";
export default {
  name: "TimeOutComponent",
  components: { Layout },
};
</script>
