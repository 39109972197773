import VueI18n from "vue-i18n";
import Vue from "vue";
import es from "./lang/es";
import en from "./lang/en";
import axios from "axios";

import esVee from "vee-validate/dist/locale/es.json";
import enVee from "vee-validate/dist/locale/en.json";
import { loadLocale } from "../plugins/vee-validate";

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: "es", // set locale
  fallbackLocale: "en",
  messages: {
    es: { ...es, validations: esVee },
    en: { ...en, validations: enVee },
  },
});

const loadedLanguages = ["en", "es"];
export const languagesAvailable = ["de", "fr", "it", "pt"];

function setI18nLanguage(lang) {
  i18n.locale = lang;
  axios.defaults.headers.common["Accept-Language"] = lang;
  document.querySelector("html").setAttribute("lang", lang);
  return lang;
}

export function navigatorCloseLanguage() {
  const userLanguage = navigator.language;
  if (languagesAvailable.includes(userLanguage)) {
    return loadLanguageAsync(userLanguage);
  } else if (userLanguage.includes("-")) {
    const userLanguageSplit = userLanguage.split("-");
    if (languagesAvailable.includes(userLanguageSplit[0])) {
      return loadLanguageAsync(userLanguageSplit[0]);
    }
  }
  return "en";
}

export async function loadLanguageAsync(lang) {
  // If the same language
  if (i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang));
  }
  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang));
  }
  // If the language hasn't been loaded yet
  return import(
    /* webpackChunkName: "lang-[request]" */ `./lang/${lang}.json`
  ).then(async (messages) => {
    const veeValidateMsg = await loadLocale(lang);
    i18n.setLocaleMessage(lang, {
      ...messages.default,
      validations: veeValidateMsg,
    });
    loadedLanguages.push(lang);
    return setI18nLanguage(lang);
  });
}

export default i18n;
