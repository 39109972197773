import axios from "axios";

const dapiInstance = axios.create({
  baseURL: "https://dapi.aireuropa.com/api/channel/v1",
  headers: {
    locale: "es-ES",
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

export const getCountries = async () => {
  return dapiInstance("/masterdata/countries");
};

export default dapiInstance;
